/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Tabs } from "antd";

import Activity from "../components/Activity";
import { getGreetingsData, getGreetingImages } from "../../services/api";
import StackActivity from "../components/StackActivity";

const CarouselItemImages = React.memo(
  ({ getValue, activeTab, setShowEditCard, data, inputFields }) => {
    const obj = { id: data.id };
    inputFields.map((i) => (obj[i] = getValue(i)));

    const getImageUrl = async () => {
      const src = await getGreetingImages(obj);
      setSource(src);
    };

    const [source, setSource] = useState(getImageUrl());

    return (
      <InnerContainer padding={activeTab === "whatsapp" ? "20px 43px" : "20px"}>
        <CarouselItem
          src={source}
          onClick={() =>
            setShowEditCard({ show: true, selectedCardId: data.id })
          }
        />
      </InnerContainer>
    );
  }
);

const Greetings = () => {
  const state = useSelector((state) => state);
  const params = useParams();
  const { greetingType } = params;
  const { TabPane } = Tabs;

  const [activeTab, setActiveTab] = useState(greetingType || "whatsapp");
  const [businessImages, setBusinessImages] = useState([]);
  const [whatsappImages, setWhatsappImages] = useState([]);
  const [greetingsImages, setGreetingsImages] = useState([]);
  const [businessInputFields, setBusinessInputFields] = useState([]);
  const [whatsappInputFields, setWhatsappInputFields] = useState([]);
  const [greetingsInputFields, setGreetingsInputFields] = useState([]);
  const [showEditCard, setShowEditCard] = useState({
    show: false,
    selectedCardId: "",
  });
  const [cardData, setCardData] = useState({});
  const [inputFieldConfig, setInputFieldConfig] = useState({});

  const GetData = async () => {
    const data = await getGreetingsData();
    const {
      businessCards,
      whatsappStatuses,
      greetingCards,
      inputFieldConfigs,
      defaultBusinessCardFields,
      defaultGreetingFields,
      defaultWhatsappStatusFields,
    } = data.data.data;
    setBusinessImages(businessCards);
    setWhatsappImages(whatsappStatuses);
    setGreetingsImages(greetingCards);
    setInputFieldConfig(inputFieldConfigs);
    setWhatsappInputFields(defaultWhatsappStatusFields);
    setBusinessInputFields(defaultBusinessCardFields);
    setGreetingsInputFields(defaultGreetingFields);
  };

  useEffect(() => {
    GetData();
  }, []);

  useEffect(() => {
    setCardData({
      type: activeTab,
      images:
        activeTab === "whatsapp"
          ? whatsappImages
          : activeTab === "business"
          ? businessImages
          : greetingsImages,
      inputFields:
        activeTab === "whatsapp"
          ? whatsappInputFields
          : activeTab === "business"
          ? businessInputFields
          : greetingsInputFields,
      inputFieldConfigs: inputFieldConfig,
      selectedCardId: showEditCard.selectedCardId,
    });
  }, [showEditCard.show]);

  const getValue = (i) => {
    const index = Object.values(inputFieldConfig).findIndex((k) => k.key === i);
    const data = Object.values(inputFieldConfig)[index];
    return data.defaultValue.source === "path"
      ? _.get(state, data.defaultValue.path)
      : data.defaultValue.value;
  };

  const MenuItems = [
    {
      title: "Business Cards",
      active: "business",
    },
    {
      title: "Whatsapp Status",
      active: "whatsapp",
    },
    {
      title: "Greeting Cards",
      active: "greetingCards",
    },
  ];

  return (
    <>
      <Activity
        heading="Branding & Marketing"
        content={
          <>
            <HeaderMenu>
              <Tabs
                className={"tabs"}
                activeKey={greetingType}
                onChange={(newGreetingType) => {
                  setActiveTab(newGreetingType);
                  // navigate(`/greetings/${newGreetingType}`); --
                }}
              >
                {MenuItems.map((i) => (
                  <TabPane
                    className={"primaryLightHoverEffect"}
                    tab={
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          margin: "0px 10px",
                        }}
                      >
                        {i.title}
                      </div>
                    }
                    key={i.active}
                  ></TabPane>
                ))}
              </Tabs>
            </HeaderMenu>

            <CarouselContainer>
              {(activeTab === "whatsapp"
                ? whatsappImages
                : activeTab === "business"
                ? businessImages
                : greetingsImages
              ).map((i) => {
                return (
                  <CarouselItemImages
                    getValue={getValue}
                    activeTab={activeTab}
                    setShowEditCard={setShowEditCard}
                    data={i}
                    inputFields={
                      activeTab === "whatsapp"
                        ? whatsappInputFields
                        : activeTab === "business"
                        ? businessInputFields
                        : greetingsInputFields
                    }
                  />
                );
              })}
            </CarouselContainer>
          </>
        }
        onBack={
          null
          // () => navigate("/dashboard") --
        }
      />
      {showEditCard.show && cardData.selectedCardId && (
        <StackActivity
          visible={showEditCard.show}
          setVisible={() =>
            setShowEditCard({ show: false, selectedCardId: "" })
          }
          type="editCard"
          data={cardData}
        />
      )}
    </>
  );
};

export default Greetings;

const HeaderMenu = styled.div`
  display: flex;
  width: 100%;
  overflow: scroll;
  gap: 15px;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  . {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  position: absolute;
  background: white;
`;

const CarouselItem = styled.img`
  src: ${({ src }) => src};
  height: 100%;
  border-radius: 12px;
  width: 100%;
  box-shadow: 0px 0px 12px -3px rgba(97, 94, 97, 1);
`;

const CarouselContainer = styled.div`
  margin-top: 60px;
`;

const InnerContainer = styled.div`
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
`;
