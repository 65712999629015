import React, { useState } from "react";
import { Col, Input, Row } from "antd";
import { List } from "antd-mobile";
import { RUPEE } from "../../../../models/constants";
import { beautifyAmount } from "../../../../utils";
import FlexContainer from "../../../components/FlexContainer";
import CustomFAB from "../../../components/CustomFAB";
import CustomList from "../../../components/CustomList";
import StackActivity from "../../../components/StackActivity";
import {ELayerTrigger, ELayerType, useLayersFramework} from "../../../v2framework/framework.hooks";
import {EnumLayer} from "../../../v2framework/layers.list";

const LedgersList = ({ records, title, loading, ledgerType, onSave }) => {
  const [searchText, setSearchText] = useState("");
  const [visible, setVisible] = useState(false);
  const {pushInAppLayer} = useLayersFramework();
  const [visibleTransationList, setVisibleTransationList] = useState(false);
  const [popUpData, setPopUpData] = useState({
    ledgerType,
    ledgerData : {},
  })

  const editLedger = (data) => {
    pushInAppLayer({
        trigger: ELayerTrigger.IN_APP,
        type: ELayerType.FULL_SCREEN,
        target: EnumLayer.LEDGER_STATEMENT,
    }, {
        ledgerStatementLedgerData: data,
    });
  }

  return (
    <FlexContainer
      header={
        <Input.Search
          onChange={(e) => setSearchText(e.target.value)}
          placeholder={"Search with Ledger Name"}
          style={{
            width: "100%",
            minWidth: 300,
            fontSize: 18,
          }}
          size={"large"}
          enterButton={true}
          allowClear
        />
      }
      content={
        <>
          <CustomList
            loading={loading}
            records={records.filter((r) =>
              (r.name || "").toLowerCase().includes(searchText.toLowerCase())
            )}
            rowUi={(r) => (
              <List.Item className={"listItem"}>
                <Row onClick={() => editLedger(r)}>
                  <Col span={17}>{r.name || ""}</Col>
                  <Col
                    span={7}
                    style={{
                      fontWeight: "bold",
                      color: r.balance > 0 ? "red" : "green",
                    }}
                  >
                    {RUPEE} {beautifyAmount(r.balance || 0)}
                  </Col>
                </Row>
              </List.Item>
            )}
          />
          <div onClick={() => {
              setVisible(true);
              pushInAppLayer({
                  trigger: ELayerTrigger.IN_APP,
                  type: ELayerType.FULL_SCREEN,
                  target: EnumLayer.SAVE_LEDGER,
              }, {
                  ledgerSaveTargetVendorType: ledgerType,
                  ledgerSaveOnSave: onSave,
                  ledgerSaveSelectedRecord: undefined,
              })
          }}>
            <CustomFAB title={title} Bottom={true} />
          </div>
          {/* {visible ? <StackActivity*/}
          {/*  visible={visible}*/}
          {/*  setVisible={setVisible}*/}
          {/*  type="addCustomer"*/}
          {/*  data={popUpData.ledgerType}*/}
          {/*  onSave={onSave}*/}
          {/*/> : */}
          {/*  {!visible &&*/}
          {/*<StackActivity*/}
          {/*  visible={visibleTransationList}*/}
          {/*  setVisible={setVisibleTransationList}*/}
          {/*  data={popUpData.ledgerData}*/}
          {/*  type="customerTransaction"*/}
          {/*/>}*/}
        </>
      }
    />
  );
};

export default LedgersList;
