import React from "react";
import { Popup } from "antd-mobile";
import ItemSave from "../activities/tabs/items/itemSave";
import StockInOut from "../activities/tabs/items/stockInOut";
import PayIn from "../activities/tabs/ledgers/PayIn";
import PayOut from "../activities/tabs/ledgers/PayOut";
import OrderList from "../ecommerceStore/orderList";
import Order from "../ecommerceStore/order";
import PayoutItems from "../activities/tabs/settings/PaymentGateway/payoutItems";
import UpgradePlanModal from "../activities/tabs/dashboard/UpgradePlanModal";
import EditCard from "../greetings/editCard";
import Payment from "../activities/tabs/dashboard/Payment";
import Coupons from "../activities/tabs/dashboard/Coupons";

const StackActivity = ({
  visible,
  setVisible,
  type,
  data,
  onSave = () => {},
}) => {
  return (
    <div>
      <Popup
        visible={visible}
        onMaskClick={() => {
          data && !!data.stock && setVisible(false);
        }}
        position={
          type !== "ledger" && data && !!data.stock ? "bottom" : "right"
        }
        bodyStyle={{
          width: "100vw",
          borderTopRightRadius: "5%",
          borderTopLeftRadius: "5%",
        }}
      >
        {/*{type === "itemSaveActivity" && (*/}
        {/*  <ItemSave*/}
        {/*    selectedRecord={data}*/}
        {/*    onSave={onSave}*/}
        {/*    onCancel={() => setVisible(false)}*/}
        {/*  />*/}
        {/*)}*/}

         {type === "stockInOut" && (
          <StockInOut
            stock={data.stock}
            stockIn={data.stockIn}
            closeDrawer={() => setVisible(false)}
          />
        )}

        {type === "payIn" && (
          <PayIn customer={data} onCancel={() => setVisible(false)} />
        )}

        {type === "payOut" && (
          <PayOut vendor={data} onCancel={() => setVisible(false)} />
        )}

        {type === "orderList" && (
          <OrderList onCancel={() => setVisible(false)} />
        )}

        {type === "order" && (
          <Order
            orderData={data}
            onSave={onSave}
            onCancel={() => setVisible(false)}
          />
        )}

        {type === "paymentPayout" && (
          <PayoutItems
            selectedRecord={data}
            onCancel={() => setVisible(false)}
          />
        )}

        {type === "upgradePlan" && (
          <UpgradePlanModal onCancel={() => setVisible(false)} />
        )}

        {type === "editCard" && (
          <EditCard
            type={data.type}
            images={data.images}
            inputFields={data.inputFields}
            inputFieldConfigs={data.inputFieldConfigs}
            selectedCardId={data.selectedCardId}
            onCancel={() => setVisible(false)}
          />
        )}


        {type === "payment" && (
          <Payment data={data} onCancel={() => setVisible(false)} />
        )}

        {type === "coupons" && (
          <Coupons
            onSave={onSave}
            data={data}
            onCancel={() => setVisible(false)}
          />
        )}
      </Popup>
    </div>
  );
};

export default StackActivity;
