import {getAllData, getLedgers, getStoreDataItems} from "../services/api";
import {customDispatcher} from "../utils";
import {useDispatch} from "react-redux";

export const StoreDataTypes = {
    LEDGERS: "LEDGERS",
    ITEMS: "ITEMS",
    COMPANY: "COMPANY",
}

const storeDataLoadersMap = {
    [StoreDataTypes.LEDGERS]: async () => {
        const ledgers = (await getLedgers("all")).data.data.ledgers;
        console.log("## DATA ##", ledgers);
        return {
            [StoreDataTypes.LEDGERS]: ledgers,
        };
    },
    [StoreDataTypes.ITEMS]: async () => {
        const allData = (await getAllData()).data?.data;
        console.log("## allData", allData);
        return {
            [StoreDataTypes.ITEMS]: allData.user.items || [],
            [StoreDataTypes.COMPANY]: allData.user.company || {},
        }
    },
    [StoreDataTypes.COMPANY]: async () => {
        const allData = (await getAllData()).data?.data;
        console.log("## allData", allData);
        return {
            [StoreDataTypes.ITEMS]: allData.user.items || [],
            [StoreDataTypes.COMPANY]: allData.user.company || {},
        }
    },
}

export const getStoreDataKey = (storeDataType) => `store_${storeDataType}`;

export function useStoreDataLoader() {
    const dispatch = customDispatcher(useDispatch());

    return [async (storeDataType) => {
        const key = getStoreDataKey(storeDataType);
        const dataLoader = storeDataLoadersMap[storeDataType];
        if (!dataLoader) {
            console.log(`## Store Data Loader not set for ${storeDataType} ##`);
            return;
        }

        try {
            console.log(`## Loading Store Data for ${storeDataType} ##`);
            const fetchedData = await dataLoader();
            console.log(`## Loaded Store Data for ${storeDataType} ##`);
            // trigger dispatch to update redux store
            dispatch(fetchedData);
        } catch(err) {
            console.log("## ERR useStoreDataLoader ##", err);
        }

    }]
}