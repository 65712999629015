/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useState} from 'react';
// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import 'antd/dist/antd.min.css';
import {useDispatch} from "react-redux";
import {loginRequest} from "../../services/api";
import {customDispatcher, logRender} from "../../utils";
import {getCredentials} from "../../services/cookiesPlus";
import FlexContainer from "../components/FlexContainer";
import {useMyParams} from "../v2framework/framework.hooks";
import {EnumLayer} from "../v2framework/registry";
import {BottomTabBar} from "../BottomTabBar";
import {DashboardTab} from "./tabs/dashboard";
import Bills from "./tabs/vouchers";
import VouchersTab from "./tabs/vouchers";
import LedgersTab from "./tabs/ledgers";
import ItemsTab from "./tabs/items";
import SettingsTab from "./tabs/settings";
import {useNavigate, useParams} from "react-router-dom";

const tabsMap = {
    dashboard: <DashboardTab/>,
    vouchers: <VouchersTab/>,
    ledgers: <LedgersTab/>,
    items: <ItemsTab/>,
    settings: <SettingsTab/>,
}

export const HomeActivity = React.memo(() => {
    logRender("HomeActivity");
    // const params = useParams();
    // const navigate = useNavigate();
    // const [currentTab, setCurrentTab] = useState('dashboard');
    const [a, params] = useMyParams();
    const currentTab = params?.currentHomeTab;

    const content = useMemo(() => {
        return <div>
            {tabsMap[currentTab]}
        </div>;
    }, [currentTab]);

    const footer = useMemo(() => {
        return <BottomTabBar activeKey={currentTab} setCurrentTab={() => {}} />;
    }, [currentTab]);

    return <div style={{height: '100vh'}}>
        <FlexContainer
            footer={footer}
            footerHeight={70}
            content={content}
        />
    </div>

})

