import {useCustomDispatch} from "./generic";
import {useSelector} from "react-redux";
import {useCallback, useMemo} from "react";
import {appConfig} from "../config";
import {EStorageKeys, iStorage} from "../services/storage";
import {dateRangeTemplatesMap} from "../views/activities/dateRangePicker/rangeTemplates";
import moment from "moment";

const defaultDateRange = {
    filterType: "currentFy",
};

/*
    DateRange interface:: {
        filterType: 'string',
        start: number, // epoch
        end: number, // epoch
    }
 */
export function useDateRange() {
    const dispatch = useCustomDispatch();
    const selectedDataRange = useSelector(state => state.selectedDateRange) || defaultDateRange;

    const actualValues = useMemo(() => {
        let start, end;
        if (selectedDataRange.filterType === "custom") {
            start = selectedDataRange.start;
            end = selectedDataRange.end;
        } else {
            ([start, end] = dateRangeTemplatesMap[selectedDataRange.filterType]?.getRange?.() || []);
        }

        return {
            start: moment(start),
            end: moment(end),
        }
    }, [selectedDataRange]);

    const setSelectedDateRange = useCallback((dateRange) => {
        console.log("## Setting date to state ##");
        dispatch({
            selectedDateRange: dateRange,
        });
        if (appConfig.enablePersistentDateRange) {
            iStorage.set(EStorageKeys.dateRange, dateRange);
        }
    }, [dispatch])

    return [selectedDataRange, setSelectedDateRange, actualValues];
}