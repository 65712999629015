/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Modal } from "antd-mobile";
import Activity from "../../../components/Activity";
import Text from "../../../components/Text";
import { RUPEE } from "../../../../models/constants";
import StackActivity from "../../../components/StackActivity";
import {
  getCoupons,
  completePurchase,
  paymentBaseUrl,
  checkPaymentStatus,
  getPlans,
} from "../../../../services/api";
import { openDeepLinkUrl } from "../../../../appInterface/linking";
import {
  CloseCircleOutlined,
  RightOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import { Spin } from "antd";
import logo from "../../../../res/logoLandscapeV3.png";

const Payment = ({ data, onCancel }) => {
  const state = useSelector((state) => state);
  const { user } = state;
  
  const lite = "lite";
  const planType = data && data.planType.toLowerCase();
  const duration = data && data.selectedDuration;

  const [showCouponModal, setShowCouponModal] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [totalPayment, setTotalPayment] = useState(0);
  const [itemsTotal, setItemsTotal] = useState(0);
  const [promotionApplied, setPromotionApplied] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showPaymentStatusModal, setShowPaymentStatusModal] = useState(false);
  const [paymentOrderId, setPaymentOrderId] = useState();
  const [clearStatusInterval, setClearStatusInterval] = useState(false);
  const [plans, setPlans] = useState({
    "lite": {
        "1yr": "--" ,
        "3yr": "--"
    },
    "premium": {
        "1yr": "--",
        "3yr": "--"
    }
});

useEffect(() => {
  if (state && state.company) {
      getPlans().then(resp => setPlans(resp.data.data.plans));
  }
}, [state]);
  let statusInterval;

  const selectCoupon = (data, code) => {
    setPromotionApplied(data.data.discount);
    setCouponCode(code);
  };

  const removeCoupon = () => {
    setPromotionApplied(0);
    setCouponCode("");
    setTotalPayment(plans[planType][duration]);
  };

  const getPaymentStatus = (orderId) => {
    setShowPaymentStatusModal(true);
    statusInterval = setInterval(async () => {
      const { data } = await checkPaymentStatus(orderId);
      console.log("ininininini");
      if (data.data.payOrder.status === "paid") {
        clearInterval(statusInterval);
        setShowPaymentStatusModal(false);
        onCancel();
        // navigate("/"); --
      }
    }, 1000);
  };

  useEffect(() => {
    if (data.planType && !isNaN(plans[lite][duration])) {
      setTotalPayment(plans[planType][duration]);
      setItemsTotal(plans[planType][duration]);
    }
  }, [plans]);

  useEffect(() => {
    if (state && user && user.company) {
      getCoupons().then((resp) => setCoupons(resp.data.data.coupons));
    }
  }, [state]);

  useEffect(() => {
    if (clearStatusInterval) {
      console.log("clear", clearStatusInterval);
      clearInterval(statusInterval);
      setShowPaymentStatusModal(false);
      console.log("inside stop function");
    }
  }, [clearStatusInterval]);

  useEffect(() => {
    let statusTimeOut = setTimeout(() => {
      clearInterval(statusInterval);
    }, 600000);

    return () => {
      clearInterval(statusInterval);
      clearTimeout(statusTimeOut);
    };
  }, [getPaymentStatus]);

  return (
    <Activity
      heading="Buy Plan"
      onBack={() => {
        onCancel();
      }}
      content={
        <>
          <OuterContainer>
            <img alt={"Logo"} src={logo} width={200} />
            <Text
              color="#0080FF"
              content={`${data.planType} Plan, ${
                duration === "1yr" ? "1 Year" : "3 Years"
              }`}
              fontSize={20}
              fontWeight={600}
              style={{ marginTop: "10px"}}
            />
            <Text fontSize={14} color="gray" content="( Valid for Web + Mobile App )" />
          </OuterContainer>
          <BottomSection height={couponCode.length > 0 ? "300px" : "230px"}>
            <CouponContainer couponApplied={couponCode.length > 0} onClick={() => couponCode.length < 1 && setShowCouponModal(true)}>
              <LeftContainer>
                <PercentageContainer>
                  <PercentageOutlined
                    style={{
                      color: "#0080ff",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  />
                </PercentageContainer>
                <Text
                  fontSize={16}
                  color="#0080ff"
                  fontWeight={500}
                  content={
                    couponCode.length > 0
                      ? '"' + couponCode + '"'
                      : "Apply coupon code!"
                  }
                />
                {couponCode.length > 0 && (
                  <Text
                    fontSize={16}
                    color="#0080ff"
                    fontWeight={500}
                    content={", applied"}
                  />
                )}
              </LeftContainer>
              <ApplyCoupon
                couponApplied={couponCode.length > 0}
                onClick={() => couponCode.length > 0 && removeCoupon()}
              >
                {couponCode.length > 0 ? (
                  <CloseCircleOutlined
                    style={{
                      color: "#0080ff",
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  />
                ) : (
                  <RightOutlined
                    style={{
                      color: "#0080ff",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                )}
              </ApplyCoupon>
            </CouponContainer>
            <PaymentSummary>
              {couponCode.length > 0 && (
                <>
                  <InlineContainer>
                    <Text content="Items Total:" />
                    <Text content={`${RUPEE}${itemsTotal}`} />
                  </InlineContainer>
                  <InlineContainer>
                    <Text content="Promotion Applied:" />
                    <Text content={`-${RUPEE}${promotionApplied}`} />
                  </InlineContainer>
                </>
              )}
              <InlineContainer>
                <Text
                  fontSize={18}
                  fontWeight={600}
                  color="green"
                  content="Order Total"
                />
                <Text
                  fontSize={18}
                  fontWeight={600}
                  color="green"
                  content={`${RUPEE}${totalPayment - promotionApplied}`}
                />
              </InlineContainer>
            </PaymentSummary>
            <button
              style={{ marginLeft: "10%", marginBottom: "-8px" }}
              className={
                "fab primaryEffect generalRoundedButton buttonProperties"
              }
              onClick={async () => {
                const formData = {
                  name: user.name,
                  address: user.company.address,
                  email: user.email,
                  gstin: user.company.gstin,
                  state: user.company.state,
                }
                const resp = await completePurchase({
                  formData,
                  code: (couponCode.length > 0 && couponCode) || "",
                  plan: planType,
                  duration,
                  username: state?.user?.email || state?.user?.phone,
                });
                const {
                  data: {
                    data: { needToPay, payOrder },
                  },
                } = resp;
                const { orderId } = payOrder;
                setPaymentOrderId(orderId);
                getPaymentStatus(needToPay && orderId);
                if (needToPay) {
                  openDeepLinkUrl(`${paymentBaseUrl}/pay/${orderId}/gateway`);
                }
              }}
            >
              Complete Purchase
            </button>
            <Text fontSize={14} color="gray" content="100% Secure Payment" />
          </BottomSection>

          {showCouponModal && (
            <StackActivity
              visible={showCouponModal}
              setVisible={setShowCouponModal}
              type="coupons"
              data={{
                coupons,
                planType,
                duration,
              }}
              onSave={selectCoupon}
            />
          )}

          <StackActivity
            visible={showUpgradeModal}
            setVisible={setShowUpgradeModal}
            type="upgradePlan"
          />

          <Modal
            visible={showPaymentStatusModal}
            title={"Payment In-process"}
            content={
              <PaymentProcessContainer>
                <Spin />
                <Text content="Description" />
                <ButtonContainer>
                  <button
                    className={"redEffect generalRoundedButton"}
                    onClick={() => setClearStatusInterval(true)}
                    style={{ width: "100px" }}
                  >
                    Cancel
                  </button>
                  <button
                    className={"greenEffect generalRoundedButton"}
                    onClick={() =>
                      openDeepLinkUrl(
                        `${paymentBaseUrl}/pay/${paymentOrderId}/gateway`
                      )
                    }
                  >
                    Go to browser
                  </button>
                </ButtonContainer>
              </PaymentProcessContainer>
            }
            onClose={() => setShowPaymentStatusModal(false)}
            closeOnAction
          />
        </>
      }
    />
  );
};

export default Payment;

const OuterContainer = styled.div`
  padding: 20px;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const BottomSection = styled.div`
  height: ${({ height }) => height};
  width: 100%;
  bottom: -2px;
  position: absolute;
  background: white;
  display: flex;
  border: 2px solid #d0caca4a;
  box-shadow: 0px 0px 12px -3px rgba(97, 94, 97, 1);
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
`;

const CouponContainer = styled.div`
  margin: 10px;
  border-radius: 12px;
  display: flex;
  height: 35px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  background: ${({ couponApplied }) => couponApplied && "#f6ffed"};
  width: 95%;
  padding: 30px 20px;
  background: #abd3fa8a;
`;

const ApplyCoupon = styled.div`
  padding: 1px 10px;
  height: 34px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-right: -2px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  color: ${({ couponApplied }) => (couponApplied ? "gray" : "white")};
  align-items: center;
}
`;

const PaymentSummary = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 95%;
  border-radius: 10px;
  background: #6969691f;
  padding: 10px 20px;
`;

const InlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PaymentProcessContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const PercentageContainer = styled.div`
  height: 27px;
  width: 27px;
  border: 2px dotted #0080ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
