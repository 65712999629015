import _ from "lodash";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {getStoreDataKey, StoreDataTypes, useStoreDataLoader} from "./useStoreDataLoader.hook";

const dataLoadedFlagKeysMap = {
    [StoreDataTypes.LEDGERS]: "ledgers",
    [StoreDataTypes.ITEMS]: "allData",
    [StoreDataTypes.COMPANY]: "allData",
}

const isDataAlreadyLoaded = (storeDataType) => {
    const path = `tempStorage.useLazyStoreData.${dataLoadedFlagKeysMap[storeDataType]}`;
    if (_.get(window, path)) {
        // console.log("## Lazy Already ##", _.get(window, path));
        return true;
    }
    // console.log("## Lazy Not already ##");
    _.set(window, path, "Yes");
    return false;
}

export function useLazyStoreData(storeDataType) {
    const key = getStoreDataKey(storeDataType);
    const data = useSelector(state => state?.[storeDataType]);
    const [syncData] = useStoreDataLoader();

    useEffect(() => {
        if (!isDataAlreadyLoaded(storeDataType)) {
            syncData(storeDataType);
        }
    }, []);

    return [data];
}