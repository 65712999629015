import React, {useEffect, useState} from "react";
import {Grid, NumberKeyboard} from "antd-mobile";
import _ from "lodash";

const InputDigits = ({disabledKeyboard=false, onFill, length, cell, style, minLength=0, defaultValue=""}) => {

    const [input, setInput] = useState(defaultValue.split(""));
    const [currentCursor, setCurrentCursor] = useState(defaultValue.length);

    const onInput = (ch) => {
        if (disabledKeyboard || currentCursor >= length) {
            return;
        }
        const newInput = _.cloneDeep(input);
        newInput[currentCursor] = ch;
        setInput(newInput);
        setCurrentCursor(currentCursor + 1);
    }
    const onDelete = () => {
        if (disabledKeyboard || currentCursor <= minLength) {
            return;
        }
        const newInput = _.cloneDeep(input);
        delete newInput[currentCursor - 1];
        setInput(newInput);
        setCurrentCursor(currentCursor - 1);
    }

    useEffect(() => {
        // console.log("## Cursor Effect ##", currentCursor);
        if (currentCursor === length) {
            const finalString = input.join("");
            onFill(finalString);
        }
    }, [currentCursor, length, input]);

    return (
        <div>
            <Grid columns={length} style={style}>
                {
                    [...Array(length).keys()].map(( index) => {
                        const digit = input[index];
                        return <Grid.Item>
                            <div style={{width: '100%', textAlign: 'center', border: '0px solid red'}}>
                                <center>
                                    {cell(digit, index)}
                                    {/*<div style={{*/}
                                    {/*    width: 60,*/}
                                    {/*    height: 60,*/}
                                    {/*    borderRadius: 100,*/}
                                    {/*    background: digit ? '#1677ff' : 'lightgrey',*/}
                                    {/*    textAlign: 'center',*/}
                                    {/*    verticalAlign: 'middle',*/}
                                    {/*    lineHeight: '60px',*/}
                                    {/*    color: 'white',*/}
                                    {/*    // fontWeight: 'bold',*/}
                                    {/*    fontSize: 40,*/}
                                    {/*}}>{digit}</div>*/}
                                </center>
                            </div>
                        </Grid.Item>
                    })
                }

            </Grid>
            <NumberKeyboard
                visible={true}
                showCloseButton={false}
                onInput={onInput}
                // customKey={'+'}
                onDelete={onDelete}
                style={{
                    // height: 500,
                    // border: '1px solid red',
                }}
                // confirmText={<CheckOutlined/>}
            />
        </div>
    )
};

export default InputDigits;
