import {
    CarOutlined,
    DollarOutlined,
    FileDoneOutlined,
    FileOutlined,
    FileSyncOutlined,
    FileTextOutlined, MoneyCollectOutlined, ShoppingCartOutlined
} from "@ant-design/icons";

export const viewList = [
    {
        viewLabel: "Invoices",
        createLabel: "Invoice",
        Icon: FileDoneOutlined,
        path: "/vouchers/invoice",
        createPath: "/vouchers/invoice/create"
    },
    {
        viewLabel: "Quotations",
        createLabel: "Quotation",
        Icon: FileTextOutlined,
        path: "/vouchers/proforma",
        createPath: "/vouchers/proforma/create"
    },
    {
        viewLabel: "Delivery Challan",
        createLabel: "Delivery Challan",
        Icon: CarOutlined,
        path: "/vouchers/delivery",
        createPath: "/vouchers/delivery/create"
    },
    {
        viewLabel: "Credit Notes",
        createLabel: "Credit Note",
        Icon: FileSyncOutlined,
        path: "/vouchers/creditNote",
        createPath: "/vouchers/creditNote/create"
    },
    {
        viewLabel: "Debit Notes",
        createLabel: "Debit Note",
        Icon: FileOutlined,
        path: "/vouchers/debitNote",
        createPath: "/vouchers/debitNote/create"
    },
    {
        viewLabel: "Purchases",
        createLabel: "Purchase",
        Icon: ShoppingCartOutlined,
        path: "/vouchers/purchase",
        createPath: "/vouchers/purchase/create"
    },
    {
        viewLabel: "Payments",
        createLabel: "Payment",
        Icon: DollarOutlined ,
        path: "/vouchers/payment",
        createPath: "/dashboard/createPay/receipt"
    },
    {
        viewLabel: "Expenses",
        createLabel: "Expense",
        Icon: MoneyCollectOutlined,
        path: "/vouchers/payment",
        createPath: "/dashboard/createPay/payment"
    },
];
