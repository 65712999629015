import React from "react";
import CustomLoading from "./CustomLoading";
import {Image, List, Empty} from "antd-mobile";
import NoDataImg from "../../res/img/nodata.jpeg";
import {PRIMARY_COLOR} from "../../models/constants";

const CustomList = ({loading, records = [], rowUi, emptyDescription = "No Data"}) => {
    return (
        <>
            {
                loading ?
                    <CustomLoading/>
                    :
                    (
                        records.length ?
                            <List header='Data' style={{marginTop: 8}}>
                                {
                                    records.map(rowUi)
                                }
                            </List>
                            :
                            <div style={{
                                width: '100%',
                                height: '100%',
                                color: PRIMARY_COLOR,
                                fontSize: 35,
                                textAlign: 'center',
                                paddingTop: '20%',
                                border: '0px solid red'
                            }}>
                                <Empty
                                description={<span style={{ fontWeight: 'bold', color:"black" }}>{emptyDescription}</span>}
                                image = {NoDataImg} imageStyle={{ width: 100 }}
                                style={{ paddingTop: 50 }} />
                                {/* <Image src={NoDataImg} width={200}/> */}
                            </div>
                    )

            }
        </>
    );
}

export default CustomList;