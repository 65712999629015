import {EnumLayer} from "./layers.list";

/*
    Requirement examples::

    / :: Main
    /login :: Main, Login
    /dashboard :: Main, Home (tab=dashboard)
    /vouchers :: Main, Home (tab=bills)
    /settings :: Main, Home (tab=settings)
    /vouchers/invoice :: Main, Home (tab=bills, voucherType=invoice)
    /vouchers/invoice/view/vid :: Main, Home, ViewVoucher (tab=bills, voucherType=invoice, voucherId=vid)
    /vouchers/invoice/view/vid/edit :: Main, Home, VoucherView, VoucherWrite (tab=bills, voucherType=invoice, voucherId=vid)
    /vouchers/invoice/create :: Main, Home, VoucherView, VoucherWrite (tab=bills, voucherType=invoice)

    /login/abc :: Main, Login
    /dashboard/abc :: Main, Home (tab=dashboard)
    /abc :: Main, Home (tab=abc)


 */



const vouchersRoute = {
    children: {
        "*": {
            paramKey: "voucherType",
            next: {
                children: {
                    view: {
                        component: EnumLayer.VOUCHER_VIEW,
                        next: {
                            children: {
                                "*": {
                                    paramKey: "voucherId"
                                }
                            }
                        }
                    },
                    create: {
                        component: EnumLayer.VOUCHER_WRITE,
                    },
                    edit: {
                        component: EnumLayer.VOUCHER_WRITE,
                        next: {
                            children: {
                                "*": {
                                    paramKey: "voucherId",
                                }
                            }
                        }
                    }
                }
            }
        }
    }
};

const settingsRoute = {
    children: {
        profile: {
            component: EnumLayer.SETTINGS_PROFILE,
        },
        password: {
            component: EnumLayer.SETTINGS_PASSWORD,
        },
        company: {
            component: EnumLayer.SETTINGS_COMPANY,
        },
        payment: {
            component: EnumLayer.SETTINGS_PAYMENT,
        },
        invoice: {
            component: EnumLayer.SETTINGS_INVOICE,
        },
        help: {
            component: EnumLayer.SETTINGS_HELP,
        }
    }
}

const routingTree = {
    children: {
        login: {
            component: EnumLayer.LOGIN,
        },
        lastStep: {
            component: EnumLayer.LAST_STEP,
        },
        invoiceTemplates: {
            component: EnumLayer.INVOICE_TEMPLATES,
        },
        ecommerce: {
            component: EnumLayer.ECOMMERCE,
        },
        "*": {
            component: EnumLayer.HOME,
            paramKey: "currentHomeTab",
            nextMap: {
                dashboard: {
                    children: {
                        createPay: {
                            component: EnumLayer.PAY_IN_OUT,
                            next: {
                                children: {
                                    "*": {
                                        paramKey: "payType",
                                    }
                                }
                            }
                        },
                    }
                },
                vouchers: vouchersRoute,
                settings: settingsRoute,
                // ...
            }
        }
    }
}

export const parseRoutingUrl = (url) => {
    const paramChunks = url.split("/").filter(x => !!x);
    // console.log("## chunks ##", paramChunks);
    const stack = [];
    const paramValuesMap = {};
    let currentNode = routingTree;

    paramChunks.forEach((chunk) => {
        // console.log("## Chunk ##", chunk);
        let childConfig = currentNode?.children?.[chunk] || currentNode?.children?.["*"];
        if (!childConfig) {
            console.log(`## No child config for ${chunk} ##`);
            return;
        }

        if (childConfig.component) {
            stack.push(childConfig.component);
        }
        if (childConfig.paramKey) {
            paramValuesMap[childConfig.paramKey] = chunk;
        }

        currentNode = childConfig.nextMap?.[chunk] || childConfig.next;
    });

    return {stack: [EnumLayer.MAIN, ...stack], paramValuesMap};
}


const testRun = async () => {
    const url = "vouchers/proforma/view/123214"
    const data = parseRoutingUrl(url);
    console.log("## data ##", data);
}

// testRun();