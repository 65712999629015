import React, {useEffect, useMemo, useState} from "react";
import {Badge, TabBar} from "antd-mobile";
import {
    RiApps2Fill, RiApps2Line,
    RiFileTextFill,
    RiFileTextLine, RiGroupFill, RiGroupLine,
    RiHome4Fill,
    RiHome4Line
} from "react-icons/ri";
import {IoCubeOutline, IoCubeSharp} from "react-icons/io5";
import {logRender} from "../utils";
import {useNavigate} from "react-router-dom";


const tabStyle = {marginTop: 8};
const iconFontSize = 28;
const tabs = [
    {
        key: 'dashboard',
        title: <div style={tabStyle}>Home</div>,
        icon: active => active ? <RiHome4Fill style={{fontSize: iconFontSize}} /> : <RiHome4Line style={{fontSize: iconFontSize}} />,
        badge: Badge.dot,
    },
    {
        key: 'vouchers',
        title: <div style={tabStyle}>Bills</div>,
        icon: active => active ? <RiFileTextFill style={{fontSize: iconFontSize}} /> : <RiFileTextLine style={{fontSize: iconFontSize}} />,
        badge: '5',
    },
    {
        key: "ledgers",
        title: <div style={tabStyle}>Parties</div>,
        icon: active => active ? <RiGroupFill style={{fontSize: iconFontSize}} /> : <RiGroupLine style={{fontSize: iconFontSize}} />,
    },
    {
        key: 'items',
        title: <div style={tabStyle}>Items</div>,
        icon: active => active ? <IoCubeSharp style={{fontSize: iconFontSize}} /> : <IoCubeOutline style={{fontSize: iconFontSize}} />,
        badge: '5',
    },
    {
        key: 'settings',
        title: <div style={tabStyle}>More</div>,
        icon: active => active ? <RiApps2Fill style={{fontSize: iconFontSize}} /> : <RiApps2Line style={{fontSize: iconFontSize}} />,
    },
    // {
    //     key: 'personalCenter',
    //     title: 'Account',
    //     icon: <UserOutline/>,
    // },
]


export const BottomTabBar = React.memo(({activeKey, setCurrentTab}) => {

    logRender("BottomTabBar");
    const navigate = useNavigate();

    return (
            <TabBar style={{
                height: 70,
                background: 'white',
                borderTop: '1px solid lightgrey'}} activeKey={activeKey} onChange={(newKey) => {
                // setCurrentTab(newKey);
                navigate(`/${newKey}`);
            }}>
                {tabs.map(item => (
                    <TabBar.Item style={{padding: 0, '--font-size': '20px', color: activeKey===item.key ? '#1457C6' : 'black'}}
                                 key={item.key} icon={item.icon} title={
                                     <div style={{fontSize: 13}}>
                                         {item.title}
                                     </div>
                                 } />
                ))}
            </TabBar>
    );
});

