import {useDispatch} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {EDispatchType} from "../services/store";

export function useCustomDispatch(type = EDispatchType.BASIC) {
    const reduxDispatch = useDispatch();
    const customDispatch = useCallback((props) => reduxDispatch({type, props}), [reduxDispatch]);
    return customDispatch;
}

export function useTransitionedVisibility(requiredVisibility) {
    const [isInitialised, setIsInitialised] = useState(false);

    useEffect(() => {
        if (!isInitialised && requiredVisibility) {
            setIsInitialised(true);
        }
    }, [requiredVisibility]);

    const existence = isInitialised || requiredVisibility;
    const actualVisibility = isInitialised && requiredVisibility;

    return [existence, actualVisibility];
}