import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input } from "antd";
import { useSelector } from "react-redux";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  EditOutlined,
} from "@ant-design/icons";

import FlexContainer from "../../../components/FlexContainer";
import CustomFAB from "../../../components/CustomFAB";
import CustomList from "../../../components/CustomList";
import StackActivity from "../../../components/StackActivity";

import { RUPEE } from "../../../../models/constants";

import { beautifyAmount } from "../../../../utils";

import { baseUrl } from "../../../../services/api";
import {Z_INDEXES} from "../../../../styles/js/z_indexes";
import {ELayerTrigger, ELayerType, useLayersFramework} from "../../../v2framework/framework.hooks";
import {EnumLayer} from "../../../v2framework/layers.list";

const ItemCard = () => {
    const {pushInAppLayer} = useLayersFramework();
  const state = useSelector((state) => state);
  const data = state && state.items;

  const [searchText, setSearchText] = useState("");
  const [showItemDrawer, setShowItemDrawer] = useState(false);
  const [showStockDrawer, setShowStockDrawer] = useState(false);
  const [itemData, setItemData] = useState({});
  const [stockData, setStockData] = useState({
    stock : {},
    stockIn : false,
  });

  useEffect(() => {
    if(!showItemDrawer){
      setItemData({});
    }
    if(!showStockDrawer){
      setStockData({
        stock : {},
    stockIn : false,
      })
    }
  },[showItemDrawer, showStockDrawer])

  const Card = (data) => {
    const cardData = data.data;

    const editItem = () => {
        // setShowItemDrawer(true);
        pushInAppLayer({
            trigger: ELayerTrigger.IN_APP,
            type: ELayerType.FULL_SCREEN,
            target: EnumLayer.SAVE_ITEM,
        }, {
            saveItemActivityArgs: {
                selectedItem: cardData,
                onSave: () => {},
            }
        })
    }

    const stockInOutData = (In) => {
      setStockData({
        stock: cardData,
        stockIn: In});
      setShowStockDrawer(true); 
    }

    return (
      <CardContainer className={"greyLightHoverEffect"}>
        <CardContent>
          <InnerContainer width="30%">
            <img
              src={
                cardData.img
                  ? `${baseUrl}/internal/file/${cardData.img}`
                  : "https://vyaparwebsiteimages.vypcdn.in/catalogue/product-placeholder.png"
              }
              alt=" "
              style={{ height: "18mm" }}
            />
          </InnerContainer>
          <InnerContainer width="40%" margin>
            <Text size="14px" bold>
              {cardData.name}
            </Text>
            <Text size="14px" color="grey">
              {cardData.type || "Product"}
            </Text>
          </InnerContainer>
          <InnerContainer width="30%" flexEnd>
            <Text size="14px" bold>
              {RUPEE} {beautifyAmount(cardData.defaultRate || 0)}
            </Text>
            <Text size="12px" color="grey">
              Tax: {cardData.taxRate}%
            </Text>
            <Text
              size="14px"
              color={
                cardData.qty ? (cardData.qty > 0 ? "green" : "red") : "green"
              }
              bold
            >
              Qty {cardData.qty || 0}
            </Text>
          </InnerContainer>
        </CardContent>
        <ButtonsContainer>
          <StyledButton onClick={editItem} className={ "primaryEffect" }>
            <EditOutlined /> Edit
          </StyledButton>
          <StyledButton onClick={() => stockInOutData(true)} className={ "greenEffect" }>
          <ArrowDownOutlined /> Stock In
          </StyledButton>
          <StyledButton onClick={() => stockInOutData(false)} className={ "redEffect" }>
          <ArrowUpOutlined /> Stock Out
          </StyledButton>
        </ButtonsContainer>
      </CardContainer>
    );
  };

  return (
    <>
      <OuterContainer>
        <FlexContainer
          header={
            <Input.Search
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={"Search with Item Name"}
              style={{
                width: "100%",
                minWidth: 300,
                fontSize: 18,
                position: "fixed",
                padding: "3%",
                marginTop: "-1",
                background: "white",
                zIndex: Z_INDEXES.DEFAULT,
              }}
              size={"large"}
              enterButton={true}
              allowClear
            />
          }
          content={
            <ItemList>
              <CustomList
                loading={false}
                records={
                  data &&
                  data.filter((r) =>
                    (r.name || "")
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  )
                }
                rowUi={(r) => <Card data={r} />}
                emptyDescription = "No Items"
              />
            </ItemList>
          }
        />
        <NewItem onClick={() => {
            setShowItemDrawer(true);
            pushInAppLayer({
                trigger: ELayerTrigger.IN_APP,
                type: ELayerType.FULL_SCREEN,
                target: EnumLayer.SAVE_ITEM,
            }, {
                saveItemActivityArgs: {
                    selectedItem: undefined,
                    onSave: () => {},
                }
            })
        }}>
          <CustomFAB title="New Item" Bottom />
        </NewItem>
      </OuterContainer>
      {/*<StackActivity*/}
      {/*  visible={showItemDrawer}*/}
      {/*  setVisible={setShowItemDrawer}*/}
      {/*  type="itemSaveActivity"*/}
      {/*  data={itemData}*/}
      {/*/>*/}
      <StackActivity
        visible={showStockDrawer}
        setVisible={setShowStockDrawer}
        type="stockInOut"
        data={stockData}
      />
    </>
  );
};

export default ItemCard;

const OuterContainer = styled.div``;

const Text = styled.div`
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
  font-weight: ${({ bold }) => bold && "bold"};
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 20px;
  border: 2px solid lightgray;
  margin: 0px 0px 10px;
  padding: 8px;
  gap: 5px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
`;

const ItemList = styled.div`
  margin-bottom: 15%;
  margin-top: 12%;
  padding: 3%;
`;

const InnerContainer = styled.div`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  align-items: ${({ flexEnd }) => flexEnd && "flex-end"};
  margin-left: ${({ margin }) => margin && "5%"};
`;

const NewItem = styled.div``;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled.button`
  width: 30%;
  height: 30px;
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
`;
