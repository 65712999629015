import {MainLayer} from "./main.layer";
import {LoginActivity} from "../activities/login.activity";
import {HomeActivity} from "../activities/home.activity";
import {LastStepActivity} from "../activities/lastStep.activity";
import VoucherViewActivity from "../activities/voucherView/voucherView.activity";
import VoucherWriteActivity from "../activities/voucherWrite/voucherWrite.activity";
import {EnumLayer} from "./layers.list";
import AddItemLineActivity from "../activities/voucherWrite/addItemLine/addItemLine.activity";
import {SelectItemDrawerLayer} from "../activities/voucherWrite/addItemLine/selectItemDrawer.layer";
import ProfileSettings from "../profile";
import CompanySettings from "../activities/tabs/settings/CompanySettings";
import PaymentGateway from "../activities/tabs/settings/PaymentGateway";
import PasswordSettings from "../activities/tabs/settings/password";
import InvoiceSettings from "../activities/tabs/settings/InvoiceSettings";
import HelpSettings from "../activities/tabs/settings/help";
import LastStep from "../laststep";
import InvoiceTemplates from "../invoiceTemplates";
import EcommerceStore from "../ecommerceStore/ecommerceStore";
import {SwitchCompanyLayer} from "../layers.inapp/switchCompany.layer";
import DashboardPayInOut from "../activities/tabs/dashboard/DashboardPayInOut";
import BottomSelect from "../components/BottomSelect";
import CustomerSelectionActivity from "../vouchers/selectClient";
import LedgerSave from "../activities/tabs/ledgers/LedgerSave";
import CustomerTransactions from "../activities/tabs/ledgers/CustomerTransactions";
import ItemSave from "../activities/tabs/items/itemSave";


export const LayersRegistry = {
    [EnumLayer.MAIN]: <MainLayer/>,
    [EnumLayer.LOGIN]: <LoginActivity/>,
    [EnumLayer.HOME]: <HomeActivity/>,
    [EnumLayer.LAST_STEP]: <LastStep/>,
    [EnumLayer.VOUCHER_VIEW]: <VoucherViewActivity/>,
    [EnumLayer.VOUCHER_WRITE]: <VoucherWriteActivity/>,
    [EnumLayer.INVOICE_TEMPLATES]: <InvoiceTemplates/>,
    [EnumLayer.ECOMMERCE]: <EcommerceStore/>,
    [EnumLayer.PAY_IN_OUT]: <DashboardPayInOut/>,


    [EnumLayer.ADD_ITEM_LINE]: <AddItemLineActivity/>,
    [EnumLayer.SELECT_ITEM_DRAWER]: <SelectItemDrawerLayer/>,

    [EnumLayer.SELECT_CUSTOMER]: <CustomerSelectionActivity/>,
    [EnumLayer.SAVE_LEDGER]: <LedgerSave />,
    [EnumLayer.LEDGER_STATEMENT]: <CustomerTransactions />,
    [EnumLayer.SAVE_ITEM]: <ItemSave/>,

    // Settings:
    [EnumLayer.SETTINGS_PROFILE]: <ProfileSettings/>,
    [EnumLayer.SETTINGS_COMPANY]: <CompanySettings/>,
    [EnumLayer.SETTINGS_PAYMENT]: <PaymentGateway/>,
    [EnumLayer.SETTINGS_PASSWORD]: <PasswordSettings/>,
    [EnumLayer.SETTINGS_INVOICE]: <InvoiceSettings/>,
    [EnumLayer.SETTINGS_HELP]: <HelpSettings/>,


    // in-app layers::
    [EnumLayer.SWITCH_COMPANY]: <SwitchCompanyLayer/>,
    [EnumLayer.SELECT_UNIT]: <BottomSelect /> 
}

// export const DRAWERS = {
//     BOTTOM: {
//         SELECT: <BottomSelect />,
//     }
// }