import React, {useState} from 'react';
import {logRender} from "../../utils";

export const MySelectPopup = React.memo(({value, onChange, options}) => {
    logRender("MySelectPopup");

    return (
        <select className={"myDropDownInput"} onChange={e => onChange?.(e.target.value)} value={value}>
            {
                options.map(opt => <option value={opt.value}>{opt.label}</option>)
            }
        </select>
    );
})