/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useCallback} from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  Button,
  Dialog,
  Form,
  Input,
  List,
  Modal,
  Popup,
  TextArea,
} from "antd-mobile";
import {
  AppstoreOutline,
  CheckCircleFill,
  DownlandOutline,
  MessageOutline,
} from "antd-mobile-icons";
import {
  FileExcelOutlined,
  CopyOutlined,
  LinkOutlined,
  MailOutlined,
  ShareAltOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import Activity from "../../../components/Activity";
import Text from "../../../components/Text";
import CustomList from "../../../components/CustomList";
import {
  beautifyAmount,
  PublicShareLinks,
  getPublicShareLink,
} from "../../../../utils";
import { PRIMARY_COLOR, RUPEE } from "../../../../models/constants";
import {
  getInternalLedgerStatement,
  emailInvoice,
  baseUrl,
} from "../../../../services/api";
import StackActivity from "../../../components/StackActivity";
import { Alert, Input as InputB } from "antd";
import {
  appListener,
  arePermissionsGranted,
  requestPermissions,
  sendDataToApp,
} from "../../../../utils/webviewUtils";
import { MobileButtonV2 } from "../../../components/MobileButtonV2";
import {checkAndRequestPermissionsV2} from "../../../../utils/webViewUtilsV2/interfaces";
import {ELayerTrigger, ELayerType, useLayersFramework} from "../../../v2framework/framework.hooks";
import {EnumLayer} from "../../../v2framework/layers.list";

const CustomerTransactions = ({}) => {
    const {goBack, pushInAppLayer, args: layerArgs} = useLayersFramework();
    const onCancel = goBack;
    const customerData = layerArgs.ledgerStatementLedgerData;
  const defaultRangeFrom = moment().set({ year: 2001, month: 1 });
  const defaultRangeTo = moment();

  const [loading, setLoading] = useState(true);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showPay, setShowPay] = useState(false);
  const [ref, setRef] = useState();

  const [waForm] = Form.useForm();
  const [emailForm] = Form.useForm();
  const [showCopied, setShowCopied] = useState(false);

  const state = useSelector((state) => state);

  const [showWaModal, setShowWaModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [cardData, setCardData] = useState({
    statement: [],
    ledger: {},
    summary: {},
  });

  const fileName = customerData?.date
    ? `${customerData?.type.filePrefix}-${
        customerData.documentNumber?.prefix || ""
      }${customerData.documentNumber?.num || ""}-${moment(
        customerData.date
      ).format("DD-MM-YYYY")}.pdf`
    : "document.pdf";

  const invoiceNum = `${customerData?.hashId}`;

  const publicLink = getPublicShareLink(
    PublicShareLinks.ledger,
    customerData?.hashId
  );

  const downloadPdfUrl = `${baseUrl}/internal/pdf/${
    customerData?._id
  }?type=ledgerStatement&from=${defaultRangeFrom.valueOf()}&to=${defaultRangeTo.valueOf()}`;

  const downloadExcelUrl = `${baseUrl}/internal/export?ledgerId=${
    customerData?._id
  }&type=ledgerStatement&from=${defaultRangeFrom.valueOf()}&to=${defaultRangeTo.valueOf()}`;

  const fetchStatement = useCallback(async () => {
    setLoading(true);
    const resp = await getInternalLedgerStatement(
      customerData._id,
      defaultRangeFrom.valueOf(),
      defaultRangeTo.valueOf()
    );
    setCardData(resp.data.data);
    setLoading(false);
  }, [customerData?._id, defaultRangeFrom, defaultRangeTo]);

  useEffect(() => {
    if (customerData && customerData._id && !visible && !showPay) {
      fetchStatement();
    }
  }, [visible, showPay, customerData]);

  const shareDocument = async ({ channel, phone }) => {
    const title = "Invoice";
    const message = `Hi ${customerData?.name}, Thank you for your business. Here is your tax invoice: ${publicLink} You can view or download PDF using this link.`;
    let filePath;
    if (channel === "whatsapp" && phone) {
      phone = `91${phone}`;
    }
    setShowWaModal(false);
    setShowSharePopup(false);
    if (channel === "whatsapp" || channel === "others") {
      const result = await appPermissionsHandler();
      if (!result) {
        return;
      }
      setLoading("Generating PDF to share");
      filePath = await downloadFile("pdf", true);
      setLoading("");
    }
    sendDataToApp({
      type: "shareDocument",
      payload: { channel, filePath, phone, message, title },
    });
  };

  const downloadFile = (downloadType, sendNotificationFlag) => {
    return new Promise((resolve) => {
      appListener("downloadFile", ({ filePath }) => {
        resolve(filePath);
      });
      sendDataToApp({
        type: "downloadFile",
        payload: {
          url: downloadType === "pdf" ? downloadPdfUrl : downloadExcelUrl,
          fileName,
          notification: sendNotificationFlag,
        },
      });
    });
  };

  const onClickPdf = async () => {
    const result = await appPermissionsHandler();
    if (!result) {
      return;
    }
    setLoading("Downloading PDF");
    const filePath = await downloadFile("pdf", true);
    setLoading("");
    Dialog.confirm({
      header: (
        <CheckCircleFill
          style={{
            fontSize: 64,
            color: "var(--adm-color-success)",
          }}
        />
      ),
      closeOnMaskClick: true,
      content: <div>{`File (${fileName}) saved to your Downloads.`}</div>,
      confirmText: "Open Now",
      cancelText: "Okay",
      onConfirm: async () => {
        console.log("## Open Now ##");
        sendDataToApp({ type: "openPdf", payload: filePath });
        // await deleteVoucher(voucherId);
        // await new Promise(resolve => setTimeout(resolve, 1500));
        // navigate(`/vouchers/${voucherType}`);
      },
    });
  };

  const onClickExcel = async () => {
    const result = await appPermissionsHandler();
    if (!result) {
      return;
    }
    setLoading("Downloading PDF");
    await downloadFile("excel", true);
    setLoading("");
  };

  const appPermissionsHandler = async () => {
      const result = await checkAndRequestPermissionsV2();  // "To enjoy this feature, you need to provide storage permission."
      if (!result) {
          Dialog.alert({
              title: "Error",
              content:
                  "It seems like you have not provided storage permissions. To enjoy this feature, please allow storage permissions in Settings > Apps > KhataBuddy > Permissions > Storage > Allow",
              confirmText: "Okay",
          });
      }
      return result;
  };
  const typesMap = {
    invoice: "Sale",
    receipt: "Receipt",
    purchase: "Purchase",
    payment: "Payment",
  };

  const Card = ({ data }) => {
    return (
      <CardContainer>
        <Container width={45}>
          <InlineContainer space>
            <Text
              fontSize={14}
              content={
                data.isSpecial
                  ? data.specialText
                  : moment(data.date).format("DD MMM YYYY")
              }
            />
            |<Text fontSize={14} content={typesMap[data.type] || ""} />
          </InlineContainer>
          <Text
            fontSize={18}
            fontWeight={600}
            color="#625a5a"
            content={customerData.name}
          />
        </Container>
        <Container width={25}>
          <Text
            fontSize={16}
            fontWeight={600}
            color={data.amount < 0 ? "red" : "green"}
            content={RUPEE + beautifyAmount(data.amount)}
          />
        </Container>
        <Container width={30}>
          <Text
            fontSize={16}
            fontWeight={600}
            color={data.closingBalance < 0 ? "red" : "green"}
            content={
              RUPEE +
              beautifyAmount(data.closingBalance) +
              (data.closingBalance > 0 ? "(Dr)" : "(Cr)")
            }
          />
        </Container>
      </CardContainer>
    );
  };

  return (
    <>
      <Activity
        heading="Statement"
        onBack={() => {
          onCancel();
        }}
        content={
          <OuterContainer>
            <HeaderContainer>
              <InlineContainer end>
                <MobileButtonV2
                  style={{
                    background: "#ffebee",
                    color: "#b71c1c",
                    padding: 0,
                    height: 28,
                    fontWeight: "bold",
                    fontSize: 13,
                    paddingLeft: 8,
                    paddingRight: 8,
                    border: "1px solid #b71c1c",
                    borderRadius: "10px",
                  }}
                  onClick={onClickPdf}
                >
                  <DownlandOutline />
                  &nbsp;PDF
                </MobileButtonV2>
                <Button
                  style={{
                    background: "#e8f5e9",
                    color: "#1b5e20",
                    padding: 0,
                    height: "28px",
                    fontWeight: "bold",
                    fontSize: 13,
                    paddingLeft: 8,
                    paddingRight: 8,
                    border: "1px solid #1b5e20",
                    borderRadius: "10px",
                  }}
                  onClick={() => setShowSharePopup(true)}
                >
                  <ShareAltOutlined />
                  &nbsp;Share
                </Button>
                <MobileButtonV2
                  style={{
                    background: "#ffecb3",
                    color: "#ff6f00",
                    padding: 0,
                    height: 28,
                    fontWeight: "bold",
                    fontSize: 13,
                    paddingLeft: 8,
                    paddingRight: 8,
                    border: "1px solid #ff6f00",
                    borderRadius: "10px",
                  }}
                  onClick={onClickExcel}
                >
                  <FileExcelOutlined style={{ color: "#ff6f00" }} /> Excel
                </MobileButtonV2>
              </InlineContainer>
              <Text
                fontSize={22}
                fontWeight={600}
                color="#625a5a"
                content={customerData.name}
              />
              <Text fontSize={18} fontWeight={600} content="Net Balance" />
              <InlineContainer>
                <Text
                  fontSize={20}
                  fontWeight={600}
                  color={cardData.summary?.closing <= 0 ? "red" : "green"}
                  content={
                    RUPEE + beautifyAmount(cardData.summary?.closing || "")
                  }
                />
                <button
                  style={{
                    height: "40px",
                    borderRadius: "10px",
                    border: "none",
                    color: "white",
                    backgroundColor: "#0080ff",
                    width: "60px",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                      setVisible(true);
                      pushInAppLayer({
                          trigger: ELayerTrigger.IN_APP,
                          type: ELayerType.FULL_SCREEN,
                          target: EnumLayer.SAVE_LEDGER,
                      }, {
                          ledgerSaveTargetVendorType: customerData.type,
                          ledgerSaveSelectedRecord: customerData,
                          ledgerSaveOnSave: () => {},
                      })
                  }}
                >
                  Edit
                </button>
              </InlineContainer>
            </HeaderContainer>
            <InlineContainer padding>
              <Container width={40}>
                <Text fontSize={18} fontWeight={600} content="ENTRIES" />
              </Container>
              <Container width={25}>
                <Text fontSize={18} fontWeight={600} content="AMOUNT" />
              </Container>
              <Container width={30}>
                <Text fontSize={18} fontWeight={600} content="BALANCE" />
              </Container>
            </InlineContainer>
            <CardList>
              <CustomList
                loading={loading}
                records={cardData.records}
                rowUi={(r) => <Card data={r} />}
              />
            </CardList>
            <ButtonContainer onClick={() => setShowPay(true)}>
              {customerData.type === "creditor" ? "Pay Out" : "Pay In"}
            </ButtonContainer>
          </OuterContainer>
        }
      />

      <Modal
        style={{ width: "100%" }}
        visible={showEmailModal}
        title={"Email Document"}
        closeOnMaskClick={true}
        content={
          <div style={{ width: "100%" }}>
            <Form form={emailForm} style={{ padding: 0, margin: 0 }}>
              <Form.Item
                name={"to"}
                style={{ width: "100%", padding: 0, margin: 0 }}
                label={"Recipient email address"}
                rules={[
                  { required: true, type: "email", message: "Invalid email" },
                ]}
              >
                <Input
                  placeholder={"Enter email aaddress"}
                  style={{
                    border: "1px solid #333",
                    padding: 4,
                    borderRadius: 4,
                    width: "100%",
                  }}
                />
              </Form.Item>
              <Form.Item
                name={"subject"}
                label={"Email Subject"}
                rules={[{ required: true, message: "can't be blank" }]}
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Input
                  placeholder={"Email Subject"}
                  style={{
                    border: "1px solid #333",
                    padding: 4,
                    borderRadius: 4,
                    width: "100%",
                  }}
                />
              </Form.Item>
              <Form.Item
                name={"body"}
                label={"Email Body"}
                rules={[{ required: true, message: "can't be blank" }]}
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <TextArea
                  style={{
                    border: "1px solid #333",
                    padding: 4,
                    borderRadius: 4,
                    width: "100%",
                    "--font-size": "14px",
                  }}
                  rows={4}
                />
              </Form.Item>
            </Form>
            <p style={{ color: PRIMARY_COLOR }}>
              <i>Invoice PDF will be automatically attached with email.</i>
            </p>
            {/*<Alert type={"info"}*/}
            {/*       description={"Invoice PDF will be automatically attached with email."}*/}
            {/*/>*/}
          </div>
        }
        onClose={() => setShowEmailModal(false)}
        actions={[{ key: "send", text: "Send Email", primary: true }]}
        onAction={async (action) => {
          console.log("## Action ##", action);

          const formData = await emailForm.validateFields();
          console.log("## formData ##", formData);

          formData.from = "";
          formData.ledgerId = customerData?._id;
          formData.filename = fileName;
          await emailInvoice(formData);

          await new Promise((resolve) => setTimeout(resolve, 2500));
          setShowEmailModal(false);

          Dialog.alert({
            title: "Email sent successfully",
            header: (
              <CheckCircleFill
                style={{
                  fontSize: 64,
                  color: "var(--adm-color-success)",
                }}
              />
            ),
            closeOnMaskClick: true,
            confirmText: "Okay",
          });
        }}
        closeOnAction
      />
      <Modal
        visible={showLinkModal}
        title={"Public URL Link"}
        closeOnMaskClick={true}
        content={
          <div>
            <InputB ref={(thisRef) => setRef(thisRef)} value={publicLink} />
            <br />
            <br />
            <Alert
              type={"info"}
              description={
                "This is a public URL. Anyone using this link can view or download this invoice. You can share it with your clients."
              }
            />
            <br />
            <MobileButtonV2
              style={{ width: "100%" }}
              color={"primary"}
              onClick={async (e) => {
                ref.select();
                document.execCommand("copy");
                setShowCopied(true);
                e.target.focus();
              }}
            >
              <CopyOutlined />
              &nbsp;Copy to clipboard
            </MobileButtonV2>
            {showCopied && (
              <p
                style={{
                  width: "100%",
                  color: "green",
                  textAlign: "center",
                  marginTop: 16,
                }}
              >
                Copied !
              </p>
            )}
          </div>
        }
        onClose={() => setShowLinkModal(false)}
        closeOnAction
      />

      <Modal
        visible={showWaModal}
        title={"Share using Whatsapp"}
        closeOnMaskClick={true}
        content={
          <div>
            <Form form={waForm}>
              <Form.Item
                name={"phone"}
                label={"Whatsapp Mobile Number"}
                rules={[
                  { required: true, message: "Invalid mobile number", len: 10 },
                ]}
              >
                <Input
                  type={"number"}
                  max={9999999999}
                  placeholder={"Enter 10 digit number"}
                  style={{
                    border: "1px solid #333",
                    padding: 4,
                    borderRadius: 4,
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Form>
            <MobileButtonV2
              style={{ width: "100%" }}
              color={"primary"}
              onClick={async () => {
                const { phone } = await waForm.validateFields();
                shareDocument({ channel: "whatsapp", phone });
              }}
            >
              Share on this number
            </MobileButtonV2>
            <center style={{ color: "#666" }}>or</center>
            <Button
              style={{ width: "100%" }}
              color={"success"}
              onClick={() => {
                shareDocument({ channel: "whatsapp" });
              }}
            >
              Choose Recipient in App
            </Button>
          </div>
        }
        onClose={() => setShowWaModal(false)}
        closeOnAction
      />
      <Popup
        visible={showSharePopup}
        onMaskClick={() => {
          setShowSharePopup(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }}
      >
        <div style={{ height: "50vh", overflow: "scroll" }}>
          <List>
            <List.Item
              prefix={<WhatsAppOutlined />}
              onClick={() => {
                setShowSharePopup(false);
                setShowWaModal(true);
                waForm.setFieldsValue({
                  phone: customerData?.phone || "",
                });
              }}
            >
              Whatsapp
            </List.Item>
            <List.Item
              prefix={<MessageOutline />}
              onClick={() => {
                const phone = customerData?.phone;
                if (phone && typeof phone === "string" && phone.length === 10) {
                  shareDocument({ channel: "sms", phone });
                } else {
                  Dialog.alert({
                    title: "Error",
                    content:
                      "Invalid mobile number in client details of invoice.",
                    confirmText: "Okay",
                  });
                }
              }}
            >
              SMS
            </List.Item>
            <List.Item
              prefix={<MailOutlined />}
              onClick={() => {
                setShowSharePopup(false);
                setShowEmailModal(true);
                emailForm.setFieldsValue({
                  to: customerData?.email,
                  subject: `Invoice ${invoiceNum} from ${state?.company?.name}`,
                  body: `Hi ${customerData?.name},\nThank you for your business.\nPlease find the tax invoice ${invoiceNum} attached with this email.\nRegards,\n${state?.company?.name}`,
                });
              }}
            >
              Email
            </List.Item>
            <List.Item
              prefix={<LinkOutlined />}
              onClick={() => {
                setShowSharePopup(false);
                setShowCopied(false);
                setShowLinkModal(true);
              }}
            >
              Public URL Link
            </List.Item>
            <List.Item
              prefix={<AppstoreOutline />}
              onClick={() => {
                shareDocument({ channel: "others" });
              }}
            >
              Others
            </List.Item>
          </List>
        </div>
      </Popup>
      {visible && !showPay ? (<></>
        // <StackActivity
        //   visible={visible}
        //   setVisible={setVisible}
        //   type="updateCustomer"
        //   data={customerData}
        // />
      ) : (
        <StackActivity
          visible={showPay}
          setVisible={setShowPay}
          type={customerData.type === "creditor" ? "payOut" : "payIn"}
          data={customerData}
        />
      )}
    </>
  );
};

export default CustomerTransactions;

const OuterContainer = styled.div``;

const HeaderContainer = styled.div`
  padding: 20px;
  background: #eae9e9;
  margin-bottom: 10px;
`;

const CardList = styled.div`
  height: 450px;
  overflow: scroll;
  padding: 5px 20px;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: -2px;
  width: 100%;
  height: 50px;
  font-size: 22px;
  background: #0080ff;
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const InlineContainer = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: ${({ space, end }) =>
    end ? "flex-end" : space ? "flex-start" : "space-between"};
  align-items: center;
  gap: ${({ end }) => "10px"};
  padding: ${({ padding }) => padding && "0px 20px"};
`;

const Container = styled.div`
  width: ${({ width }) => `${width}%`};
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 20px;
  border: 2px solid lightgray;
  margin: 0px 0px 21px;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
`;
