/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useMemo} from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Loading } from "antd-mobile";
import { Button, Input } from "antd";
import { SearchOutline } from "antd-mobile-icons";

import Text from "../components/Text";
import StackActivity from "../components/StackActivity";
import { truncate } from "../../utils";
import {EmptyComponent} from "../components/EmptyComponent";
import {useLazyStoreData} from "../../hooks/useLazyStoreData.hook";
import {StoreDataTypes} from "../../hooks/useStoreDataLoader.hook";
import {ELayerTrigger, ELayerType, useLayersFramework} from "../v2framework/framework.hooks";
import ActivityWrapper from "../components/Activity";
import {EnumLayer} from "../v2framework/layers.list";

const CustomerSelectionActivity = ({}) => {
  const {params, args, goBack, pushInAppLayer} = useLayersFramework();
  const { onSaveSelectCustomer, selectedSelectCustomerId } = args;
  const selectedId = selectedSelectCustomerId;
  const onSave = onSaveSelectCustomer;
  const onCancel = goBack;
  const { voucherType } = params || {};
    const isVendorType = ["purchase", "debitNote"].includes(voucherType);
    const targetVendorType = isVendorType ? "creditor" : "debtor";
  const [allLedgers] = useLazyStoreData(StoreDataTypes.LEDGERS);
  const [searchText, setSearchText] = useState("");
  // const [addNewClient, setAddNewClient] = useState(false);

  const clientLedgers = useMemo(() => (allLedgers || []).filter(x => !x.type || x.type === targetVendorType), [allLedgers, targetVendorType]);
    const filteredLedgers = useMemo(() => {
        return (clientLedgers || []).filter((c) =>
            (c.name || " ").toLowerCase().includes(searchText.toLowerCase())
        )
    }, [clientLedgers, searchText]);

  const clientAdded = (id) => {
    onSave(id);
    onCancel();
  };

  return (
    <ActivityWrapper
      heading={
          isVendorType ? "Select Vendor" : "Select Customer"
      }
      onBack={() => {
        onCancel();
      }}
      content={
        <div style={{ padding: 6 }}>
          <SearchContainer>
            <div
              style={{
                fontSize: 24,
                verticalAlign: "middle",
                lineHeight: "30px",
                borderRadius: 8,
                display: "flex",
                flexDirection: "row",
                padding: 4,
                alignItems: "center",
                border: "2px solid #d0caca4a",
                margin: "8px",
                height: "40px",
                width: "95%",
              }}
            >
              <SearchOutline style={{ width: 30, color: "gray" }} />
              <Input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder={
                    isVendorType ? "Select Vendor" : "Select Customer"
                }
                style={{
                  width: "100%",
                  fontSize: 16,
                  paddingLeft: 8,
                  border: "none",
                  color: "gray",
                }}
                clearable
              />
            </div>
          </SearchContainer>
          <ListContainer>
            {!filteredLedgers ? (
              <Loading
                style={{
                  padding: "37%",
                  fontSize: "40px",
                  color: "#0080ff",
                  height: "60vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            ) : (
                !filteredLedgers.length ?
                    <EmptyComponent
                        message={`You have not added any ${isVendorType ? "vendor" : "customer"} yet.`}
                    />
                    :
              filteredLedgers.map(
                (i) =>
                  i.name && (
                    <ItemContainer
                      selected={selectedId === i._id}
                      onClick={() => {
                        onSave(i._id);
                        onCancel();
                      }}
                    >
                      <Text fontSize={16} fontWeight={600} content={i.name} />
                      <Text
                        fontSize={14}
                        color="grey"
                        content={i.address ? truncate(i.address, 80) : " "}
                      />
                    </ItemContainer>
                  )
              )
            )}
          </ListContainer>
          <ButtonContainer>
            <Button
              style={{
                width: "96%",
                height: "40px",
                fontSize: "16px",
                borderRadius: "12px",
                marginTop: "22px",
                marginLeft: "12px",
              }}
              type={"primary"}
              className={"fab primaryEffect generalRoundedButton"}
              onClick={() => {
                  pushInAppLayer({
                      trigger: ELayerTrigger.IN_APP,
                      type: ELayerType.FULL_SCREEN,
                      target: EnumLayer.SAVE_LEDGER,
                  }, {
                      ledgerSaveTargetVendorType: targetVendorType,
                      ledgerSaveOnSave: clientAdded,
                      ledgerSaveSelectedRecord: undefined,
                  })
              }}
            >
              {
                  isVendorType ? "Add New Vendor" : "Add New Customer"
              }
            </Button>
          </ButtonContainer>
          {/*{addNewClient && (*/}
          {/*  <StackActivity*/}
          {/*    visible={addNewClient}*/}
          {/*    setVisible={setAddNewClient}*/}
          {/*    type="addCustomer"*/}
          {/*    data={targetVendorType}*/}
          {/*    onSave={clientAdded}*/}
          {/*  />*/}
          {/*)}*/}
        </div>
      }
    />
  );
};

export default CustomerSelectionActivity;

const SearchContainer = styled.div`
  position: absolute;
  background: white;
  width: 100%;
  height: 55px;
  margin-top: -7px;
  margin-left: -4px;
`;

const ListContainer = styled.div`
  padding: 10px;
  overflow-y: scroll;
  height: 80vh;
  margin-top: 40px;
  margin-bottom: 60px;
`;

const ItemContainer = styled.div`
  border-bottom: 2px solid #d0caca4a;
  margin-bottom: 8px;
  padding: 0px 10px;
  background: ${({ selected }) => (selected ? "#d0caca4a" : "white")};
`;

const ButtonContainer = styled.div`
  width: 100%;
  bottom: -2px;
  position: absolute;
  background: white;
`;
