import {sendDataToApp} from "./webviewUtils";
import {createWhatsappIntent} from "./webViewUtilsV2/interfaces";

export const shareOnWhatsapp = ({message, imgUrl, imgBase64, whatsAppNumber}) => {
    createWhatsappIntent(message, whatsAppNumber);
    return;

    const shareExecutionCode = `
                const shareOptions = {
                    title: "Share",
                    ${message ? "message," : ""}
                    message,
                    ${(imgUrl || imgBase64) ? "url: imgBase64," : ""}
                    social: RNShare.Social.WHATSAPP,
                    whatsAppNumber,
                };
                RNShare.shareSingle(shareOptions);                
    `;

    let fetchBlobWrappedCode = `
        RNFetchBlob.fetch('GET', "${imgUrl || ""}")
            .then(resp => {
                imgBase64 = 'data:image/png;base64,' + resp.data;
                ${shareExecutionCode}
        })
            .catch(err => console.log("## SHARE_ERR ##", err));
        
    `;

    const customCode = `
        try {
            const message = \`${message || ""}\`;
            let imgBase64 = \`${imgBase64 || ""}\`;
            const whatsAppNumber = \`${whatsAppNumber || ""}\`;
            
            ${imgUrl ? fetchBlobWrappedCode : shareExecutionCode}
        } catch (err) {
            console.log("## Failed to share Whatsapp Msg ##", err);
        }        
        `;

    const msg = {
        type: "customCode",
        payload: customCode,
    };

    sendDataToApp(msg);
}