export const EnumLayer = {
    // main
    MAIN: "MAIN",

    // user-defined::

    // Base:
    LOGIN: "LOGIN",
    HOME: "HOME",
    LAST_STEP: "LAST_STEP",
    INVOICE_TEMPLATES: "INVOICE_TEMPLATES",
    ECOMMERCE: "ECOMMERCE",

    PAY_IN_OUT: "PAY_IN_OUT",


    // Vouchers:
    VOUCHER_VIEW: "VOUCHER_VIEW",
    VOUCHER_WRITE: "VOUCHER_WRITE",


    // Settings:
    SETTINGS_PROFILE: "SETTINGS_PROFILE",
    SETTINGS_PASSWORD: "SETTINGS_PASSWORD",
    SETTINGS_INVOICE: "SETTINGS_INVOICE",
    SETTINGS_COMPANY: "SETTINGS_COMPANY",
    SETTINGS_PAYMENT: "SETTINGS_PAYMENT",
    SETTINGS_HELP: "SETTINGS_HELP",


    // IN_APP layers:
    ADD_ITEM_LINE: "ADD_ITEM_LINE",
    SELECT_ITEM_DRAWER: "SELECT_ITEM_DRAWER",
    SWITCH_COMPANY: "SWITCH_COMPANY",

    SELECT_UNIT: "SELECT_UNIT",

    SELECT_CUSTOMER: "SELECT_CUSTOMER",
    SAVE_LEDGER: "SAVE_LEDGER",
    LEDGER_STATEMENT: "LEDGER_STATEMENT",
    SAVE_ITEM: "SAVE_ITEM",
}

