import { Switch } from "antd";
import React from "react";

const SWITCH_ON_COLOR = "#00c853";
const SWITCH_OFF_COLOR = "#ef5350";

export const SwitchSelect = (props) => {
  const { value, onChange = () => {}, valuesmap, reversemap, debug } = props;
  if (debug) {
    console.log("## Val ##", value, reversemap[value]);
  }
  return (
    <>
    <Switch
      {...props}
      defaultChecked={reversemap[value]}
      checked={reversemap[value]}
      onChange={(newVal) => onChange(valuesmap[newVal])}
      style={{
        background: reversemap[value] ? SWITCH_ON_COLOR : SWITCH_OFF_COLOR,
      }}
      
    />
   {console.log("koooooooo", reversemap[value], reversemap[value] ? SWITCH_ON_COLOR : SWITCH_OFF_COLOR)}
    </>
  );
};
