import {Popup} from "antd-mobile";
import React from "react";
import {logRender} from "../../utils";
import {useTransitionedVisibility} from "../../hooks/generic";
import {useDrawerActions, useDrawerData} from "../../hooks/drawer";
import {useLayersFramework} from "../v2framework/framework.hooks";


export const DrawersWrapper = React.memo(({id, popupStyles, children, forcedVisibility}) => {
    logRender("DrawerWrapper", id, !!forcedVisibility);
    let [params, visibility] = useDrawerData(id);
    if (forcedVisibility !== undefined) {
        visibility = forcedVisibility;
    }
    const [existence, actualVisibility] = useTransitionedVisibility(visibility);
    const [showDrawer, hideDrawer] = useDrawerActions();
    const {goBack} = useLayersFramework();

    return !existence ? <div></div> :
        <div>
            <Popup
                // style={ params?.zIndex ? {zIndex: params?.zIndex} : {} }
                visible={actualVisibility}
                onMaskClick={() => {
                    // hideDrawer(id);
                    goBack();
                    params?.onCancel?.();
                }}
                bodyStyle={{
                    width: "100%",
                    borderTopRightRadius: "5%",
                    height: "450px",
                    borderTopLeftRadius: "5%",
                    ...(popupStyles || {})
                }}
            >
                {children}
            </Popup>
        </div>;
});