import React, {useEffect} from "react";
import {Form, DatePicker, Select, Button, message} from "antd";
import {exportReports} from "../../services/api";
import moment from "moment";
import {useSelector} from "react-redux";
import { ButtonV2 } from "../components/ButtonV2";

const {RangePicker} = DatePicker;

const ExportData = () => {

    const state = useSelector(state => state);
    const [form] = Form.useForm();

    useEffect(() => {
    }, []);


    return (
        <div>
            <h2>Export Reports</h2>
            <Form
                form={form}
                layout={"vertical"}
                initialValues={{}}
            >
                <Form.Item name={"dateRange"} label={"Date Range"}
                           rules={[{required: true, message: "can't be blank"}]}>
                    <RangePicker picker="month"/>
                </Form.Item>

                <Form.Item name={"type"} label={"Export Type"} style={{width: 400}}
                           rules={[{required: true, message: "can't be blank"}]}>
                    <Select
                        placeholder={"Select Export Type"}
                        options={[
                            {label: "GST Report (Excel)", value: "gstExcel"},
                            // {label: "GST Report (JSON for GST Portal)", value: "gstJson"},
                            // {label: "XML for TallyERP", value: "xmlTally"}
                        ]}
                    />
                </Form.Item>

                <ButtonV2 type={"primary"} onClick={async () => {
                    const data = await form.validateFields();
                    if (data.type !== "gstExcel") {
                        message.info("Feature to be launched soon !");
                        return;
                    }
                    exportReports({
                        company: state?.company?._id,
                        type: data.type,
                        from: data.dateRange[0].startOf('month').valueOf(),
                        to: data.dateRange[1].endOf('month').valueOf()
                    })
                        .then((response) => response.blob())
                        .then((blob) => {
                            // Create blob link to download
                            const url = window.URL.createObjectURL(
                                new Blob([blob]),
                            );
                            const link = document.createElement('a');
                            link.href = url;
                            const fileName = `Gst_Reports_${moment().format("DDMMYYYYHHmmss")}.zip`;
                            link.setAttribute(
                                'download',
                                fileName
                            );
                            // Append to html link element page
                            document.body.appendChild(link);
                            // Start download
                            link.click();
                            // Clean up and remove the link
                            link.parentNode.removeChild(link);
                        });
                }}>Generate</ButtonV2>
            </Form>
        </div>
    )
};

export default ExportData;
