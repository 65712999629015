import {AntCloudOutlined} from "@ant-design/icons";
import Text from "./Text";
import {Button} from "antd-mobile";
import {AddCircleOutline} from "antd-mobile-icons";
import React from "react";
import styled from "styled-components";

export const EmptyComponent = ({message, extras}) => {
    return (
        <EmptyState>
            <AntCloudOutlined
                style={{color: "gray", fontSize: "80px"}}
            />
            <Text
                color="gray"
                fontSize={14}
                content={message || "No Data"}
            />
            {extras || <></>}
        </EmptyState>
    );
}

const EmptyState = styled.div`
display: flex;
  flex-direction: column;
  margin-top: 35%;
  align-items: center;
`;