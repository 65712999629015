import Cookies from "js-cookie";
import {isMobileApp, sendDataToApp} from "../utils/webviewUtils";
import {getValueFromAppStorage, setValueToAppStorage} from "../utils/webViewUtilsV2/interfaces";

export const EStorageKeys = {
    credentials: "credentials",
    dateRange: "dateRange",
}

const cachedMemory = {};

export class iStorage {
    static set(key, value) {
        console.log(`Set called`, key, JSON.stringify(value));
        if (typeof value === 'undefined') {
            return;
        }
        const stringifiedValue = JSON.stringify(value);
        // Save in cached memory (for caching):
        cachedMemory[key] = value;

        // Save in persistent storage (app storage or cookie):
        if (isMobileApp()) {    // Save in mobile app storage (for mobile app):
            console.log("## Calling setValue function ##", key, stringifiedValue);
            setValueToAppStorage(key, stringifiedValue);
        } else {     // Save in cookies (for mobile browser):
            Cookies.set(key, stringifiedValue, {expires: 30}); // 30 days
        }
    }

    static get(key) {
        console.log(`get called`, key);
        // Try1: Caching
        if (cachedMemory[key]) {
            return cachedMemory[key];
        }

        // Try2: App Storage or Cookie
        try {
            let stringifiedValue;
            if (isMobileApp()) {    // app - fetch from app storage
                stringifiedValue = getValueFromAppStorage(key);
                console.log("## stringified value from app ##", stringifiedValue);
            } else { // web - fetch from cookie
                stringifiedValue = Cookies.get(key);
            }

            const value = JSON.parse(stringifiedValue);
            cachedMemory[key] = value;
            return value;
        } catch(err) {}
    }
}