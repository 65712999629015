import {sendDataToApp} from "../utils/webviewUtils";

export const openDeepLinkUrl = (url) => {
    const customCode = `
        RN.Linking.openURL("${url}");
    `;


    const msg = {
        type: "customCode",
        payload: customCode
    };

    sendDataToApp(msg);
}