import React, {useCallback, useMemo} from "react";
import {Popup} from "antd-mobile";
import {logRender} from "../../utils";

const BottomPopup = ({ visible, setVisible, content, height }) => {
    logRender("BottomPopup", visible);
    const onMaskClick = useCallback(() => setVisible(false), [setVisible]);
    const popupStyles = useMemo(() => {
        return {
            width: "100%",
            borderTopRightRadius: "5%",
            height: height,
            borderTopLeftRadius: "5%",
        };
    }, [height]);

    return (
        <>
            <Popup
                visible={visible}
                onMaskClick={onMaskClick}
                bodyStyle={popupStyles}
            >
                {content}
            </Popup>
    </>
  );
};

export default BottomPopup;
