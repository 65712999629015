const jsonUnits = require('./units.json');
const jsonCountries = require('./countries.json');
const jsonStates = require('./states.json');

const indianStates = {
    "AN": {alphaCode: "AN", gstCode: "35", name: "Andaman and Nicobar Islands"},
    "AP": {alphaCode: "AP", gstCode: "37", name: "Andhra Pradesh"},
    "AR": {alphaCode: "AR", gstCode: "12", name: "Arunachal Pradesh"},
    "AS": {alphaCode: "AS", gstCode: "18", name: "Assam"},
    "BR": {alphaCode: "BR", gstCode: "10", name: "Bihar"},
    "CH": {alphaCode: "CH", gstCode: "04", name: "Chandigarh"},
    "CG": {alphaCode: "CG", gstCode: "22", name: "Chhattisgarh"},
    "DN": {alphaCode: "DN", gstCode: "26", name: "Dadra and Nagar Haveli"},
    "DD": {alphaCode: "DD", gstCode: "25", name: "Daman and Diu"},
    "DL": {alphaCode: "DL", gstCode: "07", name: "Delhi"},
    "GA": {alphaCode: "GA", gstCode: "30", name: "Goa"},
    "GJ": {alphaCode: "GJ", gstCode: "24", name: "Gujarat"},
    "HR": {alphaCode: "HR", gstCode: "06", name: "Haryana"},
    "HP": {alphaCode: "HP", gstCode: "02", name: "Himachal Pradesh"},
    "JK": {alphaCode: "JK", gstCode: "01", name: "Jammu and Kashmir"},
    "JH": {alphaCode: "JH", gstCode: "20", name: "Jharkhand"},
    "KA": {alphaCode: "KA", gstCode: "29", name: "Karnataka"},
    "KL": {alphaCode: "KL", gstCode: "32", name: "Kerala"},
    "LA": {alphaCode: "LA", gstCode: "38", name: "Ladakh"},
    "LD": {alphaCode: "LD", gstCode: "31", name: "Lakshadweep"},
    "MP": {alphaCode: "MP", gstCode: "23", name: "Madhya Pradesh"},
    "MH": {alphaCode: "MH", gstCode: "27", name: "Maharashtra"},
    "MN": {alphaCode: "MN", gstCode: "14", name: "Manipur"},
    "ML": {alphaCode: "ML", gstCode: "17", name: "Meghalaya"},
    "MZ": {alphaCode: "MZ", gstCode: "15", name: "Mizoram"},
    "NL": {alphaCode: "NL", gstCode: "13", name: "Nagaland"},
    "OR": {alphaCode: "OR", gstCode: "21", name: "Odisha"},
    "OT": {alphaCode: "OT", gstCode: "97", name: "Other Territory"},
    "PY": {alphaCode: "PY", gstCode: "34", name: "Puducherry"},
    "PB": {alphaCode: "PB", gstCode: "03", name: "Punjab"},
    "RJ": {alphaCode: "RJ", gstCode: "08", name: "Rajasthan"},
    "SK": {alphaCode: "SK", gstCode: "11", name: "Sikkim"},
    "TN": {alphaCode: "TN", gstCode: "33", name: "Tamil Nadu"},
    "TS": {alphaCode: "TS", gstCode: "36", name: "Telangana"},
    "TR": {alphaCode: "TR", gstCode: "16", name: "Tripura"},
    "UP": {alphaCode: "UP", gstCode: "09", name: "Uttar Pradesh"},
    "UA": {alphaCode: "UA", gstCode: "05", name: "Uttarakhand"},
    "WB": {alphaCode: "WB", gstCode: "19", name: "West Bengal"},
}

jsonStates["IN"] = indianStates;

const units = {};
jsonUnits.forEach(({code, name}) => {
    units[code] = {code, name};
})

const taxes = {
    "0": {name: "0%", rate: 0},
    "0.1": {name: "0.1%", rate: 0.1},
    "0.25": {name: "0.25%", rate: 0.25},
    "3": {name: "3%", rate: 3},
    "5": {name: "5%", rate: 5},
    "12": {name: "12%", rate: 12},
    "18": {name: "18%", rate: 18},
    "28": {name: "28%", rate: 28}
}

const old_voucherType = { // voucherTypes
    "invoice": {
        "name": "Sale Invoice",
        label: "Invoice",
        "plural": "Sale Invoices",
        filePrefix: "Invoice",
    },
    "proforma": {
        "name": "Proforma Invoice",
        "plural": "Proforma Invoices",
        filePrefix: "Proforma"
    },
    "delivery": {
        "name": "Delivery Challan",
        label: "Delivery Challan",
        "plural": "Delivery Challans",
        filePrefix: "Delivery",
    },
    "creditNote": {
        "name": "Credit Note",
        label: "Credit Note",
        "plural": "Credit Notes",
        filePrefix: "Credit Note",
    },
    "debitNote": {
        "name": "Debit Note",
        label: "Debit Note",
        "plural": "Debit Notes",
        filePrefix: "Debit Note",
    },
    "purchase": {
        "name": "Purchase",
        label: "Purchase",
        "plural": "Purchases",
        filePrefix: "Purchase",
    },
}

const RUPEE = "₹";

module.exports = {
    states: jsonStates,
    countries: jsonCountries,
    units,
    taxes,
    PRIMARY_COLOR: '#1677ff',
    RUPEE,
}