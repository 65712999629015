import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store from './services/store'
import './index.css';
import './styles/css/index.css';
import reportWebVitals from './reportWebVitals';
import {AppIndexPage} from "./views/v2framework";
import {initApp} from "./init.app";
import EntryComponent from "./views/entry";
import {
    BrowserRouter,
    Routes,
    Route, Outlet,
} from "react-router-dom";


const viewport = document.querySelector("meta[name=viewport]");
viewport.setAttribute("content", viewport.content + ", width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, height=" + window.innerHeight);

initApp();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter style={{fontFamily: 'sans-serif'}}>
                <Routes>
                    <Route path="*" element={<AppIndexPage/>}/>
                </Routes>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
