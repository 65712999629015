import React from 'react';
import {DrawersWrapper} from "../DrawersWrapper";
import {DRAWERS} from "../registry";
import {useDrawerActions, useDrawerData} from "../../../hooks/drawer";
import {Container, Header, InnerContainer} from "../../activities/tabs/dashboard/styled";
import Text from "../../components/Text";
import {CloseOutlined} from "@ant-design/icons";
import _ from "lodash";
import {viewList} from "../../activities/tabs/dashboard/vouchersList";
import {useDispatch, useSelector} from "react-redux";
import {customDispatcher} from "../../../utils";
import {useNavigate} from "react-router-dom";

const drawerId = DRAWERS.BOTTOM.CREATE_FAB;

export const CreateFabBottom = React.memo(({}) => {
    const state = useSelector((state) => state);
    const dispatch = customDispatcher(useDispatch());
    const [s, hidePopup] = useDrawerActions();
    const navigate = useNavigate();

    return <DrawersWrapper id={drawerId} popupStyles={{height: "270px"}}>
        <Container padding="20px" column noGap>
            <Header>
                <Text fontSize={20} fontWeight={600} content="Create New"/>
                <CloseOutlined
                    style={{fontSize: 20}}
                    onClick={() => hidePopup(drawerId)}
                />
            </Header>
            {_.chunk(
                viewList.map(({createLabel, createPath, Icon}) => (
                    <InnerContainer
                        width="25%"
                        className={"primaryLightHoverEffect"}
                        alignCenter={true}
                        onClick={() => {
                            if (createLabel === "Payment") {
                                dispatch({...state, showPayIn: true});
                                hidePopup(drawerId)
                            } else if (createLabel === "Expense") {
                                dispatch({...state, showPayOut: true});
                                hidePopup(drawerId)
                            } else if (createPath) {
                                navigate(createPath);
                                hidePopup(drawerId)
                            }
                        }}
                    >
                        <Icon style={{color: "#0080ff", fontSize: 25}}/>
                        <Text fontSize={12} fontWeight={500} content={createLabel}/>
                    </InnerContainer>
                )),
                4
            ).map((row, rowIndex) => (
                <Container top={rowIndex === 0}>{row}</Container>
            ))}
        </Container>
    </DrawersWrapper>
})