import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import styled, { keyframes, css } from "styled-components";
import { Radio, Swiper, Space } from "antd-mobile";
import { Button, Image, message } from "antd";
import { CheckCircleOutlined, CheckOutlined, ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

import img1 from "../../res/img/templates/inv1.jpg";
import img2Red from "../../res/img/templates/inv2Red.jpg";
import img2Blue from "../../res/img/templates/inv2Blue.jpg";
import img2Green from "../../res/img/templates/inv2Green.jpg";
import img2Black from "../../res/img/templates/inv2Black.jpg";
import img2Orange from "../../res/img/templates/inv2Orange.jpg";
import img2Voilet from "../../res/img/templates/inv2DarkViolet.jpg";
import img2Yellow from "../../res/img/templates/inv2Yellow.jpg";
import img3Green from "../../res/img/templates/inv3Green.jpg";
import img3Red from "../../res/img/templates/inv3Red.jpg";
import img3Blue from "../../res/img/templates/inv3Blue.jpg";
import img3Orange from "../../res/img/templates/inv3Orange.jpg";
import img3Black from "../../res/img/templates/inv3Black.jpg";
import img3Purple from "../../res/img/templates/inv3Purple.jpg";
import img3Yellow from "../../res/img/templates/inv3Yellow.jpg";

import img4Red from "../../res/img/templates/inv4Red.jpg";
import img4Green from "../../res/img/templates/inv4Green.jpg";
import img4Blue from "../../res/img/templates/inv4Blue.jpg";
import img4Orange from "../../res/img/templates/inv4Orange.jpg";
import img4Black from "../../res/img/templates/inv4Black.jpg";
import img4Voilet from "../../res/img/templates/inv4Voilet.jpg";
import img4Yellow from "../../res/img/templates/inv4yellow.jpg";
import img5Red from "../../res/img/templates/inv5red.jpg";
import img5Green from "../../res/img/templates/inv5Green.jpg";
import img5Blue from "../../res/img/templates/inv5Blue.jpg";
import img5Orange from "../../res/img/templates/inv5Orange.jpg";
import img5Black from "../../res/img/templates/inv5Black.jpg";
import img5Voilet from "../../res/img/templates/inv5Voilet.jpg";
import img5Yellow from "../../res/img/templates/inv5yellow.jpg";

import Text from "../components/Text";
import { saveCompany, loginRequest } from "../../services/api";
import { customDispatcher } from "../../utils";
import ActivityWrapper from "../components/Activity";


const InvoiceTemplates = () => {
  const state = useSelector((state) => state);
  const dispatch = customDispatcher(useDispatch());
  const [selectedColor, setSelectedColor] = useState("#165382");
  const [selectedTemplate, setSelectedTemplate] = useState("template2");
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const swiperRef = useRef(null)

  useEffect(() => {

    console.log("## UseTemplate effect ##");
    const _color = _.get(state, "company.invoiceConfig.color", "#165382");
    const _template = _.get(
      state,
      "company.invoiceConfig.template",
      "template2"
    );
    setSelectedColor(_color);
    setSelectedTemplate(_template);
    console.log("## Current selection ##", {
      _color,
      _template,
    });
    const index = templates.findIndex(t => t.value === selectedTemplate);
    setCurrentIndex(index);
  }, [state]);

  useEffect(() => {
    const index = templates.findIndex(t => t.value === selectedTemplate);
    if (swiperRef.current) {
      swiperRef.current.swipeTo(index);
    }
  }, [selectedTemplate]);


  const colorOptions = [
    { color: "#165382", label: "Blue" },
    { color: "green", label: "Green" },
    { color: "red", label: "Red" },
    { color: "#EE7600", label: "Orange" },
    { color: "#000000", label: "Black" },
    { color: "DarkViolet", label: "Violet" },
    { color: "#FFBF00", label: "Yellow" }
  ];

  const saveValues = async (obj) => {
    const company = _.cloneDeep(state?.company || {});
    if (!company.invoiceConfig) {
      company.invoiceConfig = {};
    }
    _.assign(company.invoiceConfig, obj);
    await saveCompany(company);
    await loginRequest("", "", dispatch);
    message.success("Invoice Template updated successfully!");
  };

  const tickIcon = (checked) =>
    checked && (
      <CheckOutlined style={{ fontSize: 14, marginBottom: 13 }} />
    );

    const imagesMap = {
      template1: {
        red: img1,
        green: img1,
        "#165382": img1,
        "#EE7600":img1,
        "#000000":img1,
        "DarkViolet":img1,
        "DarkGreen":img1,
        "#FFBF00":img1
      },
      template2: {
        red: img2Red,
        green: img2Green,
        "#165382": img2Blue,
        "#000000": img2Black,
        "#EE7600":img2Orange,
        "DarkViolet":img2Voilet,
        "#FFBF00":img2Yellow
      },
      template3: {
        "red": img3Red,
        "green": img3Green,
        "#165382": img3Blue,
        "#EE7600":img3Orange,
        "#000000":img3Black,
        "DarkViolet":img3Purple,
        "#FFBF00":img3Yellow
    },
    template4: {
        "red": img4Red,
        "green": img4Green,
        "#165382": img4Blue,
        "#EE7600":img4Orange,
        "#000000":img4Black,
        "DarkViolet":img4Voilet,
        "#FFBF00":img4Yellow
    },
    template5: {
        "red": img5Red,
        "green": img5Green,
        "#165382": img5Blue,
        "#EE7600":img5Orange,
        "#000000":img5Black,
        "DarkViolet":img5Voilet,
        "#FFBF00":img5Yellow
    },
  
    };
  
    const templates = [
      {title: "Classic", value: "template1" },
      {title: "Modern", value: "template2" },
      {title: "Elegant", value: "template3"},
      {title: "Vibrant", value: "template4"},
      {title: "Retro", value: "template5"},
    ];
  

  return (
    <ActivityWrapper
      heading="Invoice Templates"
      content={
        <OuterContainer>
          <TopContainer>
            <Text fontSize={18} fontWeight={600} content="Design Selection" />
            <ColorOption>
          {" "}
          <Radio.Group
            onChange={(val) => {
              setSelectedColor(val);
              saveValues({ color: val });
            } }
            value={selectedColor}
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              paddingVertical: 6,
            }}
          >
            {colorOptions.map(option => (
                  <Radio
                    icon={tickIcon}
                    className={"colorRadio"}
                    style={{
                      height: 25,
                      width: 25,
                      background: option.color,
                      color: "white",
                      padding: 6,
                      borderRadius: "50%",
                    }}
                    value={option.color}
                    key={option.label}  // Adding a key for React elements in lists
                  />
                ))}
          </Radio.Group>
        </ColorOption>
        
        </TopContainer>
        <DesignContainer>
            <Swiper
            trackOffset={5}
            slideSize={90}
            direction="horizontal" 
            indicator={() => null}
            ref={swiperRef}
            onIndexChange={(index) => setCurrentIndex(index)}
            >
              {templates.map((t) => (
                <Swiper.Item>
                  <Template>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 15,
                        color: "#444",
                        marginBottom: 4,
                      }}
                    >
                      {t.title}
                    </div>
                    <Image
                      style={{ width: 280, border: "1px solid" }}
                      src={imagesMap[t.value][selectedColor]} />
                    {t.value === selectedTemplate ? (
                      <div
                        style={{
                          height: 36,
                          width: "100%",
                          textAlign: "center",
                          marginTop: 8,
                          color: "green",
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      >
                        <CheckCircleOutlined /> Selected
                      </div>
                    ) : (
                      <div
                        style={{
                          height: 36,
                          width: "100%",
                          textAlign: "center",
                          marginTop: 8,
                        }}
                      >
                        <Button
                          onClick={() => {
                            setSelectedTemplate(t.value);
                            saveValues({ template: t.value });
                          } }
                          type={"primary"}
                          style={{ fontSize: 14, borderRadius: 100 }}
                        >
                          Select
                        </Button>
                      </div>
                    )}
                  </Template>
                </Swiper.Item>
              ))}
            </Swiper>
            
            <ButtonContainer>
                <RoundedButton onClick={() => swiperRef.current?.swipePrev()}  disabled={currentIndex === 0} icon={<ArrowLeftOutlined style={{ fontSize: 25 }} />}></RoundedButton>
                <RoundedButton onClick={() => swiperRef.current?.swipeNext()}  disabled={currentIndex === templates.length - 1} icon={<ArrowRightOutlined style={{ fontSize: 25 }}/>}></RoundedButton>
            </ButtonContainer>
      
          </DesignContainer>
                  </OuterContainer>
      }
    />
  );
};

export default InvoiceTemplates;

const OuterContainer = styled.div`
  padding: 20px;
  @media (max-width: 768px) {
    padding: 10px;
  };
`;

const ColorOption = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
  margin-top: 10px;
`;

const DesignContainer = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: center;
  gap: 15px;
  margin-top: 60px;
`;

const Template = styled.div`
  border-radius: 12px;
  padding: ${({ padding }) => (padding ? padding : "10px")};
  display: flex;
 
  align-items: center;
  box-shadow: 0px 0px 12px -3px rgba(97, 94, 97, 1);
  margin: 22px 10px;
  flex-direction: column;
`;

const TopContainer = styled.div`
position: fixed;
`;

const ButtonContainer = styled(Space)`
  margin-top: 2%;
  gap:50px;
`;

const RoundedButton = styled(Button)`
  border-radius: 70%;
  border:1px solid black;
  width:40px;
  height:40px;

`;