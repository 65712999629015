import React, {useEffect, useState} from 'react';
import {message, Upload, Row, Col} from 'antd';
import {loginRequest, saveCompany} from '../../../../services/api';
import _ from "lodash";
import {states} from "../../../../models/constants";
import {useDispatch, useSelector} from "react-redux";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import Resizer from "react-image-file-resizer";
import {Grid, NavBar, Button, Input, Form} from "antd-mobile";
import Activity from "../../../activity";
import {customDispatcher} from "../../../../utils";
import ActivityWrapper from "../../../components/Activity";
import {useLayersFramework} from "../../../v2framework/framework.hooks";
import { MobileButtonV2 } from '../../../components/MobileButtonV2';

const findStateByGstin = (gstin = "") => {
    const inputStateCode = gstin.slice(0, 2);
    const vals = _.values(states["IN"]);
    for (const state of vals) {
        if (state.gstCode === inputStateCode) {
            return state.alphaCode
        }
    }
}

const resizeFile = (file, factor) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            128 * factor,
            128 * factor,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "file"
        );
    });

const CustomUpload = ({value, onChange}) => {
    const [uploading, setUploading] = useState(false);
    return (
        <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={`/api/internal/upload`}
            beforeUpload={async (file) => {
                // await new Promise(resolve => setTimeout(resolve, 5000));
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                    message.error('You can only upload JPG/PNG file!');
                    return false;
                }
                let currentFile = file;
                let factor = 4;
                while (factor > 0 && currentFile.size > 768 * 1024) {
                    console.log(`## Reducing Size with factor ##`, currentFile.size);
                    const newFile = await resizeFile(file, factor);
                    console.log("## Reduced Size ##", newFile.size);
                    currentFile = newFile;
                    factor--;
                }
                setUploading(false);
                return currentFile;
                // return true;
                // return newImg;
                // const isLt2M = file.size / 1024 / 1024 < 1;
                // if (!isLt2M) {
                //     message.error('Image must smaller than 1 MB!');
                // }
                // return isJpgOrPng && isLt2M;
            }}
            onChange={async (info) => {
                if (info.file.status === 'uploading') {
                    setUploading(true);
                    return;
                }
                if (info.file.status === 'done') {
                    const imgFilename = _.get(info, 'file.response.data.filename');
                    onChange(imgFilename);
                }
            }}
        >
            {value ? <img src={`/api/internal/file/${value}`} alt="avatar"
                          style={{width: '100%'}}/> :
                <div>
                    {uploading ? <LoadingOutlined/> : <PlusOutlined/>}
                    <div style={{marginTop: 8}}>Upload Sign</div>
                </div>
            }
        </Upload>
    )
}

const CompanySettings = () => {
    const state = useSelector(state => state);
    const {goBack} = useLayersFramework();
    const dispatch = customDispatcher(useDispatch());
    const [form] = Form.useForm();
    const [gstState, setGstState] = useState();
    if (gstState) {
        form.setFieldsValue({state: gstState});
    }


    useEffect(() => {
        form.setFieldsValue(_.cloneDeep(state?.company || {}));
    }, [state, form]);

    return (
        <ActivityWrapper
            heading={"Company Settings"}
            footer={
                <Grid columns={2} style={{width: '100%', height: '100%', border: '0px solid orange'}}>
                    <Grid.Item>
                        <Button style={{borderRadius: 0, width: '100%', height: '100%'}} color={"danger"}
                                onClick={() => {
                                    goBack();
                                }}
                        >
                            Cancel
                        </Button>
                    </Grid.Item>
                    <Grid.Item>
                        <MobileButtonV2 style={{borderRadius: 0, width: '100%', height: '100%'}} color={"primary"}
                                onClick={async () => {
                                    const values = await form.validateFields();
                                    const obj = values;
                                    const company = _.cloneDeep(state.company || {});
                                    _.assign(company, obj);
                                    await saveCompany(company);
                                    loginRequest("", "", dispatch);
                                    goBack();
                                }}>
                            Update
                        </MobileButtonV2>
                    </Grid.Item>
                </Grid>
            }

        >
            <div>
                <Form
                    form={form}
                >
                    <>

                        <Form.Item name={"name"} label={"Business Name"}
                                   rules={[{required: true, message: "can't be blank"}]}>
                            <Input className={"myTextInput"}/>
                        </Form.Item>
                        <Form.Item name={"address"} label={"Address"}
                                   rules={[{required: true, message: "can't be blank"}]}>
                            <Input className={"myTextInput"}/>
                        </Form.Item>
                        <Form.Item name={"gstin"} label={"GSTIN (Optional)"}>
                            <Input onChange={(e) => setGstState(findStateByGstin(e))} className={"myTextInput"}/>
                        </Form.Item>
                        <Form.Item name={"state"} label={`State ${!!gstState ? "(auto populated using GSTIN)" : ""}`}
                                   rules={[{required: true, message: "can't be blank"}]}>
                            <select
                                disabled={!!gstState}
                                // style={{width: '100%', background: 'white', height: 35}}
                                className={"myDropDownInput"}
                                // style={{border: '1px solid black', borderRadius: 8, paddingLeft: 8, height: 32, width: '100%', background: 'white'}}
                                // filterOption={(input, option) => {
                                //     return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                //         option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                // }}
                                // showSearch
                            >
                                {_.map(states["IN"], state => (<option value={state.alphaCode}>{state.name}</option>))}
                            </select>
                        </Form.Item>
                        <Row>
                            <Col span={12}>
                                <Form.Item name={"imgSign"} label={"Signature"}>
                                    <CustomUpload/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={"imgLogo"} label={"Business Logo"}>
                                    <CustomUpload/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>

                    {
                        state.company?.invoiceConfig?.enableBank &&
                        <>
                            <h4 style={{paddingLeft: 16, marginTop: 8}}>Bank Details (To print on Invoice)</h4>
                            <Form.Item name={"bankName"} label={"Bank Name"}>
                                <Input className={"myTextInput"}/>
                            </Form.Item>
                            <Form.Item name={"bankAccountNum"} label={"Bank Account Number"}>
                                <Input className={"myTextInput"}/>
                            </Form.Item>
                            <Form.Item name={"bankIfsc"} label={"IFSC Code"}>
                                <Input className={"myTextInput"}/>
                            </Form.Item>
                            <Form.Item name={"bankBranch"} label={"Branch Name"}>
                                <Input className={"myTextInput"}/>
                            </Form.Item>

                        </>
                    }

                </Form>

            </div>
        </ActivityWrapper>
    )

}

export default CompanySettings;
