import _ from "lodash";
import { message } from "antd";
import { truncate as lTruncate }from "lodash";

import {units} from "../models/constants";
import {getVoucherPdf, exportedUrls} from "../services/api";
import {EDispatchType} from "../services/store";

export const customDispatcher = (reduxDispatch) => (props) =>
  reduxDispatch({ type: EDispatchType.BASIC, props });

const getNum = (num) => Math.round(100 * num) / 100;

export const formulateItems = (
  items,
  dbItemsMap,
  isIgst,
  isRoundOffEnabled = true
) => {
  const newItems = _.cloneDeep(items) || [];
  let subtotal = 0,
    totalCgst = 0,
    totalIgst = 0,
    totalSum = 0;
  newItems.forEach((item, index) => {
    const dbItem = dbItemsMap[item._id] || {};
    item.name = dbItem.name;
    item.invDescription = (
      <div>
        {dbItem.name}
        <br />
        <span style={{ fontWeight: "normal" }}>{item.description}</span>
      </div>
    );
    item.hsn = dbItem.hsn;
    item.qty = `${item.qty} (${units[dbItem.unit || "NOS"]?.code})`;
    const taxRate = item.taxRate / 100;
    const rate =
      item.priceType === "exc" ? item.price : item.price / (1 + taxRate);
    const taxable = rate * parseInt(item.qty);
    const cgst = isIgst ? 0 : (taxable * taxRate) / 2;
    const igst = isIgst ? taxable * taxRate : 0;
    item.sno = (index + 1).toString();
    item.cgstRate = getNum(item.taxRate / 2);
    item.price = getNum(rate);
    item.taxable = getNum(taxable);
    item.cgst = isIgst ? 0 : getNum(cgst);
    item.sgst = item.cgst;
    item.igst = isIgst ? getNum(igst) : 0;
    item.txtCgst = `${item.cgst.toFixed(2)} (${item.cgstRate}%)`;
    item.txtSgst = item.txtCgst;
    item.txtIgst = `${item.igst.toFixed(2)} (${item.taxRate}%)`;
    const total = item.taxable + (isIgst ? item.igst : 2 * item.cgst);
    item.total = getNum(total);
    subtotal += item.taxable;
    totalCgst += item.cgst;
    totalIgst += item.igst;
    totalSum += item.total;
    item.price = item.price.toFixed(2);
    item.taxable = item.taxable.toFixed(2);
    item.totalTax = (item.cgst + item.cgst + item.igst).toFixed(2);
    // item.cgst = item.cgst.toFixed(2);
    item.total = item.total.toFixed(2);
  });

  const roundedTotal = isRoundOffEnabled ? Math.round(totalSum) : totalSum;
  const roundedOff = roundedTotal - totalSum;

  return {
    tableItems: newItems,
    summary: {
      subtotal: getNum(subtotal).toFixed(2),
      totalCgst: getNum(totalCgst).toFixed(2),
      totalIgst: getNum(totalIgst).toFixed(2),
      totalSum: getNum(totalSum).toFixed(2),
      roundedOff: getNum(roundedOff).toFixed(2),
      roundedTotal: getNum(roundedTotal),
    },
  };
};

export const convertArrayToMap = (arr, key) => {
  const map = {};
  arr.forEach((item) => {
    map[item[key]] = item;
  });
  return map;
};

export const sleep = (duration) =>
  new Promise((resolve) => setTimeout(resolve, duration));

export const beautifyAmount = (amount) => {
  let minusPrefix = "";
  if (typeof amount === "number" && amount < 0) {
    minusPrefix = "-";
    amount = -amount;
  } else if (typeof amount === "string" && amount.charAt(0) === "-") {
    minusPrefix = "-";
    amount = String(amount).slice(1);
  }
  if (typeof amount !== "string") {
    amount = amount.toString();
  }

  let [left, right] = amount.split(".");
  let finalStr = "";
  if (right) {
    finalStr = `.${right}`;
  }

  // manipulate left:
  let partLength = 3;
  while (left.length) {
    const len = partLength;
    let part;
    if (left.length > len) {
      part = left.slice(-len);
      left = left.slice(0, left.length - len);
    } else {
      part = left;
      left = "";
    }
    finalStr = `${part}${partLength < 3 ? "," : ""}${finalStr}`;
    partLength = 2;
  }

  return minusPrefix + finalStr;
};

export const defaultDocumentPrefixes = {
  invoice: "INV-",
  proforma: "PI-",
  receipt: "PAYIN-",
  payment: "PAYOUT-",
  expense: "PAYOUT-",
  creditNote: "CR-",
  debitNote: "DR-",
  purchase: "PINV-",
  delivery: "DC-",
};

export const isProdEnv = () => {
  const hostname = window.location.hostname;
  return hostname.includes("app.khatabuddy") || hostname.includes("m.khatabuddy");
};

export const exportPdf = async (voucherId, fileName) => {
  getVoucherPdf(voucherId)
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
};

export const PublicShareLinks = {
  VOUCHER: "voucher",
  LEDGER: "ledger",
  STORE: "company",
  ECOMMERCE_ORDER: "ecommerce",
};

export const getPublicShareLink = (hashId, _hashId) => {
  const baseUrl = isProdEnv()
    ? `https://khatabuddy.in`
    : `${exportedUrls.baseUrl}/shortUrl`;
  let link = `${baseUrl}/${_hashId || hashId}`;
  return link;
};

export function copyToClipboard(text) {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData("Text", text);
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported("copy")
  ) {
    var textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy"); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      return prompt("Copy to clipboard: Ctrl+C, Enter", text);
    } finally {
      document.body.removeChild(textarea);
    }
  }
}

export const truncate = (input, limit) =>
  lTruncate(input, { length: limit, omission: "..." });

export const logRender = (componentName, ...otherArgs) => {
  console.log(`## Render: ${componentName} ##`, ...otherArgs);
};

export const errorMessage = (msg) => {
  return message.error(msg);
};

export const logMemo = (label, ...otherArgs) => {
  // console.log(`## UseMemo: ${label} ##`, ...otherArgs);
};

export const logEffect = (label, ...otherArgs) => {
  // console.log(`## UseEffect: ${label} ##`, ...otherArgs);
};

export const isBlankString = (str) => !str?.trim?.();