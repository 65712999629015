import styled from "styled-components";

export const InnerContainer = styled.div`
  width: ${({ width }) => width};
  // border-left: ${({ border }) => border && "1px solid grey"};
  // border: 1px solid red;
  // padding-left: ${({ border }) => border && "5px"};
  display: flex;
  flex-direction: column;
  align-items: ${({ notCenter }) => (notCenter ? "" : "center")};
  padding: 4px;
  border-radius: 6px;
  text-align: ${({ alignCenter }) => (alignCenter ? "center" : "")};
`;

export const Container = styled.div`
  height: ${(height) => `${height}px`};
  border-radius: 12px;
  padding: ${({ padding }) => (padding ? padding : "10px")};
  display: flex;
  width: 100%;
  box-shadow: ${({ shadow }) => shadow && "0px 0px 12px -3px rgba(97,94,97,1)"};
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  margin-top: ${({ top }) => top && "10px"};
  gap: ${({ noGap }) => !noGap && "20px"};
  align-items: ${({ start }) => start && "flex-start"};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
