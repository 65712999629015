import React, {useEffect, useState} from "react";
import {Alert} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Button, Grid, NavBar, Form, Input} from "antd-mobile";
import {customDispatcher} from "../utils";
import {loginRequest, saveUser} from "../services/api";
import {getCredentials, setCredentials} from "../services/cookiesPlus";
import ActivityWrapper from "./components/Activity";
import {useLayersFramework} from "./v2framework/framework.hooks";
import { MobileButtonV2 } from "./components/MobileButtonV2";


const ProfileSettings = () => {
    const state = useSelector(state => state);
    const dispatch = customDispatcher(useDispatch());
    const {goBack} = useLayersFramework();
    const [form] = Form.useForm();
    const [res, setRes] = useState();

    useEffect(() => {
        form.setFieldsValue(state?.user);
    }, [state, form]);

    return (
        <ActivityWrapper
            heading={"Edit Profile"}
            footer={
                <Grid columns={2} style={{width: '100%', height: '100%', border: '0px solid orange'}}>
                    <Grid.Item>
                        <Button style={{borderRadius: 0, width: '100%', height: '100%'}} color={"danger"}
                                onClick={() => {
                                    goBack();
                                }}
                                disabled={res?.type === "success"}
                        >
                            Cancel
                        </Button>
                    </Grid.Item>
                    <Grid.Item>
                        <MobileButtonV2 style={{borderRadius: 0, width: '100%', height: '100%'}} color={"primary"}
                                disabled={res?.type === "success"}
                                onClick={async () => {
                                    const values = await form.validateFields();
                                    await saveUser({user: {_id: state.user._id, ...values}});
                                    const currCred = getCredentials();
                                    if (currCred.username.includes("@")) {
                                        currCred.username = values.email;
                                    } else {
                                        currCred.username = values.phone;
                                    }
                                    setCredentials(currCred.username, currCred.password);
                                    await loginRequest(currCred.username, currCred.password, dispatch);
                                    setRes({
                                        type: "success",
                                        msg: "Profile updated successfully"
                                    });
                                }}>
                            Update
                        </MobileButtonV2>
                    </Grid.Item>
                </Grid>
            }
            content={
                <div>
                    <Form form={form}>
                        <Form.Item name={"email"} label={"Email"} rules={[{type: "email", required: true, message: "Invalid Email"}]}>
                            <Input maxLength={50} placeholder={"Email Address"}/>
                        </Form.Item>
                        <Form.Item name={"phone"} label={"Mobile Number"} rules={[{required: true, len: 10, message: "Invalid mobile number"}]}>
                            <Input type={"number"} placeholder={"10 digit Mobile Number"}/>
                        </Form.Item>
                        <Form.Item name={"name"} label={"Full Name"}
                                   rules={[{required: true, message: "can't be blank"}]}>
                            <Input maxLength={40} placeholder={"Full Name"}/>
                        </Form.Item>
                    </Form>

                    <br/><br/>
                    {
                        res &&
                        <div style={{padding: 8}}>
                            <Alert
                                message={res.msg}
                                type={res.type} showIcon={true}/>
                        </div>
                    }
                </div>
            }
        />
    )

};

export default ProfileSettings;
