import React, {useEffect} from "react";
import {Col, Row, Select, Switch, Form} from "antd";
import {loginRequest, saveCompany} from "../../../../services/api";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import Activity from "../../../activity";
import {Grid, NavBar, Button, List, Input, TextArea} from "antd-mobile";
import {customDispatcher} from "../../../../utils";
import ActivityWrapper from "../../../components/Activity";
import {useLayersFramework} from "../../../v2framework/framework.hooks";
import { MobileButtonV2 } from "../../../components/MobileButtonV2";


const CustomFormItem = ({value, onChange, label}) => {
    return (
        <List.Item style={{}} extra={<Switch checked={!!value} onClick={onChange}/>}>{label}</List.Item>
    )
}


const InvoiceSettings = () => {
    const dispatch = customDispatcher(useDispatch());
    const {goBack} = useLayersFramework();
    const [form] = Form.useForm();
    const state = useSelector(state => state);

    useEffect(() => {
        if (state.company) {
            form.setFieldsValue(state.company?.invoiceConfig || {});
        }
    }, [state.company, form]);

    return (
        <ActivityWrapper
            heading={"Invoice Settings"}
            footer={
                <Grid columns={2} style={{width: '100%', height: '100%', border: '0px solid orange'}}>
                    <Grid.Item>
                        <Button style={{borderRadius: 0, width: '100%', height: '100%'}} color={"danger"}
                                onClick={() => {
                                    goBack();
                                }}
                        >
                            Cancel
                        </Button>
                    </Grid.Item>
                    <Grid.Item>
                        <MobileButtonV2 style={{borderRadius: 0, width: '100%', height: '100%'}} color={"primary"}
                                onClick={async () => {
                                    const values = await form.validateFields();
                                    console.log("## ValsForm ##", values);
                                    const obj = values;
                                    // if (obj.enableQR && (!obj.upiId || !obj.upiAmountType)) {
                                    //     message.error("Please input UPI ID & select Amount type to enable UPI QR on invoice");
                                    //     return;
                                    // }
                                    const company = _.cloneDeep(state?.company || {});
                                    if (!company.invoiceConfig) {
                                        company.invoiceConfig = {};
                                    }
                                    _.assign(company.invoiceConfig, obj);
                                    // console.log("## Saving Comp ##", company);
                                    await saveCompany(company);
                                    loginRequest("", "", dispatch);
                                    goBack();
                                    // message.success("Invoice Settings saved successfully!");
                                    // await saveUser({user: {_id: state.user._id, ...values}});
                                    // const currCred = getCredentials();
                                    // if (currCred.username.includes("@")) {
                                    //     currCred.username = values.email;
                                    // } else {
                                    //     currCred.username = values.phone;
                                    // }
                                    // setCredentials(currCred.username, currCred.password);
                                    // await loginRequest(currCred.username, currCred.password, dispatch);
                                }}>
                            Update
                        </MobileButtonV2>
                    </Grid.Item>
                </Grid>
            }
            content={
                <div>
                    <Form form={form}>
                        <List header='List Header'>
                            {/*<List.Item extra={<CustomFormItem name={"enableLogo"}/>}>Show Business Logo</List.Item>*/}
                            <Form.Item style={{padding: 0, margin: 0, borderBottom: 'none'}} name={"enableLogo"}>
                                <CustomFormItem label={"Show Business Logo"}/>
                            </Form.Item>
                            <Form.Item style={{padding: 0, margin: 0}} name={"enableSign"}>
                                <CustomFormItem label={"Show Signature"}/>
                            </Form.Item>
                            <Form.Item style={{padding: 0, margin: 0}} name={"enableBank"}>
                                <CustomFormItem label={"Show Bank Details"}/>
                            </Form.Item>
                            <Form.Item style={{padding: 0, margin: 0}} name={"enableShipping"}>
                                <CustomFormItem label={"Show Shipping Address"}/>
                            </Form.Item>
                            <Form.Item style={{padding: 0, margin: 0}} name={"enableTnc"}>
                                <CustomFormItem label={"Show Terms & Conditions"}/>
                            </Form.Item>
                            <Form.Item style={{padding: 0, margin: 0}} name={"enableQR"}>
                                <CustomFormItem label={"Show UPI QR Code"}/>
                            </Form.Item>
                            {/*<Form.Item style={{padding: 0, margin: 0}} name={"enableLogo"}>*/}
                            {/*    <CustomFormItem label={"Show Business Logo"}/>*/}
                            {/*</Form.Item>*/}


                            <List.Item>
                                <Form.Item name={"upiId"} label={"UPI ID (For QR code generation)"} style={{padding: 0, margin: 0}}>
                                    <Input placeholder="Enter your UPI ID"
                                    className={"myTextInput"}
                                    // style={{ border: "0.5px solid gray" }}
                                    
                                    />
                                </Form.Item>
                            </List.Item>

                            <List.Item>
                                <Form.Item name={"upiAmountType"} label={"Amount (For QR code generation)"}  style={{padding: 0, margin: 0}}>
                                    <Select
                                        placeholder={"Amount selection to generate QR"}
                                        // className={"myDropDownInput"}
                                        // style={{ border: "1px solid black", borderRadius: "10px" }}
                                        options={[{label: "Fixed to Invoice Value", value: "invoice"}, {
                                            label: "Variable (User can pay any amount)",
                                            value: "generic"
                                        }]}
                                    />
                                </Form.Item>
                            </List.Item>
                            <List.Item>
                                <Form.Item name={"tnc"} label={"Terms & Conditions"}>
                                    <TextArea rows={3} 
                                    className="myTeaxtAreaInput"
                                    // style={{border: "1px solid gray", fontSize: '13px'}}
                                    placeholder="Enter terms and conditions"
                                    />
                                </Form.Item>
                            </List.Item>
                        </List>
                    </Form>
                </div>
            }

        />
    )

};

export default InvoiceSettings;
