import {useSelector} from "react-redux";
import {useCustomDispatch} from "./generic";

const defaultDrawerData = {
    visibility: false,
}

const getReduxKeyFromDrawerId = drawerId => `drawer_${drawerId}`;

export function useDrawerData(drawerId) {
    const reduxKey = getReduxKeyFromDrawerId(drawerId);
    const {params, visibility} = useSelector(state => state[reduxKey]) || defaultDrawerData;
    return [params, visibility];
}

export function useDrawerActions() {
    const dispatch = useCustomDispatch();

    const showDrawer = (drawerId, params) => {
        const reduxKey = getReduxKeyFromDrawerId(drawerId);
        dispatch({
            [reduxKey]: {
                visibility: true,
                params,
            }
        });
    };

    const hideDrawer = (drawerId, params) => {
        const reduxKey = getReduxKeyFromDrawerId(drawerId);
        dispatch({
            [reduxKey]: {
                visibility: false,
                params,
            }
        });
    }

    return [showDrawer, hideDrawer];
}