import React, { useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import { Input, List } from "antd";
import { useSelector } from "react-redux";
import { SearchOutline } from "antd-mobile-icons";

import { DrawersWrapper } from "../DrawersWrapper";
import { DRAWERS } from "../registry";
import { useDrawerData } from "../../../hooks/drawer";
import { useDrawerActions } from "../../../hooks/drawer";
import {EmptyComponent} from "../../components/EmptyComponent";

const drawerId = DRAWERS.BOTTOM.ITEMS_LIST;

export const ItemsList = React.memo(({}) => {
  const [params] = useDrawerData(drawerId);
  const [s, hidePopup] = useDrawerActions();
  const state = useSelector((state) => state);
  const { setItemName, setAddItemModal, selectedItemName } = params || {};

  const [searchText, setSearchText] = useState("");
  const [selectedItem, setSelectedItem] = useState(selectedItemName || "");

  const options = _.map(state.items || [], ({ name, _id }) => ({
    label: name,
    value: _id,
  }));

  return (
    <DrawersWrapper id={drawerId}>
      <OuterContainer>
        <PopUpHeader>
          <TopLine />
          Select Item
        </PopUpHeader>
        {
          <>
            <div style={{ padding: 6, background: "#fff" }}>
              <div
                style={{
                  width: "100%",
                  fontSize: 24,
                  verticalAlign: "middle",
                  lineHeight: "30px",
                  borderRadius: 8,
                  background: "#d0caca4a",
                  display: "flex",
                  flexDirection: "row",
                  padding: 4,
                  alignItems: "center",
                }}
              >
                <SearchOutline style={{ width: 30, color: "gray" }} />
                <Input
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Search with Item name"
                  style={{
                    width: "100%",
                    fontSize: 16,
                    paddingLeft: 8,
                    border: "none",
                    background: "#f1efef",
                  }}
                />
              </div>
              <ListContainer>
                <List>
                  {
                    !options?.length ?
                        <EmptyComponent
                            message={"You have not added any item yet."}
                        /> :
                    options
                    .filter((f) =>
                      (f.label || "")
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    )
                    .map(
                      (i) =>
                        i.label && (
                          <Item
                            onClick={() => {
                              setItemName(i.label, i.value);
                              setSelectedItem(i.label);
                              hidePopup(drawerId);
                            }}
                            selected={i.label === selectedItem}
                          >
                            <List.Item
                              arrow="false"
                              style={{ fontWeight: 600, fontSize: 16 }}
                            >
                              {i.label}
                            </List.Item>
                          </Item>
                        )
                    )}
                </List>
              </ListContainer>
            </div>
            <ButtonContainer margin>
              <button
                className={
                  "fab primaryEffect generalRoundedButton buttonProperties"
                }
                onClick={() => {
                  setAddItemModal(true);
                }}
              >
                Add Item
              </button>
            </ButtonContainer>
          </>
        }
      </OuterContainer>
    </DrawersWrapper>
  );
});

const ListContainer = styled.div`
  max-height: 310px;
  margin-top: 10px;
  overflow-y: scroll;
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 70px;
  bottom: -2px;
  position: absolute;
  background: white;
  display: flex;
  justify-content: space-between;
  margin-left: ${({ margin }) => margin && "10px"};
  padding-top: 20px;
`;

const OuterContainer = styled.div``;

const PopUpHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 12px 12px 0;
  font-size: 20px;
  font-weight: bold;
  border-top-right-radius: 10%;
  flex-direction: column;
`;

const TopLine = styled.div`
  height: 2.5px;
  background: lightgray;
  width: 52px;
  border-radius: 2px;
  margin-bottom: 10px;
`;

const Item = styled.div`
  background: ${({ selected }) => selected && "#d0caca4a"};
  padding-left: 10px;
  border-bottom: 1px solid #d0caca4a;
`;
