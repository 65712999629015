import { createStore } from 'redux';

export const EDispatchType = {
    BASIC: "BASIC",
    IN_APP_LAYER: "IN_APP_LAYER",
}

export const reducerHandlers = {
    [EDispatchType.BASIC]: (state, props) => ({
        ...state,
        ...props,
    }),
    [EDispatchType.IN_APP_LAYER]: (state, props) => {
        const {action, value, args} = props;
        const currentInAppLayers = state.currentInAppLayers || [];
        const newInAppLayers = [...currentInAppLayers];
        if (action === "pop") {
            newInAppLayers.pop();
        }
        if (action === "push") {
            newInAppLayers.push(value);
        }
        const layerArgs = state.layerArgs || {};
        Object.assign(layerArgs, args || {});
        return {
            ...state,
            layerArgs,
            currentInAppLayers: newInAppLayers,
        }
    }
}

function reducer(state = {}, {type, props}) {
    const reducerHandler = reducerHandlers[type];
    if (!reducerHandler) {
        // console.log(`## Invalid Dispatch Type ##`, type);
        return state;
    }
    console.log("## Changing State ##");
    return reducerHandler(state || {}, props || {});
}

const store = createStore(reducer, {})

export default store;