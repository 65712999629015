import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Activity from "./activity";
import {Button,NavBar, Input, Image} from "antd-mobile";
import {customDispatcher} from "../utils";
import {loginRequest, saveCompany} from "../services/api";
import {getCredentials} from "../services/cookiesPlus";
import imgEnterprise from "../res/img/enterprise.png";
import ActivityWrapper from "./components/Activity";
import {useNavigate} from "react-router-dom";
import { MobileButtonV2 } from "./components/MobileButtonV2";


const LastStep = () => {
    const state = useSelector(state => state);
    const dispatch = customDispatcher(useDispatch());
    const navigate = useNavigate();
    const [companyName, setCompanyName] = useState("");
    const [res, setRes] = useState({});

    return (
        <ActivityWrapper
            noHeading={true}
            footer={
                <MobileButtonV2 style={{borderRadius: 0, width: '100%', height: '100%'}} color={"primary"}
                        disabled={res?.type === "success"}
                        onClick={async () => {
                            console.log("## Clicked ##");
                            const trimmedCompanyName = companyName?.trim?.();
                            if (!trimmedCompanyName) {
                                setRes({
                                    type: "error",
                                    msg: "can't be blank"
                                });
                                return;
                            } else {
                                console.log("## Else ##");
                                setRes({type: "success"});
                            }
                            await saveCompany({_id: state?.company?._id, name: trimmedCompanyName});
                            const currCred = getCredentials();
                            // if (currCred.username.includes("@")) {
                            //     currCred.username = values.email;
                            // } else {
                            //     currCred.username = values.phone;
                            // }
                            // setCredentials(currCred.username, currCred.password);
                            await loginRequest(currCred.username, currCred.password, dispatch);
                            navigate("/dashboard");
                            // setRes({
                            //     type: "success",
                            //     msg: "Profile updated successfully"
                            // });
                        }}>
                    Start Invoicing
                </MobileButtonV2>
            }
            content={
                <div>
                    <div style={{textAlign: 'center'}}>
                        <div style={{textAlign: 'center'}}>
                            <br/>
                            <Image src={imgEnterprise} style={{height: 130}}/>
                            <br/>
                            <h1><strong>
                                Last step before we start invoicing !
                            </strong></h1>
                            {/*<br/>*/}
                            <p style={{color: 'grey'}}>
                                Please let us know your business name to start creating beautiful invoices
                            </p>
                        </div>

                        <div style={{padding: 16}}>
                            <Input
                                onChange={(val) => setCompanyName(val)}
                                placeholder={"Business Name"}
                                className='myTextInput'
                                // style={{
                                //     border: '1px solid black',
                                //     borderRadius: 1000,
                                //     height: 42,
                                //     '--font-size': '24px',
                                //     '--text-align': 'center'
                                // }}
                            />
                        </div>

                    </div>
                    {
                        res.type==="error" && !companyName?.trim?.() &&
                        <div style={{color: 'red', textAlign: 'center'}}>
                            {res.msg}
                            {/*<Alert*/}
                            {/*    message={res.msg}*/}
                            {/*    type={res.type} showIcon={true}/>*/}
                        </div>
                    }
                </div>
            }
        />
    )

};

export default LastStep;
