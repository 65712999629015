import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Swiper } from "antd-mobile";
import { Button } from "antd";

import Activity from "../../../components/Activity";
import Text from "../../../components/Text";
import StackActivity from "../../../components/StackActivity";
import { getPlans } from "../../../../services/api";

const planDurationsMap = {
  "1yr": "1 Year",
  "3yr": "3 Years"
};

const planNamesMap = {
  "free": "Free Plan",
  "lite": "Lite",
  "premium": "Premium"
};

const UpgradePlanModal = ({ onCancel }) => {
  const state = useSelector(state => state);
  let currentPlan = state?.company ? `${planNamesMap[state?.company?.subscription?.plan || "free"]}` : "--";

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [planType, setPlanType] = useState(currentPlan);
  const [selectedDuration, setSelectedDuration] = useState("1yr");
  const [plans, setPlans] = useState({
    "lite": {
        "1yr": "--" ,
        "3yr": "--"
    },
    "premium": {
        "1yr": "--",
        "3yr": "--"
    }
});

useEffect(() => {
  if (state && state.company) {
      getPlans().then(resp => setPlans(resp.data.data.plans));
  }
}, [state]);

  const FreePlanData = (price) => {
    return (
      <>
        <Text fontSize={20} fontWeight={700} content="₹0 for forever" />
        <Content>
          Up to <strong>15</strong> documents /month
        </Content>
        <Content>
          <strong>Single</strong> users
        </Content>
        <Content>
          <strong>KhataBuddy branding</strong> on documents
        </Content>
        <Content>Tax Invoice</Content>
        <Content>Proforma Invoice</Content>
        <Content>
          <strong>Multiple</strong> Themes
        </Content>
        <Content>
          <strong>Email/ Whatsapp</strong> Document
        </Content>
        <Content>
          <strong>Basic</strong> GSTR1 & GSTR3b Reports
        </Content>
        <Content>
          <strong>Limited</strong> Support
        </Content>
      </>
    );
  };

  const LitePlanData = (price) => {
    return (
      <>
        <Text fontSize={20} fontWeight={700} content={`₹${plans["lite"][selectedDuration]} for ${planDurationsMap[selectedDuration]}`} />
        <Content>
          Up to <strong>5,000</strong> documents /year
        </Content>
        <Content>
          <strong>Unlimited</strong> users
        </Content>
        <Content>
          <strong>No branding</strong> on documents
        </Content>
        <Content>Tax Invoice</Content>
        <Content>Proforma Invoice</Content>
        <Content>
          <strong>Multiple</strong> Themes
        </Content>
        <Content>
          <strong>Email/ Whatsapp</strong> Document
        </Content>
        <Content>
          <strong>Detailed</strong> GSTR1 & GSTR3b Reports
        </Content>
        <Content>
          <strong>Priority</strong> Support
        </Content>
      </>
    );
  };

  const PremiumPlanData = (price) => {
    return (
      <>
        <Text fontSize={20} fontWeight={700} content={`₹${plans["premium"][selectedDuration]} for ${planDurationsMap[selectedDuration]}`} />
        <Content>
          Up to <strong>25,000</strong> documents /year
        </Content>
        <Content>
          <strong>Unlimited</strong> users
        </Content>
        <Content>
          <strong>No branding</strong> on documents
        </Content>
        <Content>Tax Invoice</Content>
        <Content>Proforma Invoice</Content>
        <Content>
          <strong>Multiple</strong> Themes
        </Content>
        <Content>
          <strong>Email/ Whatsapp</strong> Document
        </Content>
        <Content>
          <strong>Detailed</strong> GSTR1 & GSTR3b Reports
        </Content>
        <Content>
          <strong>Priority</strong> Support
        </Content>
        <Content>Payment Receipts</Content>
        <Content>Purchase Invoices</Content>
        <Content>Balance Sheet</Content>
        <Content>Inventory Management</Content>
        <Content>Export JSON for GST Portal</Content>
        <Content>Integrate Payment gateway for payments collection</Content>
        <Content>Schedule Payment reminders for clients</Content>
        <Content>GSTIN validation</Content>
        <Content>GSTR2A Mismatch report</Content>
        <Content>E-invoice</Content>
        <Content>Automated GST Filing</Content>
      </>
    );
  };

  const PlanCard = (header, type) => {
    return (
      <PlanContainer>
        <CardTopContainer
          color={
            type === "Free" ? "green" : type === "Lite" ? "#0080ff" : "#cd2424"
          }
        >
          {header}
        </CardTopContainer>
        <InnerContainer>
          {type === "Free"
            ? FreePlanData("₹0 Forever")
            : type === "Lite"
            ? LitePlanData()
            : PremiumPlanData()
            }
        </InnerContainer>
        {type !== "Free" && (
          <BottomContainer>
            <Button
              style={{
                borderRadius: "10px",
                background: type === "Lite" ? "#0080ff" : "#cd2424",
                color: "white",
                height: "40px",
                fontSize: "20px",
                fontWeight: "600",
              }}
              onClick={() => {
                setPlanType(type);
                setShowPaymentModal(true);
              }}
            >
              Buy Now
            </Button>
          </BottomContainer>
        )}
      </PlanContainer>
    );
  };
  return (
    <Activity
      heading={"Upgrade Plans"}
      onBack={() => {
        onCancel();
      }}
      content={
        <>
          <TopContainer>
            <StyledButton
              selected={selectedDuration === "1yr"}
              onClick={() => setSelectedDuration("1yr")}
            >
              1 Year Plan
            </StyledButton>
            <StyledButton
              selected={selectedDuration === "3yr"}
              onClick={() => setSelectedDuration("3yr")}
            >
              3 Years Plan
            </StyledButton>
          </TopContainer>
          <div
            style={{ padding: "10px 25px", background: "#fff", height: "88%" }}
          >
            <Swiper
              direction="horizontal"
              indicator={() => null}
              style={{
                height: "100%",
              }}
            >
              <Swiper.Item
                style={{ border: "2px solid green", borderRadius: "20px" }}
              >
                {PlanCard("Free Forever", "Free")}
              </Swiper.Item>
              <Swiper.Item
                style={{ border: "2px solid #0080ff", borderRadius: "20px" }}
              >
                {PlanCard("Lite (App Only)", "Lite")}
              </Swiper.Item>
              <Swiper.Item
                style={{ border: "2px solid #cd2424", borderRadius: "20px" }}
              >
                {PlanCard("Premium (Web+App)", "Premium")}
              </Swiper.Item>
            </Swiper>
            {showPaymentModal && (
              <StackActivity
                visible={showPaymentModal}
                setVisible={setShowPaymentModal}
                type="payment"
                data={{ planType, selectedDuration }}
              />
            )}
          </div>
        </>
      }
    />
  );
};

export default UpgradePlanModal;

const TopContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Content = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const PlanContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledButton = styled(Button)`
  width: 45%;
  color: ${({ selected }) =>
    selected ? "white !important" : "black !important"};
  background: ${({ selected }) =>
    selected ? "#0080ff !important" : "white !important"};
  height: 40px;
  font-weight: 600;
  border-radius: 5px;
  font-size: 20px;
  :hover {
    color: ${({ selected }) => !selected && "#0080ff !important"};
  }
`;

const CardTopContainer = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: white;
  background: ${({ color }) => color};
  width: 101%;
  height: 50px;
  margin-left: -2px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerContainer = styled.div`
  padding: 7% 4%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: scroll;
  height: 82%;
  align-items: center;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`;
