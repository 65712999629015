import React from "react";
import {logRender} from "../../../utils";
import {CalendarOutlined} from "@ant-design/icons";
import {getDateFromNativePicker} from "../../../utils/webViewUtilsV2/interfaces";

export const DateUI = React.memo(({ value, onChange }) => {
    logRender("DateUI");
    return (
        <div
            style={{
                padding: "6px 9px",
                border: "1px solid #8080804a",
                borderRadius: 10,
                height: 40,
                fontSize: 16,
                verticalAlign: "middle",
                textAlign: "center",
                fontWeight: 600,
                background: "white",
                width: ""
            }}
            onClick={async () => {
                const newVal = await getDateFromNativePicker();
                onChange(newVal);
            }}
        >
            <CalendarOutlined style={{ fontSize: "20px"}} />
            &nbsp;
            {value?.format("DD MMM YYYY")}
        </div>
    );
});