export const voucherTypes = {
    "invoice": {
        "name": "Tax Invoice",
        "plural": "Sale Invoices",
        filePrefix: "Invoice",
        idTitle: "Invoice #",
        idType: "mixed",
        haveItems: true,
        label: "Sale Invoice",
        buttons: ["settings", "create", "receipt"],
        datePrefix: "Invoice ",
        publicLinkName:"Invoice"
    },
    "proforma": {
        "name": "Proforma Invoice",
        "plural": "Proforma Invoices",
        filePrefix: "Proforma",
        idTitle: "Proforma #",
        idType: "mixed",
        haveItems: true,
        buttons: ["settings", "create"],
        datePrefix: "Proforma ",
        publicLinkName:"Proforma Invoice"
    },
    delivery: {
        "name": "Delivery Challan",
        "plural": "Delivery Challans",
        filePrefix: "Challan",
        idTitle: "Challan #",
        idType: "mixed",
        haveItems: true,
        label: "Delivery Challan",
        buttons: ["settings", "create"],
        datePrefix: "Challan ",

    },
    creditNote: {
        "name": "Credit Note",
        "plural": "Credit Notes",
        filePrefix: "Credit Note",
        idTitle: "Credit Note #",
        idType: "mixed",
        haveItems: true,
        label: "Credit Note",
        buttons: ["settings", "create"],
    },
    debitNote: {
        "name": "Debit Note",
        "plural": "Debit Notes",
        filePrefix: "Debit Note",
        idTitle: "Debit Note #",
        idType: "text",
        haveItems: true,
        label: "Debit Note",
        buttons: ["settings", "create"],
    },
    "purchase": {
        "name": "Purchase Bill",
        "plural": "Purchase Bills",
        filePrefix: "Purchase Invoice",
        idTitle: "Invoice No.",
        idType: "text",
        haveItems: true,
        buttons: ["settings", "create"],
        datePrefix: " ",
    },
    "receipt": {
        "name": "Payment Receipt",
        "plural": "Payment Receipts",
        filePrefix: "Receipt",
        idTitle: "Voucher ID",
        idType: "number",
        haveItems: false,
        buttons: ["settings", "create"],
        publicLinkName:"Payment Receipt",
        datePrefix:"Receipt"
    },
    "payment": {
        "name": "Payment Made",
        "plural": "Payments Made",
        filePrefix: "Payment",
        idTitle: "Voucher ID",
        idType: "number",
        haveItems: false,
        buttons: ["settings", "create"]
    },
    "expense": {
        "name": "Expense",
        "plural": "Expenses",
        filePrefix: "expense",
        idTitle: "Voucher ID",
        idType: "number",
        haveItems: false,
        buttons: ["settings", "create"]
    }
}

export const nonPreviewVoucherTypes = ["purchase", "payment"];