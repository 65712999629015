import Text from "../../../components/Text";
import {RUPEE} from "../../../../models/constants";
import {beautifyAmount, logRender} from "../../../../utils";
import React, {useEffect, useState} from "react";
import {InnerContainer} from "./styled";
import {getSummary} from "../../../../services/api";
import {emitAppLoadedSignal, sendDataToApp} from "../../../../utils/webviewUtils";
import {message} from "antd";
import {useSelector} from "react-redux";

export const SummaryBlock = React.memo(() => {
    logRender("SummaryBlock");
    const company = useSelector(state => state.company);
    const [summary, setSummary] = useState({});

    useEffect(() => {
        const fetchRecords = async () => {
            const {
                data: { data: _summary },
            } = await getSummary();
            setSummary(_summary);
            // navigate("/laststep"); // ToDo infinity
        };

        fetchRecords().then(() => {
            const currentAppUrl = window.location.origin;
            if (!currentAppUrl.includes("m.khatabuddy") && !window.customUrlShown) {
                message.info(`Custom Base URL: ${currentAppUrl}`);
                window.customUrlShown = true;
            }
        });
    }, [company]);


    return <>
        <InnerContainer
            width="50%"
            notCenter
            style={{ padding: 4, paddingLeft: 16 }}
        >
            <Text
                fontSize={16}
                fontWeight={500}
                color="grey"
                content="Sales"
            />
            <Text
                fontSize={18}
                fontWeight={500}
                // color={"green"}
                content={RUPEE + " " + beautifyAmount(summary?.newNumbers?.sales || 0)}
            />
        </InnerContainer>
        <InnerContainer
            border
            width="50%"
            notCenter
            style={{ padding: 4, paddingLeft: 16 }}
        >
            <Text
                fontSize={16}
                fontWeight={500}
                color="grey"
                content="Purchases"
            />
            <Text
                fontSize={18}
                fontWeight={500}
                // color={"red"}
                content={RUPEE + " " + beautifyAmount(summary?.newNumbers?.receipt || 0)}
            />
        </InnerContainer>
    </>
})