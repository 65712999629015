import React from "react";
import {SplashScreen} from "../activities/splash";
import {logRender} from "../../utils";

export const MainLayer = React.memo(() => {
    logRender("MainLayer");
    return (
        <div>
            <SplashScreen/>
        </div>
    )
})