import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Activity from "../../../components/Activity";
import { Col, Form, Row, Button, message } from "antd";
import { Checkbox, Tabs } from "antd-mobile";
import { states } from "../../../../models/constants";
import _ from "lodash";
import BottomSelect from "../../../components/BottomSelect";
import { saveLedger, loginRequest, gstinValidation } from "../../../../services/api";
import Text from "../../../components/Text";
import { customDispatcher } from "../../../../utils";
import { CustomInput } from "../../../../styles/js/styleConstants";
import {StoreDataTypes, useStoreDataLoader} from "../../../../hooks/useStoreDataLoader.hook";
import { ButtonV2 } from "../../../components/ButtonV2";
import {useLayersFramework} from "../../../v2framework/framework.hooks";

const findStateByGstin = (gstin = "") => {
  const inputStateCode = gstin.slice(0, 2);
  const vals = _.values(states["IN"]);
  for (const state of vals) {
    if (state.gstCode === inputStateCode) {
      return state.alphaCode;
    }
  }
};

const MandatoryStar = () => {
  return <span style={{ color: "red" }}>*</span>;
};

const LedgerSave = ({}) => {
  const {args: layerArgs, goBack} = useLayersFramework();
  const ledgerType = layerArgs.ledgerSaveTargetVendorType;
  const onSave = layerArgs.ledgerSaveOnSave;
  const selectedRecord = layerArgs.ledgerSaveSelectedRecord;
  const onCancel = goBack;

  const [clientForm] = Form.useForm();
  const dispatch = customDispatcher(useDispatch());
  const [syncData] = useStoreDataLoader();

  const [enableShipping, setEnableShipping] = useState(false);
  const [gstState, setGstState] = useState();
  const [shipGstState, setShipGstState] = useState();
  if (gstState) {
    clientForm.setFieldsValue({ state: gstState });
  }
  if (shipGstState) {
    clientForm.setFieldsValue({ shippingState: shipGstState });
  }

  const stateOptions = Object.values(states["IN"]).map(
    ({ name, alphaCode }) => ({
      label: name,
      value: alphaCode,
    })
  );

  useEffect(() => {
    clientForm.resetFields();
    if (selectedRecord) {
      clientForm.setFieldsValue({
        state: selectedRecord.state || "DL",
        shippingState: selectedRecord.shippingState || "DL",
      });
    } else {
      clientForm.setFieldsValue({
        state: "DL",
        shippingState: "DL",
      });
    }
  }, [selectedRecord, clientForm]);

  const isDebtor = ledgerType === "debtor" || !ledgerType;
  const partyTitle = isDebtor ? "Customer" : "Vendor";

  return (
    <Activity
      heading={selectedRecord ? `Update ${partyTitle} Details` : `Add New ${partyTitle}`}
      onBack={() => {
        onCancel();
      }}
      content={
        <>
          <OuterContainer>
            <Form
              form={clientForm}
              style={{ fontSize: 16 }}
              initialValues={selectedRecord}
            >
              <MandatoryStar />
              {partyTitle} Name
              <Form.Item
                name={"name"}
                rules={[{ required: true, message: "Please write the name" }]}
                style={{ marginBottom: 8 }}
              >
                <CustomInput />
              </Form.Item>
              <Row>
                <Col span={12}>Phone</Col>
                <Col span={12}>PAN no.</Col>
              </Row>
              <Row>
                <Col span={12} style={{ paddingRight: 2 }}>
                  <Form.Item
                    name={"phone"}
                    style={{ marginBottom: 8 }}
                    rules={[{ len: 10, message: "Invalid phone" }]}
                  >
                    <CustomInput
                      type={"number"}
                      max={9999999999}
                      min={1000000000}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ paddingLeft: 2 }}>
                  <Form.Item
                    name={"pan"}
                    style={{ marginBottom: 8 }}
                    rules={[{ len: 10, message: "Invalid PAN" }]}
                  >
                    <CustomInput maxLength={10} />
                  </Form.Item>
                </Col>
              </Row>
              Email
              <Form.Item
                name={"email"}
                style={{ marginBottom: 8 }}
                rules={[{ type: "email", message: "Invalid email" }]}
              >
                <CustomInput />
              </Form.Item>
              <Tabs>
                <Tabs.Tab
                  title={
                    <div style={{ fontSize: 16, fontWeight: "bold" }}>
                      Billing Info
                    </div>
                  }
                  key="billing"
                  style={{ fontSize: 10 }}
                >
                  Billing Address
                  <Form.Item name={"address"} style={{ marginBottom: 8 }}>
                    <CustomInput />
                  </Form.Item>
                  <Row align="middle">
                  <Col flex={18} style={{border: "0px solid black"}}>
                  GSTIN
                  <Form.Item name={"gstin"} style={{ marginBottom: 8 }}>
                    <CustomInput
                      placeholder={"GSTIN (applicable if registered dealer)"}
                      maxLength={15}
                      onChange={(e) =>
                        setGstState(findStateByGstin(e.target.value))
                      }
                    />
                  </Form.Item>
                  </Col>
                  <Col flex={2} style={{border: "0px solid black"}}>
                  <Form.Item name={"verify"} label = {"                "} style={{ marginTop: '5%', borderRadius: 20}}>
                        <ButtonV2 type="primary" onClick={async()=>{
                          const gstinValue = clientForm.getFieldValue("gstin");
                          console.log(gstinValue)
                          const resp = await gstinValidation(gstinValue);
                          if(!resp.data.success){
                            message.error('Invalid GSTIN');
                            return
                          }
                          message.success('GSTIN verified successfully');
                          clientForm.setFieldsValue({
                              address: resp?.data?.data?.address,
                              name: resp?.data?.data?.name,
                              pan: resp?.data?.data?.pan,
                            gstin: resp?.data?.data?.gstin || gstinValue,
                          });
                      }} style={{width:"100%", height: 40, fontSize: 18, borderRadius:10}} >Verify</ButtonV2>
                      </Form.Item>
                  </Col>
                  </Row>
                  State{gstState && " (Auto-populated from GSTIN)"}
                  <Form.Item name={"state"} style={{ marginBottom: 8 }}>
                    <BottomSelect
                      disabled={!!gstState}
                      header="State"
                      data={stateOptions}
                      searchPlaceholder="Search with State Name"
                    />
                  </Form.Item>
                </Tabs.Tab>
                <Tabs.Tab
                  title={
                    <div style={{ fontSize: 16, fontWeight: "bold" }}>
                      Shipping Info
                    </div>
                  }
                  key="shipping"
                >
                  <Checkbox
                    style={{
                      "--icon-size": "18px",
                      "--font-size": "13px",
                      "--gap": "6px",
                    }}
                    checked={!enableShipping}
                    onChange={() => setEnableShipping(!enableShipping)}
                  >
                    <Text fontSize={16} content="Same as Billing address" />
                  </Checkbox>
                  <br />
                  {enableShipping && (
                    <>
                      Shipping Address
                      <Form.Item
                        name={"shippingAddress"}
                        style={{ marginBottom: 8 }}
                      >
                        <CustomInput />
                      </Form.Item>
                      GSTIN
                      <Form.Item
                        name={"shippingGstin"}
                        style={{ marginBottom: 8 }}
                      >
                        <CustomInput
                          onChange={(e) =>
                            setShipGstState(findStateByGstin(e.target.value))
                          }
                        />
                      </Form.Item>
                      State{shipGstState && " (Auto-populated from GSTIN)"}
                      <Form.Item
                        name={"shippingState"}
                        style={{ marginBottom: 8 }}
                      >
                        <BottomSelect
                          disabled={!!shipGstState}
                          header="State"
                          data={stateOptions}
                          searchPlaceholder="Search with State Name"
                        />
                      </Form.Item>
                    </>
                  )}
                </Tabs.Tab>
              </Tabs>
            </Form>
          </OuterContainer>
          <ButtonContainer>
            <ButtonV2
              style={{
                width: "100%",
                height: "50px",
                fontSize: "22px",
                background: "#0080FF",
              }}
              type={"primary"}
              className={"customBorder"}
              onClick={async () => {
                clientForm
                  .validateFields()
                  .then(async (values) => {
                    console.log("## Vals #sss#", values);
                    if (!values.state) {
                      values.state = "DL";
                      values.gstin = "07";
                    }
                    values.enableShipping = enableShipping;
                    if (enableShipping && !values.shippingState) {
                      values.shippingState = "DL";
                      values.shippingGstin = "07";
                    }
                    if (selectedRecord) {
                      values._id = selectedRecord._id;
                    }
                    values.type = ledgerType;
                    try {
                      const data = await saveLedger(values);
                      clientForm.resetFields();
                      onSave(data?.data?.data?.ledger?._id);
                      // await loginRequest("", "", dispatch);
                      await syncData(StoreDataTypes.LEDGERS);
                      onCancel();
                    } catch (err) {
                      console.log("Failed to save offer", err);
                    }
                  })
                  .catch((info) => {
                    console.log("## Validate Failed ##", info);
                  });
              }}
            >
              Save
            </ButtonV2>
          </ButtonContainer>
        </>
      }
    />
  );
};

export default LedgerSave;

const OuterContainer = styled.div`
  padding: 5% 5% 15%;
`;

const ButtonContainer = styled.div`
  width: 100%;
  bottom: -2px;
  position: absolute;
  z-index: 1;
`;
