import styled from "styled-components";
import { Input } from "antd";

export const STYLE_CONSTANTS = {
    primaryColor: "#0080FF",
    primaryDarkColor: "#1457C6",
    greenColor: "green",
    greenDarkColor: "darkgreen",
    defaultBackgroundColor: "#fff",
};

export const CustomInput = styled(Input)`
  font-size: 18px;
  letter-spacing: 0.28px;
  line-height: 17px;
  width: 100%;
  height: 40px;
  border-radius: 10px;
`;