import React from "react";
import {logRender} from "../../../utils";
import {Input, InputNumber} from "antd";
import styled from "styled-components";

export const DocumentNumber = React.memo(({ value, onChange }) => {
    logRender("DocumentNumber");
    const { prefix, num } = value || {};
    console.log("## docValue ##", value);
    return (
        <Input.Group compact>
            <Input
                value={prefix}
                onChange={(e) => onChange({ prefix: e.target.value, num })}
                style={{ width: "45%", borderTopLeftRadius: 12, borderBottomLeftRadius: 12, height: 40, fontWeight: 600 }}
                placeholder={"Prefix"}
            />
            <Number
                min={1}
                value={num}
                type="number"
                onChange={(val) => onChange({ prefix, num: val })}
                placeholder={"Number"}
            />
        </Input.Group>
    );
});

const Number = styled(InputNumber)`
width: 45%;
border-top-right-radius: 12px !important;
border-bottom-right-radius: 12px !important;
height: 40px;
padding-top: 4px;

.ant-input-number-input {
    font-weight: 600;
}
`;
