import React, {useEffect, useState} from "react";
import {Avatar} from "antd";
import {
    DollarOutlined,
    FileOutlined,
    LockOutlined, LogoutOutlined,
    UserOutlined
} from "@ant-design/icons";
import {Button, Image, List} from "antd-mobile";
import {setCredentials} from "../../../../services/cookiesPlus";
import {useSelector} from "react-redux";
import {QuestionCircleOutline, RightOutline} from "antd-mobile-icons";
import imgMade from "../../../../res/img/madeInIndia.png";
import {useNavigate} from "react-router-dom";


const SettingsTab = () => {
    const state = useSelector(state => state);
    const navigate = useNavigate();
    const fullName = state?.user?.name || "";
    const [src, setSrc] = useState(state.company?.imgLogo);
    const fallbackImg = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSi33sxKaUgj-fMZEkgeXQ3V6kYcZPZCgU2q_xhY3GwgoiV1wNq01NkzUI_6TX6spmxzaw&usqp=CAU";

    useEffect(() => {
        setSrc(state.company?.imgLogo);
    }, [state]);

    const list = [
        {
            icon: <Image
                src={src}
                style={{borderRadius: 20}}
                fit='cover'
                width={72}
                height={72}
                onError={(e) => {
                    setSrc(fallbackImg);
                    return false;
                }}
            />,
            label: state.company?.name,
            link: "/settings/company",
            description: state.company?.addressa || "Company Settings"
        },
        {icon: <FileOutlined/>, label: "Invoice Settings", link: "/settings/invoice"},
        {icon: <DollarOutlined/>, label: "Payment Gateway", link: "/settings/payment"},
        // {icon: <SoundOutline/>, label: "Refer & Earn", link: "", img: ""},
        {icon: <LockOutlined />, label: "Change Password", link: "/settings/password"},
        {icon: <QuestionCircleOutline />, label: "Contact Support", link: "/settings/help"},
        {icon: <LogoutOutlined/>, label: "Logout", link: "", img: "", onClick: () => {
                setCredentials("", "");
                navigate("/login");
            }}
    ]

    return (
        <div>
            <div style={{textAlign: 'center', marginTop: 16}}>
                <Avatar style={{backgroundColor: 'orange', verticalAlign: 'middle'}} size={60} gap={0}>
                    {fullName ? <div style={{fontSize: 35}}>{fullName.substring(0, 1)}</div> : <UserOutlined/>}
                </Avatar>
                <div style={{fontSize: 24, fontWeight: 'bold'}}>{fullName}</div>
                <h4 style={{color: 'grey'}}>{state.user?.email || state.user?.phone}</h4>
                <Button color={"primary"} style={{borderRadius: 100}} onClick={() => {
                    navigate("/settings/profile");
                }}>Edit Profile <RightOutline/></Button>
            </div>

            <List style={{marginTop: 24}}>
                {list.map(({icon, label, link, img, description, onClick}) => (
                    <List.Item
                        prefix={icon}
                        description={description}
                        onClick={() => {
                            if (onClick) {
                                onClick();
                            } else if (link) {
                                navigate(link);
                            }
                        }}
                        clickable
                    >
                        {label}
                    </List.Item>
                ))}
            </List>
            <br/><br/>
            <center>
                <Image src={imgMade} height={60}/>
            </center>
        </div>
    )

};

export default React.memo(SettingsTab);
