import {EStorageKeys, iStorage} from "./storage";

export const getCredentials = () => {
    const creds = iStorage.get(EStorageKeys.credentials);
    console.log(`CredsFromMemory`, JSON.stringify(creds));
    return creds || {};
}

export const setCredentials = (username, password) => {
    console.log(`Saving creds To Memory`, JSON.stringify({username, password}));
    iStorage.set(EStorageKeys.credentials, {
        username,
        password,
    });
}

