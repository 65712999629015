import {useCallback, useState} from "react";
// import {Button} from "antd";
import { Button } from "antd-mobile";

// Button with in-built loading (progress) effect:
export const MobileButtonV2 = ({onClick, ...props}) => {
    const [loading, setLoading] = useState(false);
    const modifiedOnClickHandler = useCallback(async (...args) => {
        if(!onClick){
            return
        }
        const returnedValue = onClick(...args);
        if (!(returnedValue instanceof Promise)) {
            return returnedValue;
        }
        try {
            setLoading(true);
            const settledValue = await returnedValue;
            return settledValue;
        } catch(err) {
            throw err;
        } finally {
            setLoading(false);
        }
    }, [onClick, setLoading]);

    return <Button loading={loading} onClick={modifiedOnClickHandler} {...props} />
}