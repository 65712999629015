/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";
import { Input, Form, Switch, List } from "antd";
import { TextArea, Stepper } from "antd-mobile";
import { useSelector } from "react-redux";
import { DownOutlined, EditOutlined } from "@ant-design/icons";
import { SearchOutline } from "antd-mobile-icons";

import Text from "../../../components/Text";
import ActivityWrapper from "../../../components/Activity";
import StackActivity from "../../../components/StackActivity";
import BottomPopup from "../../../components/BottomPopup";
import { RUPEE } from "../../../../models/constants";
import { CustomInput } from "../../../../styles/js/styleConstants";
import {useDrawerActions} from "../../../../hooks/drawer";
import {DRAWERS} from "../../../drawers/registry";
import {logRender} from "../../../../utils";
import {Z_INDEXES} from "../../../../styles/js/z_indexes";
import {ELayerTrigger, ELayerType, useLayersFramework} from "../../../v2framework/framework.hooks";
import {SelectItemInput} from "./selectItem.input";
import {useLazyStoreData} from "../../../../hooks/useLazyStoreData.hook";
import {StoreDataTypes} from "../../../../hooks/useStoreDataLoader.hook";
import {EnumLayer} from "../../../v2framework/layers.list";

const CustomFormItem = ({ value, onChange, valuesmap, reversemap }) => {
  return (
    <List.Item
      style={{}}
      extra={
        <Switch
          checkedChildren={"With Tax"}
          unCheckedChildren={"Without Tax"}
          style={{ background: value === "inc" ? "green" : "red" }}
          defaultChecked={reversemap[value]}
          checked={reversemap[value]}
          onChange={(newVal) => onChange(valuesmap[newVal])}
        />
      }
    ></List.Item>
  );
};

const AddItemLineActivity = ({}) => {
  logRender("AddItemLineActivity");
  const {args: layerArgs, pushInAppLayer} = useLayersFramework();
  const itemData = layerArgs.addItemLineArgs?.data;
  const onSave = layerArgs.addItemLineArgs?.onSave || (() => {});
  const {goBack} = useLayersFramework();
  const [clientForm] = Form.useForm();
  const state = useSelector((state) => state);

  const [searchText, setSearchText] = useState("");
  // const [showSelectItemModal, setShowSelectItemModal] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState("");
  // const [updateItemModal, setUpdateItemModal] = useState(false);
  // const [addItemModal, setAddItemModal2] = useState(false);
  const [itemQuantity, setItemQuantity] = useState(0);

  const setAddItemModal = (toShowOrNot) => {
    if (toShowOrNot) {
      pushInAppLayer({
        trigger: ELayerTrigger.IN_APP,
        type: ELayerType.FULL_SCREEN,
        target: EnumLayer.SAVE_ITEM,
      }, {
        saveItemActivityArgs: {
          selectedItem: undefined,
          onSave: onItemAdd,
        }
      });
    }
  }

  let initialClientId;

  const selectedItem =_.filter(state.items || [], (item) => item.name === selectedItemName)[0] || "";

  const options = _.map(state.items || [], ({ name, _id }) => ({
    label: name,
    value: _id,
  }));

  // Check for new item
  if (itemData._id) {
    initialClientId = options.filter((i) => i.value === itemData._id);
  }

  useEffect(() => {
    clientForm.setFieldsValue(itemData);
    if (initialClientId) {
      setSelectedItemName(initialClientId[0].label);
    }
  }, []);

  useEffect(() => {
    if (selectedItem._id !== itemData._id) {
      setSearchText("");
      setFormValues(selectedItem);
      clientForm.setFieldsValue({ qty: itemQuantity > 0 ? itemQuantity : itemData.qty > 0 && itemData.qty });
    }
  }, [selectedItem]);

  const setFormValues = (data) => {
    if(data){
      clientForm.setFieldsValue(data);
      clientForm.setFieldsValue({
      price: data.defaultRate,
      description: data.defaultDescription || "",
    });}
  };

  const setItemName = (label, val) => {
    setSelectedItemName(label);
    clientForm.setFieldsValue({ _id: val });
  };

  const onItemAdd = (data) => {
    onItemUpdate(data);
    goBack();
  };

  const onItemUpdate = (data) => {
    setFormValues(data);
    setSelectedItemName(data.name);
  };


  const [allItems] = useLazyStoreData(StoreDataTypes.ITEMS);
  const dbItemsMap = _.keyBy(allItems, "_id");
  const clientAction = (type, data) => {
    if (data) {
      data.taxRate = dbItemsMap[data._id]?.taxRate || 0;
    }
    onSave(type, data);
    // onCancel(); toDo infinity - goBack
    goBack();
  };

  return (
      <>
      <div style={{zIndex: Z_INDEXES.ADD_ITEM_LINE}}>
    <ActivityWrapper
      heading= {itemData._id ? "Update Item line" : "Add Item line"}
      onBack={goBack}
    >
      <div style={{ padding: 12 }}>
        <Form form={clientForm} style={{ fontSize: 12 }}>
          <Form.Item
              name={"_id"}
              label="Item"
              style={{ marginBottom: 8 }}
              rules={[{ required: true, message: "Please select Item" }]}
          >
            <SelectItemInput
                selectedItemName={selectedItemName}
                setItemName={setItemName}
                setAddItemModal={setAddItemModal}
            />
          </Form.Item>
          <Form.Item
              name={"description"}
              label="Description"
              style={{ marginBottom: 8 }}
          >
            <TextArea
                style={{
                  border: "2px solid #d0caca4a",
                  borderRadius: 12,
                  padding: 4,
                }}
                placeholder="Description (Optional)"
                autoSize={{ minRows: 2, maxRows: 2 }}
            />
          </Form.Item>
          <InlineContainer>
            <Form.Item name={"price"} label="Price" style={{width: "60%"}} rules={[{ required: true, message: "Can't be blank" }]}>
              <CustomInput
                  type={"number"}
                  prefix={`${RUPEE}`}
                  min={0}
                  placeholder="Price"
                  style={{ fontSize: 18}}
              />
            </Form.Item>
            <Form.Item name={"priceType"} style={{display: "flex", width: "30%", justifyContent: "center"}}>
              <CustomFormItem
                  valuesmap={{
                    true: "inc",
                    false: "exc",
                  }}
                  reversemap={{
                    inc: true,
                    exc: false,
                  }}
              />
            </Form.Item>
          </InlineContainer>

          <InlineContainer>
            <Form.Item name={"qty"} label="Qty" rules={[{ required: true }]} style={{ width: "40%"}}>
              <Stepper
                  style={{
                    height: 35,
                    width: "80%",
                    border: "2px solid #d0caca4a",
                    borderRadius: 12,
                  }}
                  onChange={(q) => setItemQuantity(q)}
                  min={1}
              />
            </Form.Item>
            {selectedItem && (
                <Form.Item label=" " style={{ color: "#666" }}>
                  <div style={{ fontSize: 16}}>GST: {selectedItem?.taxRate}%
                    {selectedItem?.hsn ? " | HSN: " + selectedItem?.hsn : ""}
                    &emsp;
                    <EditOutlined onClick={() => {
                      pushInAppLayer({
                        trigger: ELayerTrigger.IN_APP,
                        type: ELayerType.FULL_SCREEN,
                        target: EnumLayer.SAVE_ITEM,
                      }, {
                        saveItemActivityArgs: {
                          selectedItem: selectedItem,
                          onSave: onItemUpdate,
                        }
                      });
                      // setUpdateItemModal(true);
                    }} style={{color: "#0080FF", fontSize: 18}} />
                  </div>
                </Form.Item>
            )}
          </InlineContainer>

          <ButtonContainer>
            <button
                style={{ width: itemData._id && "45%"}}
                className={"fab primaryEffect generalRoundedButton buttonProperties"}
                onClick={() => {
                  clientForm
                      .validateFields()
                      .then(async (values) => {
                        console.log("## Just before saving ##", values, itemData);
                        clientAction("save", values);
                      })
                      .catch((info) => {
                        console.log("## Validate Failed ##", info);
                      });
                }}
            >
              Save
            </button>
            {itemData._id && (
                <button
                    style={{ width: "45%"}}
                    type={"primary"}
                    className={"fab redEffect generalRoundedButton buttonProperties"}
                    onClick={() => clientAction("delete")}
                >
                  Delete
                </button>
            )}
          </ButtonContainer>
        </Form>

        {/*{updateItemModal && (*/}
        {/*    <StackActivity*/}
        {/*        visible={updateItemModal}*/}
        {/*        setVisible={setUpdateItemModal}*/}
        {/*        type="itemSaveActivity"*/}
        {/*        data={selectedItem}*/}
        {/*        onSave={onItemUpdate}*/}
        {/*    />*/}
        {/*)}*/}
      </div>

    </ActivityWrapper>
      </div>
        {/*{addItemModal && (*/}
        {/*    <div style={{zIndex: Z_INDEXES.MAX}}>*/}
        {/*      <StackActivity*/}
        {/*          visible={addItemModal}*/}
        {/*          setVisible={setAddItemModal}*/}
        {/*          type="itemSaveActivity"*/}
        {/*          onSave={onItemAdd}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*)}*/}

      </>
  );
};

export default AddItemLineActivity;

const ListContainer = styled.div`
  max-height: 310px;
  margin-top: 10px;
  overflow-y: scroll;
`;

const InlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 70px;
  bottom:  -2px;
  position: absolute;
  background: white;
  display: flex;
  justify-content: space-between;
  margin-left: ${({ margin }) => margin && "10px"};
  padding-top: 20px;
`;


const OuterContainer = styled.div``;

const PopUpHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 12px 12px 0;
  font-size: 20px;
  font-weight: bold;
  border-top-right-radius: 10%;
  flex-direction: column;
`;

const TopLine = styled.div`
  height: 2.5px;
  background: lightgray;
  width: 52px;
  border-radius: 2px;
  margin-bottom: 10px;
`;

const Item = styled.div`
  background: ${({ selected }) => selected && "#d0caca4a"};
  padding-left: 10px;
  border-bottom: 1px solid #d0caca4a;
`;
