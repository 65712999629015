import React from "react";
import {PRIMARY_COLOR} from "../../models/constants";
import {Loading} from "antd-mobile";

const CustomLoading = () => {
    return (
        <div style={{
            width: '100%',
            height: '100%',
            color: PRIMARY_COLOR,
            fontSize: 35,
            textAlign: 'center',
            paddingTop: '40%',
            border: '0px solid red'
        }}>
            <Loading style={{color: 'currentcolor'}}/>
        </div>
    );
}

export default CustomLoading;