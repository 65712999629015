import React, {useMemo, useState} from 'react';
import {useLazyStoreData} from "../../../../hooks/useLazyStoreData.hook";
import {StoreDataTypes} from "../../../../hooks/useStoreDataLoader.hook";
import {truncate} from "../../../../utils";
import Text from "../../../components/Text";
import {DownOutlined} from "@ant-design/icons";
import styled from "styled-components";
import _ from "lodash";
import {ELayerTrigger, ELayerType, useLayersFramework, useMyParams} from "../../../v2framework/framework.hooks";
import {EnumLayer} from "../../../v2framework/layers.list";

export const CustomerBlock = React.memo(({value, onChange}) => {
    const selectedLedgerId = value;
    const {params: {voucherType}, pushInAppLayer} = useLayersFramework();
    const [ledgers] = useLazyStoreData(StoreDataTypes.LEDGERS);
    const [showCustomerSelectionPage, setShowCustomerSelectionPage] = useState(false);

    const selectedLedger = useMemo(
        () => (ledgers || []).find(x => x._id === selectedLedgerId),
        [ledgers, selectedLedgerId]
    );

    const ledgersMap = useMemo(() => {
        return _.keyBy((ledgers || []).filter(
            (l) =>
                !l.type ||
                l.type ===
                (voucherType === "purchase" || voucherType === "debitNote"
                    ? "creditor"
                    : "debtor")
        ), "_id")
    }, [ledgers, voucherType]);

    return <>
        <CustomerInputBoxDiv onClick={() => {
            pushInAppLayer({
                trigger: ELayerTrigger.IN_APP,
                type: ELayerType.FULL_SCREEN,
                target: EnumLayer.SELECT_CUSTOMER,
            }, {
                selectedSelectCustomerId: selectedLedgerId,
                onSaveSelectCustomer: (id) => onChange(id),
            })
        }}>
            {selectedLedger?.name ? (
                truncate(selectedLedger.name, 33)
            ) : (
                <Text
                    color="#8080808c"
                    fontSize={18}
                    content={
                        ["purchase", "debitNote"].includes(voucherType) ? "Select Vendor" : "Select Customer"
                    }
                />
            )}
            <DownOutlined style={{ color: "gray" }} />
        </CustomerInputBoxDiv>

    </>
});


const CustomerInputBoxDiv = styled.div`
  height: 40px;
  padding: 2px 12px;
  border-radius: 12px;
  border: 2px solid #d0caca4a;
  margin-top: 4px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
`;
