import React, {useEffect, useState} from "react";
import {Alert} from "antd";
import Activity from "../../../activity";
import {Button, Grid, NavBar, Form, Input, TextArea} from "antd-mobile";
import {submitHelpTicket} from "../../../../services/api";
import ActivityWrapper from "../../../components/Activity";
import {useLayersFramework} from "../../../v2framework/framework.hooks";
import { MobileButtonV2 } from "../../../components/MobileButtonV2";


const HelpSettings = () => {

    const [form] = Form.useForm();
    const [ticketNum, setTicketNum] = useState();
    const {goBack} = useLayersFramework();

    useEffect(() => {
        form.setFieldsValue({
            subject: 'Facing an Issue'
        });
    }, [form]);

    return (
        <ActivityWrapper
            heading={"Contact Support"}
            footer={
                <Grid columns={2} style={{width: '100%', height: '100%', border: '0px solid orange'}}>
                    <Grid.Item>
                        <Button style={{borderRadius: 0, width: '100%', height: '100%'}} color={"danger"}
                                onClick={() => {
                                    goBack();
                                }}>
                            Cancel
                        </Button>
                    </Grid.Item>
                    <Grid.Item>
                        <MobileButtonV2 style={{borderRadius: 0, width: '100%', height: '100%'}} color={"primary"}
                                onClick={async () => {
                                    const formData = await form.validateFields();
                                    const resp = await submitHelpTicket(formData);
                                    const {ticketNum} = resp.data.data;
                                    setTicketNum(ticketNum);
                                    form.resetFields();
                                    form.setFieldsValue({
                                        subject: 'Facing an Issue'
                                    });
                                }}>
                            Submit
                        </MobileButtonV2>
                    </Grid.Item>
                </Grid>
            }
            content={
                <div>
                    <Form form={form}>
                        <Form.Item name={"name"} label={"Name"} rules={[{required: true, message: "can't be blank"}]}>
                            <Input placeholder={"Please enter your name"}/>
                        </Form.Item>
                        <Form.Item name={"email"} label={"Email"}
                                   rules={[{type: "email", required: true, message: "Invalid Email"}]}>
                            <Input placeholder={"Email address"}/>
                        </Form.Item>
                        <Form.Item name={"subject"} label={"Subject"}
                                   rules={[{required: true, message: "Please select subject"}]}>
                            <select placeholder={"Select Subject"} style={{
                                width: '100%',
                                height: 35,
                                background: 'white',
                                border: 0
                            }}>
                                {
                                    [
                                        "Facing an Issue",
                                        "Feedback",
                                        "Customized Plan Requirement",
                                        "Request a New Feature"
                                    ]
                                        .map(item => (<option value={item}>{item}</option>))
                                }
                            </select>
                        </Form.Item>
                        <Form.Item name={"message"} label={"Message"}
                                   rules={[{required: true, message: "can't be blank"}]}>
                            <TextArea placeholder={"Message"} rows={5}/>
                        </Form.Item>
                    </Form>

                    <br/><br/>
                    {
                        ticketNum &&
                        <div style={{padding: 8}}>
                            <Alert
                                message={`Ticket #${ticketNum} has been created for your query. Our team will reply you within 48 working hours through email.`}
                                type="info" showIcon={true}/>
                        </div>
                    }
                </div>
            }
        />
    )

};

export default HelpSettings;
