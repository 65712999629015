/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Input, Form, Button, Switch, List } from "antd";
import {
  WhatsAppOutlined,
  MailOutlined,
  ShareAltOutlined,
  EditOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import Text from "../components/Text";
import BottomPopup from "../components/BottomPopup";
import Activity from "../components/Activity";
import { getGreetingImages } from "../../services/api";
import { shareOnWhatsapp } from "../../utils/whatsapp";
import { getPublicShareLink } from "../../utils";
import { CustomInput } from "../../styles/js/styleConstants";
import { ButtonV2 } from "../components/ButtonV2";

const CarouselItemImages = ({ data, type, setSelectedId }) => {
  const src = getGreetingImages({
    id: data.id,
  });

  return (
    <InnerContainer padding="0px" width="200px">
      <CarouselItem
        src={src}
        height={type === "whatsapp" ? "100px" : "150px"}
        onClick={() => setSelectedId(data.id)}
      />
    </InnerContainer>
  );
};

const CustomFormItem = ({ value, onChange, label , checked, unChecked}) => {
  return (
    <List.Item
      style={{}}
      extra={<Switch checkedChildren={checked} unCheckedChildren={unChecked} checked={!!value} onClick={onChange} />}
    >
      <Text fontSize={14} fontWeight={600} content={label} />
    </List.Item>
  );
};

const getValue = (i, inputFieldConfigs, state) => {
  const data = inputFieldConfigs[i];
  const { company, user } = state;
  const storeLink = getPublicShareLink(company?.hashId);
  const pathDataObj = {
    company,
    user,
    misc: { storeLink },
  };
  return data.defaultValue.source === "path" || data.key === "storeLink"
    ? _.get(pathDataObj, data.defaultValue.path)
    : data.defaultValue.value;
};

const EditCard = ({
  type,
  images,
  inputFields,
  inputFieldConfigs,
  selectedCardId,
  onCancel,
}) => {
  const state = useSelector((state) => state);
  const [form] = Form.useForm();

  const [selectedId, setSelectedId] = useState(selectedCardId);
  const [showEditForm, setShowEditForm] = useState(false);
  const [imageData, setImageData] = useState({});

  useEffect(() => {
    const obj = { id: selectedId };
    inputFields.map((i) => (obj[i] = getValue(i, inputFieldConfigs, state)));
    setImageData(obj);
    form.setFieldsValue(obj);
  }, [state]);

  const FormContent = (
    <FormContainer padding="20px" column noGap height="400">
      <Header>
        <Text fontSize={16} fontWeight={600} content="Edit Details" />
        <CloseOutlined
          style={{ fontSize: 16 }}
          onClick={() => setShowEditForm(false)}
        />
      </Header>
      <Form
        form={form}
        labelCol={{
          span: 5,
          style: {
            textAlign: "left",
          },
        }}
        wrapperCol={{ span: 13 }}
        layout="horizontal"
        name="form_in_modal"
      >
        {inputFields.map((i) => (
            inputFieldConfigs[i].inputType !== "switch" ? (
              <Form.Item
              style={{ margin: "0 0 4px" }}
              name={inputFieldConfigs[i].key}
              label={<Text
                fontSize={14}
                fontWeight={600}
                content={inputFieldConfigs[i].label} />}
            ><CustomInput type={inputFieldConfigs[i].inputType} />
            </Form.Item>
            ) : (
              <Form.Item
            style={{ margin: "0 0 4px" }}
            name={inputFieldConfigs[i].key}
          >
              <CustomFormItem label={inputFieldConfigs[i].label} checked={inputFieldConfigs[i].inputOptions[0]} unChecked={inputFieldConfigs[i].inputOptions[1]} />
          </Form.Item>
        )))}
      </Form>
      <ButtonV2
        style={{
          width: "100%",
          height: "40px",
          fontSize: "16px",
          borderRadius: "12px",
          marginTop: "20px",
          marginLeft: "12px",
        }}
        type={"primary"}
        className={"fab primaryEffect generalRoundedButton"}
        onClick={async () => {
          form
            .validateFields()
            .then(async (values) => {
              setImageData(values);
              setShowEditForm(false);
            })
            .catch((info) => {
              console.log("## Validate Failed ##", info);
            });
        }}
      >
        Save Details
      </ButtonV2>
    </FormContainer>
  );

  return (
    <Activity
      heading={`Share ${
        type === "whatsapp"
          ? "Whatsapp Story"
          : type === "business"
          ? "Visiting Card"
          : "Greeting Card"
      }`}
      content={
        <>
          <Container>
            <InnerContainer
              padding={type === "whatsapp" ? "20px 43px" : "20px 30px"}
              height={type === "whatsapp" ? "520px" : "300px"}
              margin={type !== "whatsapp" && "40px 0px"}
            >
              <CarouselItem
                src={getGreetingImages({
                  ...imageData,
                  id: selectedId,
                })}
                onClick={() => setShowEditForm(true)}
              />
            </InnerContainer>
          </Container>

          {type !== "whatsapp" && (
            <TextContainer>Please select template!</TextContainer>
          )}
          <CarouselContainer>
            {images &&
              images.map((i) => (
                <CarouselItemImages
                  data={i}
                  type={type}
                  setSelectedId={setSelectedId}
                />
              ))}
          </CarouselContainer>

          <BottomContainer>
            <WhatsAppOutlined
              style={{ fontSize: 25, color: "green" }}
              onClick={async () => {
                shareOnWhatsapp({
                  message: "This is my business card",
                });
              }}
            />
            <MailOutlined style={{ fontSize: 25, color: "red" }} />
            <ShareAltOutlined style={{ fontSize: 25, color: "purple" }} />
            <EditOutlined
              onClick={() => setShowEditForm(true)}
              style={{ fontSize: 25, color: "blue" }}
            />
          </BottomContainer>
            <BottomPopup
                visible={showEditForm}
                setVisible={setShowEditForm}
              content={FormContent}
              height={
                type === "whatsapp"
                  ? "455px"
                  : type === "greetingCards"
                  ? "510px"
                  : "630px"
              }
            />
        </>
      }
      onBack={onCancel}
    />
  );
};

export default EditCard;

const CarouselItem = styled.img`
  src: ${({ src }) => src};
  border: 1px;
  border-radius: 10px;
  width: 100%;
  height: ${({ height }) => height};
  box-shadow: 0px 0px 14px -3px rgba(97, 94, 97, 1);
`;

const Container = styled.div`
  margin-top: 5px;
`;
const CarouselContainer = styled.div`
  margin-top: 5px;
  display: flex;
  width: 100%;
  overflow: scroll;
  gap: 20px;
  padding: 8px;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  . {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  position: absolute;
  background: white;
`;

const InnerContainer = styled.div`
  height: ${({ height }) => height};
  min-width: ${({ width }) => width};
  max-width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  bottom: 1px;
  position: absolute;
  width: 100%;
  box-shadow: 0px 0px 12px -3px rgba(97, 94, 97, 1);
`;

const TextContainer = styled.div`
  color: purple;
  font-size: 20px;
  font-weight: 600;
  padding-left: 20px;
  margin-bottom: 20px;
`;

const FormContainer = styled.div`
  height: ${(height) => `${height}px`};
  border-radius: 12px;
  padding: 20px;
  display: flex;
  width: 100%;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  align-items: ${({ start }) => start && "flex-start"};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
`;
