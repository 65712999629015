import React from "react";
import styled from "styled-components";

const Text = ({
    fontSize,
    fontWeight,
    color,
    top,
    content,
    textAlign,
    style,
}) => {
  return (
    <TextContainer fontSize={fontSize} fontWeight={fontWeight} textAlign={textAlign} top={top} color={color} style={style}>{content}</TextContainer>
  )
};

export default Text;

const TextContainer = styled.div`
font-size : ${({fontSize}) => fontSize ? `${fontSize}px` : "16px"};
color : ${({color}) => color ? color : "black"};
margin-top: ${({top}) => top};
font-weight : ${({fontWeight}) => fontWeight ? fontWeight : "normal"};
text-align: ${({textAlign}) => textAlign};
`;