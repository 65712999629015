import React, { useEffect } from "react";
import { logRender } from "../../utils";

const FlexContainer = React.memo(({ header, footer, content, children, footerHeight }) => {
  return (
    <div
      style={{
        // border: '2px solid green',
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!!header && <div style={{ flex: "none" }}>{header}</div>}
      <div
        style={{
          // height: 'available',
          overflowY: "scroll",
            paddingBottom: !!footer ? (footerHeight || 56) : 0,
          flex: "auto",
        }}
      >
        {!!content && content}
          {children}
      </div>
      {!!footer && (
        <div style={{ flex: "none" }}>
            <div style={{
                position: 'absolute',
                bottom: 0,
                height: footerHeight || 56,
                width: '100%',
                // border: '2px solid red',
                textAlign: 'center',
                verticalAlign: 'middle',
                // lineHeight: '56px',
                // color: 'white',
                // fontWeight: 'bold',
                // fontSize: 22
            }}>
                {footer}
            </div>
        </div>
      )}
    </div>
  );
});

export default FlexContainer;
