import React, {useEffect, useRef, useState} from 'react';
import {getLedgers} from '../../../../services/api';
import _ from "lodash";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Swiper, Tabs} from "antd-mobile";
import LedgersList from "./LedgersList";
import Activity from "../../../components/Activity";
import FlexContainer from "../../../components/FlexContainer";

const fetchRecords = async ({setLoading, setRecords}) => {
    setLoading(true);
    const response = await getLedgers("all");
    const resOffers = _.get(response, 'data.data.ledgers', []);
    setRecords(resOffers.sort((a, b) => (b.balance || 0) - (a.balance || 0)));
    setLoading(false);
}

const LedgersTab = () => {
    const state = useSelector(state => state);
    const params = useParams();
    const {ledgerType} = params;
    const [loading, setLoading] = useState(true);
    const [records, setRecords] = useState([]);
    const swiperRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [afterDataSaved, setAfterDataSaved] = useState(false);

    useEffect(() => {
        if (state.company) {
            fetchRecords({ledgerType, setRecords, setLoading});
        }
    }, [state, ledgerType, afterDataSaved]);

    const tabItems = [
        { key: 'customers', title: 'Customers' },
        { key: 'vendors', title: 'Vendors' },
    ];

    return (
        <Activity
            heading="Ledgers"
            onBack={
                // () => navigate("/dashboard")
                null
            }
            content={
                <FlexContainer
                    header={
                        <Tabs
                            activeKey={tabItems[activeIndex].key}
                            style={{background: "white", '--title-font-size': '18px'}}
                            activeLineMode={"full"}
                            onChange={key => {
                                const index = tabItems.findIndex(item => item.key === key)
                                setActiveIndex(index)
                                swiperRef.current?.swipeTo(index)
                            }}
                        >
                            <Tabs.Tab activeLineMode={"full"} className={ledgerType==="customers" ? "selectedPartyTab" : "unselectedPartyTab"}
                                      title={<div>Customers</div>}
                                      key="customers">
                            </Tabs.Tab>
                            <Tabs.Tab activeLineMode={"full"} className={ledgerType==="vendors" ? "selectedPartyTab" : "unselectedPartyTab"}
                                      title={<div>Vendors</div>} key="vendors">
                            </Tabs.Tab>
                        </Tabs>
                    }
                    content={
                        // <div style={{border: '2px solid purple', height: '100%'}}>
                        //     <FlexContainer
                        //         header={"Search Here"}
                        //         content={comp()}
                        //     />
                        // </div>
                        <div style={{padding: 8, paddingTop: 8, background: '#fff', height: '100%'}}>
                            <Swiper
                                direction='horizontal'
                                indicator={() => null}
                                ref={swiperRef}
                                defaultIndex={activeIndex}
                                onIndexChange={index => {
                                    setActiveIndex(index)
                                }}
                                style={{
                                    height: '100%',
                                    border: '0px solid red'
                                }}
                            >
                                <Swiper.Item style={{height: '100%', border: '0px solid blue'}}>
                                    <LedgersList loading={loading} title={"New Customer"} records={records.filter(l => l.type === "debtor")} ledgerType ="debtor" onSave={() => setAfterDataSaved(!afterDataSaved)}/>
                                </Swiper.Item>
                                <Swiper.Item style={{height: '100%', border: '0px solid yellow'}}>
                                    <LedgersList loading={loading} title={"New Vendor"} records={records.filter(l => l.type === "creditor")} ledgerType ="creditor" onSave={() => setAfterDataSaved(!afterDataSaved)}/>
                                </Swiper.Item>
                            </Swiper>
                        </div>
                    }
                />
            }
        />
    );
}

export default React.memo(LedgersTab);
