import React from "react";
import styled from "styled-components";
import moment from "moment";

import Activity from "../../../../components/Activity";
import Text from "../../../../components/Text";
import { beautifyAmount } from "../../../../../utils";
import { RUPEE } from "../../../../../models/constants";

const PayoutItems = ({ selectedRecord, onCancel }) => {
  const Card = (data) => {
    return (
      data && (
        <CardContainer>
          <InnerContainer flexEnd>
            <Text
              fontSize={16}
              color="grey"
              content={(data && data.creationDate
                ? moment(data.creationDate)
                : moment()
              ).format("DD MMM YYYY")}
            />
          </InnerContainer>
          <Text
            fontSize={20}
            fontWeight={600}
            content={data.customer && data.customer.name}
            top="-10px"
          />

          <Text
            fontSize={16}
            color="grey"
            content={data.customer && data.customer.phone}
          />

          <InnerContainer style={{ marginTop: "10px" }}>
            <Table>
              <Container border>
                <TableHeading border>
                  <Text fontWeight={600} content="Amount" />
                </TableHeading>
                <TableHeading>
                  <Text fontWeight={600} content="Bill #" />
                </TableHeading>
              </Container>
              <Container>
                <TableHeading border>
                  <Text
                    color="green"
                    fontWeight={600}
                    content={RUPEE + beautifyAmount(data.amount || 0)}
                  />
                </TableHeading>
                <TableHeading>
                  <Text fontWeight={600} content={data.invoiceNumber || "--"} />
                </TableHeading>
              </Container>
            </Table>
          </InnerContainer>
          <InnerContainer></InnerContainer>
          <Remarks>
            <Text
              fontSize={16}
              content={
                data.orderDetails && data.orderDetails.remarks
                  ? data.orderDetails.remarks
                  : ""
              }
            />
          </Remarks>
        </CardContainer>
      )
    );
  };
  return (
    <Activity
      heading={"Payout #" + selectedRecord.displayPayoutId + " Details"}
      onBack={() => {
        onCancel();
      }}
      content={
        <>
          <TopContainer>
            <Text
              fontSize={20}
              fontWeight={600}
              content={
                "Total Amount : " +
                (RUPEE + beautifyAmount(selectedRecord.totalAmount || 0))
              }
            />
            <Text
              fontSize={20}
              fontWeight={600}
              content={
                "Transaction Fee : " +
                (RUPEE + beautifyAmount(selectedRecord.transactionfee || 0))
              }
            />
            <Text
              fontSize={20}
              fontWeight={600}
              content={
                "Payout Amount : " +
                (RUPEE + beautifyAmount(selectedRecord.paidAmount || 0))
              }
            />
          </TopContainer>

          <BottomContainer>
            {selectedRecord.payOrders.map((i) => Card(i))}
          </BottomContainer>
        </>
      }
    />
  );
};

export default PayoutItems;

const TopContainer = styled.div`
  margin-bottom: 15px;
  padding: 5%;
  position: absolute;
  background: white;
  width: 100%;
`;

const BottomContainer = styled.div`
  margin-top: 130px;
  padding: 5% 5% 15%;
`;

const CardContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 20px;
  border: 2px solid gray;
  margin: 0px 0px 21px;
  padding: 5px 15px;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  align-items: ${({ flexEnd }) => flexEnd && "flex-end"};
  justify-content: space-between;
`;

const Table = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  width: 100%;
  border: 2px solid lightgray;
`;

const Container = styled.div`
  width: 100%;
  border-bottom: ${({ border }) => border && "2px solid lightgray"};
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const TableHeading = styled.div`
  width: ${({ width }) => width};
  width: 30%;
  border-right: ${({ border }) => border && "2px solid lightgray"};
  align-items: center;
  text-align: center;
`;

const Remarks = styled.div`
  padding: 10px 0px;
`;
