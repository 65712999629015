/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import _ from "lodash";
import { message } from "antd";
import { Popup, Form, Button } from "antd-mobile";
import { CalendarOutlined, MinusOutlined } from "@ant-design/icons";

import Text from "./Text";
import {useDateRange} from "../../hooks/dateRange.hook";
import {dateRangeTemplates, dateRangeTemplatesMap} from "../activities/dateRangePicker/rangeTemplates";
import {getDateFromNativePicker} from "../../utils/webViewUtilsV2/interfaces";


const DateRangePicker = ({ onClose, filterType }) => {
  const [clientForm] = Form.useForm();
  const [showPopup, setShowPopup] = useState(true);
  const [selectedDateFilter, setSelectedDateFilter] = useState(filterType || "Year Range");

  const [selectedDateRange, setSelectedDateRange , actualValues] = useDateRange();

  useEffect(() => {
    // const { startDate, endDate, filterType } = getAppDateRange();
    const startDate = actualValues.start;
    const endDate = actualValues.end;
    const filterType = selectedDateRange.filterType;

    console.log("## UseEffect Range Picker ##", filterType);
    setSelectedDateFilter(filterType);
    if (filterType === "custom") {
      clientForm.setFieldsValue({
        startDate: startDate,
        endDate: endDate,
      });
    } else {
      const template = dateRangeTemplatesMap[filterType];
      if (template) {
        const [start, end] = template.getRange();
        clientForm.setFieldsValue({
          startDate: start,
          endDate: end,
        });
      }
    }
  }, [selectedDateRange, actualValues]);

  useEffect(() => {
    const rangeTemplate = dateRangeTemplatesMap[selectedDateFilter];
    if (!rangeTemplate || selectedDateFilter === "custom") {
      return;
    }
    const [start, end] = rangeTemplate.getRange();
    clientForm.setFieldsValue({
      startDate: start,
      endDate: end,
    });
  }, [selectedDateFilter]);

  const DateUI = ({ value, onChange }) => {
    return (
      <div
        onClick={async () => {
          console.log(`picking date`);
          const newVal = await getDateFromNativePicker();
          onChange(newVal);
        }}
      >
        <CalendarOutlined />
        &nbsp;
        {value?.format("DD MMM YYYY")}
      </div>
    );
  };


  return (
    <>
      <Popup
        visible={showPopup}
        onMaskClick={() => {
          setShowPopup(false);
          onClose();
        }}
        bodyStyle={{
          width: "100%",
          borderTopRightRadius: "5%",
          height: "360px",
          borderTopLeftRadius: "5%",
          padding: "20px",
        }}
      >
        <OuterContainer>
          <Container>
            <Text fontSize={16} fontWeight={600} content="Filter" />
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedDateFilter("currentFy");
              }}
            >
              <Text
                fontSize={16}
                fontWeight={600}
                color="red"
                content="Reset"
              />
            </div>
          </Container>
          <Form form={clientForm} style={{ fontSize: 12 }}>
            <Container>
              <Form.Item
                label="Start Date"
                name="startDate"
                style={{ marginBottom: 0, width: "48%" }}
              >
                <DateUI type="startDate" onChange={() => setSelectedDateFilter("custom")} />
              </Form.Item>
              <MinusOutlined style={{ color: "#0080ff" }} />
              <Form.Item
                label="End Date"
                name="endDate"
                style={{ marginBottom: 0, width: "48%" }}
              >
                <DateUI type="endDate" onChange={() => setSelectedDateFilter("custom")} />
              </Form.Item>
            </Container>
          </Form>
          <ButtonContainer>
            {_.chunk(
              dateRangeTemplates.map(({ label, labelCreator, key }) => (
                <StyledButton
                  key={key}
                  active={
                    selectedDateFilter === key
                      // ? true
                      // : selectedDateFilter === "Year Range" &&
                      //   label === yearRangeLabel
                  }
                  onClick={() => {
                    setSelectedDateFilter(key);
                  }}
                >
                  {label || labelCreator()}
                </StyledButton>
              )),
              3
            ).map((row, rowIndex) => (
              <Container top={rowIndex === 0}>{row}</Container>
            ))}
          </ButtonContainer>
          <button
            style={{ marginTop: "26px", marginLeft: "4%", width: "100%" }}
            className={
              "fab primaryEffect generalRoundedButton buttonProperties"
            }
            onClick={async () => {
              clientForm
                .validateFields()
                .then(async (values) => {
                  if (values) {
                    setSelectedDateRange({
                      filterType: selectedDateFilter,
                      start: values.startDate,
                      end: values.endDate,
                    })
                    // setAppDateRange(values.startDate,values.endDate,selectedDateFilter);
                    setShowPopup(false);
                    onClose();
                  } else {
                    message.success("else");
                  }
                })
                .catch((info) => {
                  message.error(info);
                });
            }}
          >
            Apply
          </button>
        </OuterContainer>
      </Popup>
    </>
  );
};

export default DateRangePicker;

const OuterContainer = styled.div``;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const StyledButton = styled(Button)`
  border-radius: 12px;
  color: ${({ active }) => (active ? "white" : "black")};
  font-size: 12px;
  font-weight: 600px;
  background-color: ${({ active }) => (active ? "#0080ff" : "white")};
  width: 30%;
  padding: 9px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
`;
