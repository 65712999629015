import {isMobileApp, sendDataToApp} from "./utils/webviewUtils";
import {iLog} from "./utils/webViewUtilsV2/interfaces";

const overrideLogger = () => {
    const nativeConsoleLog = console.log;

    if (isMobileApp()) {
        console.log = (...args) => {
            try {
                // sendDataToApp({type: "logging", payload: args}, nativeConsoleLog);
                iLog(args.join("::"))
            } catch(err) {
            }
            return nativeConsoleLog(...args);
        }

        console.error = console.log;
        console.warn = console.log;
        console.debug = console.log;
    }
}

export const initApp = () => {
    overrideLogger();
}