import React, { useState, useEffect, useRef } from "react";
import { Popup, List, Input } from "antd-mobile";
import styled from "styled-components";
import { DownOutlined } from "@ant-design/icons";
import { SearchOutline } from "antd-mobile-icons";
import Text from "./Text";
import { truncate } from "../../utils";
import {useDrawerActions} from "../../hooks/drawer";
import {DRAWERS} from "../drawers/registry";

const BottomSelect = ({
  placeholder,
  searchPlaceholder,
  data,
  header,
  value,
  onChange,
  disabled,
  subLabel,
    zIndex,
}) => {
  // const [showPopup, setShowPopup] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const [listData, setListData] = useState([]);
  const [showPopup, hidePopup] = useDrawerActions();
  const dropdownRef = useRef();

  const OnSelect = (data) => {
    setSelectedValue(data.value);
    onChange(data.value);
    hidePopup(DRAWERS.BOTTOM.SELECT);
  };

  useEffect(() => {
    setListData(data);
  }, []);

  useEffect(() => {
    setListData(data);
    if (value) {
      setSelectedValue(value);
    }
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        hidePopup(DRAWERS.BOTTOM.SELECT);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [hidePopup]);

  const OptionLabel = () => {
    let Result;
    const Length = Object.keys(data).length;
    for (let i = 0; i < Length; i++) {
      if (data[i].value === value) {
        Result = data[i].label;
      }
    }
    return truncate(Result, 25);
  };

  return (
    <>
      <DropdownContainer
        ref={dropdownRef}
        onClick={() => showPopup(DRAWERS.BOTTOM.SELECT, {
          header, data, searchPlaceholder, listData, selectedValue, OnSelect, subLabel, zIndex
        })}
        disabled={disabled || false}
      >
        <ValueContainer>{value ? OptionLabel() : placeholder}</ValueContainer>
        <DownOutlined style={{ color: "black" }} />
      </DropdownContainer>
    </>
  );
};

export default BottomSelect;

const DropdownContainer = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid black;
  background: ${console.log("SD")}
  justify-content: space-between;
  padding: 4px 9px;
  align-items: center;
  height: 35px;
  justify-content: space-between;
  border-radius: 10px;
  pointer-events: ${({ disabled }) => disabled && "none"};
  background-color: ${({ disabled }) => disabled && "#d0caca4a"};
`;

const ValueContainer = styled.div`
  font-size: 18px;
`;



