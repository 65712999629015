import {PlusOutlined} from "@ant-design/icons";
import React from "react";
import {logRender} from "../../utils";

const CustomFAB = React.memo(({title, Bottom}) => {
    logRender("CustomFAB");
    return (
        <button className={"fab primaryEffect generalRoundedButton"} style={{bottom : Bottom && '80px'}}>
            <PlusOutlined/>
            &nbsp;{title || "New"}
        </button>
    );
});

export default CustomFAB;