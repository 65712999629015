import React from 'react';
import Activity from "../../../components/Activity";
import ItemCard from './itemCard';

const ItemsTab = () => {

    return (
        <Activity
            heading="Items"
            content={<ItemCard />}
            onBack={null}
        />
    )};

export default React.memo(ItemsTab);
