import React from "react";
import FlexContainer from "./FlexContainer";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {NavBar} from "antd-mobile";
import {useNavigate} from "react-router-dom";
import {useLayersFramework} from "../v2framework/framework.hooks";

const ActivityWrapper = ({heading, content, children, right, onBack, noHeading= false, footer}) => {
    // const {goBack} = useActivityFramework(); // test comment
    const {goBack} = useLayersFramework();

    return (
        <div style={{width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0}}>
        <FlexContainer
            header={
                !noHeading && <NavBar
                    right={right}
                    style={{
                        '--height': '56px',
                        paddingTop: 6,
                        borderBottom: '1px solid lightgrey',
                        boxShadow: "0px 0px 12px -3px rgba(97,94,97,1)",
                        '-font-size': '30px'
                    }}
                    backArrow={
                        <div style={{marginLeft: 12, fontWeight: '600', fontSize: 20, verticalAlign: 'middle', lineHeight: '40px', height: 40}}>
                            <ArrowLeftOutlined/>
                            &nbsp;&nbsp;&nbsp;
                            {/*<span style={{verticalAlign: 'middle', height: 50, lineHeight: '50px'}}>*/}
                            <span>
                                {heading}
                            </span>
                            {/*</span>*/}
                        </div>
                    } 
                    className={"navBar"}
                    onBack={() => {!!onBack ? onBack() : goBack()}}
                    // back={<div style={{fontSize: 18, verticalAlign: 'middle', height: 50, lineHeight: '50px'}}>Parties</div>}
                >
                </NavBar>
            }
            content={content}
            footer={footer}
        >
            {children}
        </FlexContainer>
        </div>
    )
};

export default ActivityWrapper;
