import moment from "moment";
import _ from "lodash";
import {EStorageKeys, iStorage} from "../services/storage";


export const getCurrentFinancialYear = () => {
    if (moment().month() < 3) {
      return {
        financialStartDate: moment().startOf("month").month(3).subtract(1, "years"),
        financialEndDate: moment().startOf("month").month(3).subtract(1, "days"),
      };
    } else
      return {
        financialStartDate: moment().startOf("month").month(3),
        financialEndDate: moment().startOf("month").month(3).subtract(1, "days").add(1, "years"),
      };
  };


// export const getAppDateRange = () => {
//   const dateRanges = iStorage.get(EStorageKeys.dateRange) || {
//     startDate: moment().subtract(1, 'month').valueOf(),
//   };
//   return {
//     startDate: moment(dateRanges.startDate).startOf('day'),
//     endDate: moment(dateRanges.endDate).endOf('day'),
//     filterType: dateRanges.filterType || "custom",
//   };
// };

// export const setAppDateRange = (startDate, endDate, filterType) => {
//   const obj = {
//     startDate: startDate.valueOf(),
//     endDate: endDate.valueOf(),
//     filterType,
//   };
//   iStorage.set(EStorageKeys.dateRange, obj);
// }
