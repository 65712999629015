import React, {useEffect} from "react";
import {Image, message, Spin} from "antd";
import {loginRequest} from "../../services/api";
import {customDispatcher, isBlankString} from "../../utils";
import {useDispatch} from "react-redux";
import logo from "../../res/img/logoLandscapeV3.png";
import {
    appListener, emitAppLoadedSignal, initBackPressedV2,
    isMobileApp,
    requestPermissions,
    sendDataToApp,
} from "../../utils/webviewUtils";
import {getCredentials} from "../../services/cookiesPlus";
import {EStorageKeys, iStorage} from "../../services/storage";
import {useMyParams} from "../v2framework/framework.hooks";
import {EnumLayer} from "../v2framework/registry";
import {useNavigate} from "react-router-dom";
import {appConfig} from "../../config";
import {useDateRange} from "../../hooks/dateRange.hook";
import {hideAppSplash} from "../../utils/webViewUtilsV2/interfaces";

export const SplashScreen = React.memo(() => {
    const navigate = useNavigate();
    const dispatch = customDispatcher(useDispatch());
    const [, setSelectedDateRange] = useDateRange();
    const [stack] = useMyParams();

    useEffect(() => {
        const handler = async () => {
            console.log("## Splash Handler ##");
            const xyz = iStorage.get("kbAppUrl");
            console.log("## XYZ ##", xyz);
            await new Promise(resolve => {
                if (isMobileApp()) {
                    initBackPressedV2();
                    // requestPermissions();
                } else {
                    resolve();
                }
                resolve(); // ToDo infinity
            });

            if (appConfig.enablePersistentDateRange) {
                setSelectedDateRange(iStorage.get(EStorageKeys.dateRange));
            }

            const {username, password} = getCredentials();
            let redirectionPage = "";
            if (!username || !password) {
                console.log("## Redirecting to Login Page ##");
                // pushActivity(EActivity.LOGIN);
                // navigate("/login");
                redirectionPage = "/login";
            } else {
                console.log("## Trying Login ##");
                const tryLogin = async () => {
                    // await new Promise(resolve => setTimeout(resolve, 50000));
                    console.log("## Splash TryLogin ##");
                    const user = await loginRequest(username, password, dispatch);
                    if (user) { // logged in
                        if (!isBlankString(user.company?.name)) {
                            // pushActivity(EActivity.HOME);
                            if (stack?.length <= 1) { // commenting it, as it was blocking reloads on /dashboard URLs
                                redirectionPage = "/dashboard";
                                // navigate("/dashboard"); // redirection not needed if proper URL exists
                            }
                        } else {
                            // pushActivity(EActivity.LAST_STEP); // ToDo - push LastStep activity
                            redirectionPage = "/lastStep";
                            // navigate("/lastStep");
                        }
                    } else { // auth failed
                        // pushActivity(EActivity.LOGIN);
                        redirectionPage = "/login";
                        // navigate("/login");
                    }
                }
                await tryLogin();
            }
            if (redirectionPage) {
                navigate(redirectionPage);
            }

            if (isMobileApp()) {
                await new Promise(resolve => setTimeout(resolve, 100));
                hideAppSplash();
            }
        };

        handler();

    }, []);

    return (
        <div className={"splash"}>
            <Image src={logo}/>
            <br/><br/>
            <Spin size="large"/>
        </div>
    )
});

