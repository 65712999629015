import {EStorageKeys, iStorage} from "../../../services/storage";
import moment from "moment";
import _ from "lodash";
import {getCurrentFinancialYear} from "../../../utils/appDateRange";

export const dateRangeTemplates = [
    {
        key: "currentFy",
        label: "",
        labelCreator: () => {
            const {financialStartDate, financialEndDate} = getCurrentFinancialYear();
            return `FY ${financialStartDate.format("YY")}-${financialEndDate.format("YY")}`;
        },
        getRange: () => {
            const {financialStartDate, financialEndDate} = getCurrentFinancialYear();
            return [financialStartDate, financialEndDate];
        },
    },
    {
        key: "custom",
        label: "Custom",
        getRange: () => {
            const storedDateRange = iStorage.get(EStorageKeys.dateRange);
            return [
                moment(storedDateRange?.startDate),
                moment(storedDateRange?.endDate),
            ];
        },
    },
    {
        key: "today",
        label: "Today",
        getRange: () => [
            moment().startOf("day"),
            moment().endOf("day"),
        ],
    },
    {
        key: "yesterday",
        label: "Yesterday",
        getRange: () => [
            moment().subtract(1, "days").startOf("day"),
            moment().subtract(1, "days").endOf("day"),
        ],
    },
    {
        key: "thisWeek",
        label: "This Week",
        getRange: () => [
            moment().startOf("week"),
            moment().endOf("week"),
        ],
    },
    {
        key: "lastWeek",
        label: "Last Week",
        getRange: () => [
            moment().subtract(1, "weeks").startOf("week"),
            moment().subtract(1, "weeks").endOf("week"),
        ],
    },
    {
        key: "thisMonth",
        label: "This Month",
        getRange: () => [
            moment().startOf("month"),
            moment().endOf("month"),
        ],
    },
    {
        key: "lastMonth",
        label: "Last Month",
        getRange: () => [
            moment().subtract(1, "months").startOf("month"),
            moment().subtract(1, "months").endOf("month"),
        ],
    },
    {
        key: "thisYear",
        label: "This Year",
        getRange: () => [
            moment().startOf("year"),
            moment().endOf("year"),
        ],
    },
];

export const dateRangeTemplatesMap = _.keyBy(dateRangeTemplates, "key");
