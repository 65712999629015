import React, {useState} from 'react';
import {DrawersWrapper} from "../DrawersWrapper";
import {DRAWERS} from "../registry";
import {useDrawerData} from "../../../hooks/drawer";
import {SearchOutline} from "antd-mobile-icons";
import {Input, List} from "antd-mobile";
import Text from "../../components/Text";
import styled from "styled-components";
import {logRender} from "../../../utils";
import { useDrawerActions } from '../../../hooks/drawer';

const drawerId = DRAWERS.BOTTOM.SELECT;

export const BottomSelectPopup = React.memo(({}) => {
    logRender("BottomSelectPopup");
    const [params, visibility] = useDrawerData(drawerId);
    const [s, hidePopup] = useDrawerActions();
    const [searchText, setSearchText] = useState("");

    const {header, data, searchPlaceholder, listData, selectedValue, OnSelect, subLabel} = params || {};

    return <DrawersWrapper id={drawerId} >
        {
            visibility &&

            <>
                <PopUpHeader>
                    <TopLine/>
                    {header}
                </PopUpHeader>
                {data.length > 8 &&
                <div style={{padding: 6, background: "#fff"}}>
                    <div
                        style={{
                            width: "100%",
                            fontSize: 24,
                            verticalAlign: "middle",
                            lineHeight: "30px",
                            borderRadius: 8,
                            background: "#d0caca4a",
                            display: "flex",
                            flexDirection: "row",
                            padding: 4,
                            alignItems: "center",
                        }}
                    >
                        <SearchOutline style={{width: 30, color: "gray"}}/>

                        <Input
                            value={searchText}
                            onChange={(val) => setSearchText(val)}
                            placeholder={searchPlaceholder}
                            style={{
                                width: "100%",
                                fontSize: 17,
                                paddingLeft: 8,
                                border: "none",
                            }}
                            clearable
                        />
                    </div>
                </div>
                }
                <ListContainer>
                    <List>
                        {listData.length > 0 && listData
                            .filter((f) =>
                                (f.label || "")
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                            )
                            .map(
                                (i) =>
                                    i.label && (
                                        <Item selected={i.value === selectedValue}>
                                            <List.Item arrow={false} onClick={() => {OnSelect(i);
                                            hidePopup(drawerId);
                                            }}>
                                                <Text fontWeight={600} content={i.label}/>
                                                {subLabel && (
                                                    <Text
                                                        color="gray"
                                                        fontWeight={600}
                                                        content={subLabel + i.subValue}
                                                    />
                                                )}
                                            </List.Item>
                                        </Item>
                                    )
                            )}
                    </List>
                </ListContainer>
            </>
        }
    </DrawersWrapper>
});


const PopUpHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 12px 12px 0;
  font-size: 20px;
  font-weight: bold;
  border-top-right-radius: 10%;
  flex-direction: column;
`;

const TopLine = styled.div`
  height: 2.5px;
  background: lightgray;
  width: 52px;
  border-radius: 2px;
  margin-bottom: 10px;
`;

const ListContainer = styled.div`
  max-height: 40vh;
  overflow-y: scroll;
  margin-top: 5px;
  border-top: 2px solid #d0caca4a;
`;

const Item = styled.div`
  background: ${({ selected }) => selected && "#d0caca4a"};
`;

