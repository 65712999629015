import React, { useState, useEffect } from "react";
import styled from "styled-components";
import _ from "lodash";
import { Tag } from "antd";
import {
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import Activity from "../components/Activity";
import StackActivity from "../components/StackActivity";
import Text from "../components/Text";
import { RUPEE } from "../../models/constants";
import { beautifyAmount } from "../../utils";
import { fetchOrders } from "../../services/api";

const OrderList = ({ onCancel }) => {
  const [records, setRecords] = useState([]);
  const [activeFilter, setActiveFilter] = useState("all");
  const [filteredList, setFilteredList] = useState([]);
  const [showOrder, setShowOrder] = useState(false);
  const [singleOrder, setSingleOrder] = useState();

  const getOrders = async () => {
    try {
      const response = await fetchOrders();
      const orders = _.get(response, "data.data.orders", []);
      setRecords([...orders]);
      setFilteredList([...orders]);
    } catch (e) {
      console.log(e, "Error in getting orders");
    }
  };

  const setOrderData = (data) => {
    setSingleOrder(data);
    setShowOrder(true);
  };

  const onUpdateStatus = () => {
    setActiveFilter("all");
  };

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    const filterData = () => {
      if (activeFilter === "all") {
        setFilteredList(records);
      } else {
        setFilteredList(records.filter((i) => i.status === activeFilter));
      }
    };

    filterData();
  }, [activeFilter, records]);

  const orderStatus = {
    pending: {
      icon: <ClockCircleOutlined />,
      color: "warning",
    },
    cancelled: {
      icon: <CloseCircleOutlined />,
      color: "error",
    },
    completed: {
      icon: <CheckCircleOutlined />,
      color: "success",
    },
  };

  const OrderCard = (record) => {
    return (
      <CardContainer onClick={() => setOrderData(record)}>
        <InnerContainer width="60%">
          <Text
            fontSize={20}
            fontWeight={600}
            content={record?.customerDetails?.name}
          />
          <Text
            fontSize={16}
            color="grey"
            content={
              "Order Id : " + (record.hashId ? "KB-" + record.hashId : "--")
            }
          />
        </InnerContainer>
        <InnerContainer width="40%" flexEnd>
          <Text
            fontSize={16}
            color="green"
            fontWeight={600}
            content={RUPEE + beautifyAmount(record.amount || 0)}
          />

          <span style={{ marginRight: "-8px" }}>
            <Tag
              color={orderStatus[record.status]?.color}
              icon={orderStatus[record.status]?.icon}
            >
              {record.status
                ? record.status.charAt(0).toUpperCase() + record.status.slice(1)
                : ""}
            </Tag>
          </span>
        </InnerContainer>
      </CardContainer>
    );
  };
  return (
    <>
      <Activity
        heading="Orders"
        onBack={() => {
          onCancel();
        }}
        content={
          <>
            <Header>
              <FilterOption onClick={() => setActiveFilter("all")}>
                <Text
                  fontSize={20}
                  fontWeight={600}
                  content="All"
                  color={activeFilter === "all" ? "#0080ff" : "black"}
                />
              </FilterOption>
              <FilterOption left onClick={() => setActiveFilter("pending")}>
                <Text
                  fontSize={20}
                  fontWeight={600}
                  content="Pending"
                  color={activeFilter === "pending" ? "#0080ff" : "black"}
                />
              </FilterOption>
              <FilterOption left onClick={() => setActiveFilter("completed")}>
                <Text
                  fontSize={20}
                  fontWeight={600}
                  content="Completed"
                  color={activeFilter === "completed" ? "#0080ff" : "black"}
                />
              </FilterOption>
              <FilterOption left onClick={() => setActiveFilter("cancelled")}>
                <Text
                  fontSize={20}
                  fontWeight={600}
                  content="Cancelled"
                  color={activeFilter === "cancelled" ? "#0080ff" : "black"}
                />
              </FilterOption>
            </Header>
            <OuterContainer>
              {filteredList.map((data) => OrderCard(data))}
            </OuterContainer>
          </>
        }
      />
      <StackActivity
        visible={showOrder}
        setVisible={setShowOrder}
        type="order"
        data={singleOrder}
        onSave={onUpdateStatus}
      />
    </>
  );
};

export default OrderList;

const OuterContainer = styled.div`
  padding: 15px;
  margin-top: 50px;
`;

const Header = styled.div`
  display: flex;
  padding: 10px;
  position: absolute;
  background: white;
`;

const FilterOption = styled.div`
  border-left: ${({ left }) => left && "2px solid #0080ff"};
  padding: 0px 10px 0px;
`;

const CardContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 20px;
  border: 2px solid lightgray;
  margin: 0px 0px 21px;
  padding: 5px 15px;
  gap: 10px;
  background: #f7f7f7;
`;

const InnerContainer = styled.div`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  align-items: ${({ flexEnd }) => flexEnd && "flex-end"};
  justify-content: space-between;
`;
