import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {formulateItems, logEffect, logRender} from "../../../utils";
import {Col, Form, Row} from "antd";
import _ from "lodash";
import {Button, Empty, List} from "antd-mobile";
import {PRIMARY_COLOR} from "../../../models/constants";
import {AddCircleOutline} from "antd-mobile-icons";
import {ELayerTrigger, ELayerType, useLayersFramework} from "../../v2framework/framework.hooks";
import {EnumLayer} from "../../v2framework/layers.list";

const setSelectedItemInForm = () => {};

export const ItemsComponent = React.memo(({ value, onChange, dbItemsMap, isIgst }) => {
    logRender("ItemsComponent", value);
    if (!value) {
        value = [];
    }

    const [itemLineForm] = Form.useForm();
    const {pushInAppLayer} = useLayersFramework();


    const { tableItems } = useMemo(() => {
        return formulateItems(value, dbItemsMap, isIgst);
    }, [value, dbItemsMap, isIgst]);


    const openItemLineModal = (defaultItemObj, onSave, onDelete = () => {}) => {
        const isNew = !defaultItemObj;
        if (isNew) {
            defaultItemObj = { qty: 1, priceType: "inc", price: 0 };
        }
        const selectedItem = dbItemsMap[defaultItemObj._id] || {};
        setSelectedItemInForm(selectedItem);
        itemLineForm.resetFields();
        itemLineForm.setFieldsValue(defaultItemObj);

        const OnAddItemSave = (type, data) => {
            if (type === "delete") {
                return onDelete();
            } else if (type === "save") {
                console.log("## Saving it ##", type, data, dbItemsMap[data._id]);
                if (!data.taxRate) { // fixed below toDo (now, taxRate already set from caller)
                    data.taxRate = dbItemsMap[data._id]?.taxRate || 0; // tax rate for newly added item won't reflect here // ToDo - move this logic to anywhere else
                }

                return onSave(data);
            }
        };


        pushInAppLayer({
            trigger: ELayerTrigger.IN_APP,
            type: ELayerType.FULL_SCREEN,
            target: EnumLayer.ADD_ITEM_LINE,
        }, {
            addItemLineArgs: {
                onSave: OnAddItemSave,
                data: defaultItemObj,
            }
        });
    };



    return (
        <>
        <div style={{ border: "1px solid lightgray",
        padding: "10px",
        background: "#f2f7f3",
        borderRadius: "10px",
        marginTop: "10px" }}>
            <Row>
                <Col span={12}>
                    <h3 style={{ color: "#737171" }} >Billed Items</h3>
                </Col>
                <Col span={12}></Col>
            </Row>

            <List
                style={{ width: "100%" }}
                // pagination={false}
                // style={{fontWeight: 'bold'}}
                // columns={itemsColumns}
                // dataSource={[..tableItems, {editMode: true, newMode: true}]}
            >
                {!tableItems.length && (
                    <Empty description="No items added in invoice yet" />
                )}
                {tableItems.map((row, index) => (
                    <List.Item
                        style={{
                            width: "100%",
                            fontSize: 12,
                        }}
                        clickable={false}
                        onClick={() => {
                            const newValue = _.cloneDeep(value);
                            const currentItem = newValue[index];
                            openItemLineModal(
                                currentItem,
                                (record) => {
                                    console.log("## Saving record ##", record);
                                    // record.taxRate = dbItemsMap[record._id]?.taxRate || 0;
                                    _.assign(currentItem, record);

                                    onChange(_.cloneDeep(newValue));
                                },
                                () => {
                                    // hideModal(); // ToDo
                                    const newValue = _.cloneDeep(value);
                                    newValue.splice(index, 1);
                                    onChange(newValue);
                                }
                            );
                        }}
                    >
                        <Row>
                            <Col span={18} style={{ fontWeight: "bold" }}>
                                #{index + 1}&nbsp;{row.name}
                            </Col>
                            <Col
                                span={6}
                                style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    color: PRIMARY_COLOR,
                                }}
                            >
                                ₹ {row.total}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>Item Subtotal</Col>
                            <Col span={16} style={{ textAlign: "right" }}>
                                {row.qty} x {row.price} = {row.taxable}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>GST @{row.taxRate}%</Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                                ₹ {row.totalTax}
                            </Col>
                        </Row>
                        <div style={{ color: "grey" }}>
                            {row.hsn && (
                                <>
                                    HSN: {row.hsn}
                                    <br />
                                </>
                            )}
                            {row.description && <>Description: {row.description}</>}
                        </div>
                    </List.Item>
                ))}
            </List>

            <center>
                <Button
                    color={"primary"}
                    style={{ marginTop: 8, borderRadius: 100 }}
                    onClick={() =>
                        openItemLineModal(undefined, (record) => {
                            console.log("## saving new record ##", record);
                            const newValue = _.cloneDeep(value);
                            newValue.push(record);
                            onChange(_.cloneDeep(newValue));
                        })
                    }
                >
                    <AddCircleOutline />
                    &nbsp;Add Item Line
                </Button>
            </center>


        </div>

            </>

            );
});