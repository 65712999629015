import React from 'react';
import {DrawersWrapper} from "../DrawersWrapper";
import {DRAWERS} from "../registry";
import {useDrawerData} from "../../../hooks/drawer";

const drawerId = DRAWERS.BOTTOM.SAMPLE;

export const BottomSample = React.memo(({}) => {
    const [params] = useDrawerData(drawerId);

    return <DrawersWrapper id={drawerId} >
        Test Data in Sample Bottom Popup
        <br/>
        {params}
    </DrawersWrapper>
})