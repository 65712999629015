import React from 'react';
import {BottomSample} from "./bottom/Sample";
import {CreateFabBottom} from "./bottom/CreateFabBottom";
import { ItemsList } from './bottom/ItemsList';
import {BottomSelectPopup} from "./bottom/BottomSelectPopup";
import {Z_INDEXES} from "../../styles/js/z_indexes";

export const DrawerComponents = React.memo(({}) => {
    return (
        <>
            <BottomSample/>
            <CreateFabBottom/>
            <div style={{zIndex: Z_INDEXES.ITEMS_LIST_DRAWER}}>
                <ItemsList/>
            </div>
            <BottomSelectPopup/>
        </>
    )
});