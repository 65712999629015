export const Z_INDEXES = {
    DEFAULT: 1,
    MAX: 999999,

    ADD_ITEM_LINE: 50,
    ITEMS_LIST_BOTTOM_DRAWER: 7000,

    ITEM_SAVE: 90000,
    ITEM_SAVE_SELECT_UNIT: 10000,
    ITEMS_LIST_DRAWER: 61,


}