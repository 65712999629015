import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {customDispatcher, logRender} from "../../../../utils";
import StackActivity from "../../../components/StackActivity";
import {useLayersFramework} from "../../../v2framework/framework.hooks";

const DashboardPayInOut = () => {
    logRender("DashboardPayInOut");
  const state = useSelector((state) => state);
  const dispatch = customDispatcher(useDispatch());
  const {params} = useLayersFramework();
  const {payType} = params || {};

  return (
    <>
      <StackActivity
        visible={true}
        setVisible={() => dispatch({ ...state, showPayIn: false })}
        type={ payType==="receipt" ? "payIn" : "payOut"}
      />

      {/*<StackActivity*/}
      {/*  visible={state?.showPayOut}*/}
      {/*  setVisible={() => dispatch({ ...state, showPayOut: false })}*/}
      {/*  type="payOut"*/}
      {/*/>*/}
    </>
  );
};

export default DashboardPayInOut;
