import React from "react";
import {Image, NavBar} from "antd-mobile";
import logoImg from "../../res/img/logoSquareV3.png";
import {Z_INDEXES} from "../../styles/js/z_indexes";

const CustomNavBar = ({children}) => {
    return (
        <NavBar
            backArrow={false}
            style={{
                background: 'white',
                color: "black",
                height: "56px",
                width: "100%",
                fontWeight: 'bold',
                borderBottom: '1px solid lightgrey',
                boxShadow: '0px 0px 17px -3px rgba(97,94,97,1)',
                position: "fixed",
                zIndex: Z_INDEXES.DEFAULT,
                top: 0,
            }}
            left={children}
            right={
                <Image style={{width: 30, height: 30}} src={logoImg}/>
            }
        />
    )
        ;
}

export default CustomNavBar;