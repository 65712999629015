import {useCustomDispatch} from "../../hooks/generic";
import {EDispatchType} from "../../services/store";
import {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {logRender} from "../../utils";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {parseRoutingUrl} from "./routingHelper";
import {EnumLayer} from "./layers.list";

const FALLBACK_IN_APP_LAYERS = [];
const FALLBACK_LAYER_ARGS = {};

function useInAppLayers() {
    const dispatch = useCustomDispatch(EDispatchType.IN_APP_LAYER);
    const currentInAppLayers = useSelector(state => state.currentInAppLayers) || FALLBACK_IN_APP_LAYERS;

    const popInAppLayer = useCallback(() => {
        dispatch({
            action: "pop",
        })
    }, [dispatch]);

    const pushInAppLayer = useCallback((layer, args) => {
        dispatch({
            action: "push",
            value: layer,
            args,
        })
    }, [dispatch]);

    const removeLayer = useCallback((layer) => {
        dispatch({
            action: "remove",
            value: layer,
        });
    }, [dispatch]);

    return {
        currentInAppLayers,
        popInAppLayer,
        pushInAppLayer,
        removeLayer
    }
}

const FALLBACK_ACTIVITIES = [];


export function useMyParams() {
    // const activities = useSelector(state => state.activities) || FALLBACK_ACTIVITIES;
    // const {pushActivity} = useActivityFramework();
    const params = useParams();
    const {stack, paramValuesMap} = parseRoutingUrl(params?.["*"] || "");

    // useEffect(() => {
    //
    // })

    // useEffect(() => {
    //     console.log("## Pushing Main Activity ##");
    //     pushActivity(EActivity.MAIN);
    // }, [pushActivity]);

    return [stack, paramValuesMap];
}



/*
    Requirement:

    goBack,
    currentLayers,
    pushInAppLayer,
    params,
    args,

    Layer::
    trigger: URL / IN_APP
    type: FULL_SCREEN / FRAGMENT
    target: string

 */

export const ELayerTrigger = {
    URL: "URL",
    IN_APP: "IN_APP",
}

export const ELayerType = {
    FULL_SCREEN: "FULL_SCREEN",
    FRAGMENT: "FRAGMENT",
}

export function useLayersFramework() {
    const urlParams = useParams();
    const navigate = useNavigate();
    const {currentInAppLayers, pushInAppLayer, popInAppLayer} = useInAppLayers();
    const args = useSelector(state => state.layerArgs) || FALLBACK_LAYER_ARGS;
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const {stack: urlActivities, paramValuesMap: params} = parseRoutingUrl(urlParams?.["*"] || "");

    const currentLayers = urlActivities.map(activity => ({
        trigger: ELayerTrigger.URL,
        type: ELayerType.FULL_SCREEN,
        target: activity,
    })).concat(currentInAppLayers);

    let foundLastScreen = false;
    for (let i = currentLayers.length-1; i>=0 ; i--) {
        const layer = currentLayers[i];
        if (foundLastScreen) {
            layer.shouldDisplay = false;
        } else {
            layer.shouldDisplay = true;
            if (layer.type === ELayerType.FULL_SCREEN) {
                foundLastScreen = true;
            }
        }
    }


    const location = useLocation();

    const goBack = useCallback(() => {
        // console.log("## going back ##", urlActivities, params);
        if (currentInAppLayers.length) {
            popInAppLayer();
            return true;
        }

        const terminatingTabs = [EnumLayer.LAST_STEP, EnumLayer.LOGIN, EnumLayer];
        const lastLayer = urlActivities[urlActivities.length - 1];
        if (
            terminatingTabs.includes(lastLayer) ||
            (lastLayer === EnumLayer.HOME && params.currentHomeTab === "dashboard")
        ) {
            // can't go back
            return false;
        }

        navigate(-1);
        return true;
    }, [currentInAppLayers, popInAppLayer, navigate, params]);

    window.onBackV2 = goBack;

    return {
        currentLayers,
        goBack,
        pushInAppLayer,
        params,
        args,
        setDropdownOpen
    }
}

