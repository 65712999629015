import React, { useState } from "react";
import { Popup, List, Input, Radio } from "antd-mobile";
import styled from "styled-components";
import { Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { SearchOutline } from "antd-mobile-icons";
import { useSelector, useDispatch } from "react-redux";
import { customDispatcher } from "../../utils";
import { saveUser, loginRequest, baseUrl } from "../../services/api";
import {appConfig} from "../../config";
import {useLayersFramework} from "../v2framework/framework.hooks";

export const SwitchCompanyLayer = React.memo(({}) => {
  const state = useSelector((state) => state);
  const dispatch = customDispatcher(useDispatch());
  const {goBack} = useLayersFramework();
  const [showPopup, setShowPopup] = useState(true);
  const [searchText, setSearchText] = useState("");

  const OnSelect = async (data) => {
    await saveUser({
      user: {
        _id: state?.user?._id,
        company: data._id,
      },
    });
    await loginRequest("", "", dispatch);
    goBack();
    setShowPopup(false);
  };

  return (
    <>
      <DropdownContainer onClick={() => setShowPopup(true)}>
        <DownOutlined style={{ color: "gray" }} />
      </DropdownContainer>
      <Popup
        visible={showPopup}
        onMaskClick={() => {
          goBack();
          setShowPopup(false);
        }}
        bodyStyle={{
          width: "100vw",
          borderTopRightRadius: "5%",
          borderTopLeftRadius: "5%",
        }}
      >
        {
          <>
            <PopUpHeader>
              <TopLine />
              Switch Company
            </PopUpHeader>
            {
              <div style={{ padding: 6, background: "#fff" }}>
                <div
                  style={{
                    width: "100%",
                    fontSize: 24,
                    verticalAlign: "middle",
                    lineHeight: "30px",
                    borderRadius: 8,
                    background: "#d0caca4a",
                    display: "flex",
                    flexDirection: "row",
                    padding: 4,
                    alignItems: "center",
                  }}
                >
                  <SearchOutline style={{ width: 30, color: "gray" }} />

                  <Input
                    value={searchText}
                    onChange={(val) => setSearchText(val)}
                    placeholder="Search with Company name"
                    style={{
                      width: "100%",
                      fontSize: 17,
                      paddingLeft: 8,
                      border: "none",
                    }}
                    clearable
                  />
                </div>
              </div>
            }
            <ListContainer>
              <List>
                {state?.user?.companies
                  .filter((f) =>
                    (f.name || "")
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  )
                  .map(
                    (i) =>
                      i.name && (
                        <Item selected={i.name === state?.company?.name}>
                          <List.Item arrow={false}>
                            <Radio
                              checked={i.name === state?.company?.name}
                              onChange={() => OnSelect(i)}
                              value="1"
                            >
                              <img
                                src={
                                  i.imgLogo
                                    ? `${baseUrl}/internal/file/${i.imgLogo}`
                                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSi33sxKaUgj-fMZEkgeXQ3V6kYcZPZCgU2q_xhY3GwgoiV1wNq01NkzUI_6TX6spmxzaw&usqp=CAU"
                                }
                                alt=" "
                                style={{
                                  height: "15mm",
                                  width: "15mm",
                                  marginRight: "8px",
                                }}
                              />
                              {i.name}
                            </Radio>
                          </List.Item>
                        </Item>
                      )
                  )}
              </List>
            </ListContainer>
              {
                  appConfig.enableAddMoreBusinessBtn &&
                  <ButtonContainer>
                      <Button
                          type="primary"
                          style={{
                              width: "100%",
                              fontSize: "20px",
                              height: "50px",
                              background: "#0080FF",
                          }}
                          // onClick={ "ToDo" }
                      >
                          Want to add a New Business?
                      </Button>
                  </ButtonContainer>
              }
          </>
        }
      </Popup>
    </>
  );
});

const DropdownContainer = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid #8080804a;
  justify-content: space-between;
  padding: 4px 9px;
  align-items: center;
  height: 36px;
  border-radius: 10px;
  pointer-events: ${({ disabled }) => disabled && "none"};
  background-color: ${({ disabled }) => disabled && "#d0caca4a"};
`;

const PopUpHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 12px 12px 0;
  font-size: 20px;
  font-weight: bold;
  border-top-right-radius: 10%;
  flex-direction: column;
`;

const ListContainer = styled.div`
  max-height: 38vh;
  overflow-y: scroll;
  margin-bottom: 52px;
`;

const Item = styled.div`
  background: ${({ selected }) => selected && "#d0caca4a"};
`;

const ButtonContainer = styled.div`
  width: 100%;
  bottom: -4px;
  position: absolute;
  height: 55px;
`;

const TopLine = styled.div`
  height: 2.5px;
  background: lightgray;
  width: 52px;
  border-radius: 2px;
  margin-bottom: 10px;
`;
