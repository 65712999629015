import React, { useState } from "react";
import styled from "styled-components";
import Activity from "../../../components/Activity";
import { applyCoupon } from "../../../../services/api";
import { Input } from "antd-mobile";
import Text from "../../../components/Text";
import { message } from "antd";

const Coupons = ({ data, onSave = () => {}, onCancel = () => {} }) => {
  const planType = data && data.planType;
  const duration = data && data.duration;

  const [couponCode, setCouponCode] = useState("");

  const applyCouponAction = async (code) => {
    const resp = await applyCoupon({ code, plan: planType, duration });
    if (resp.data.success) {
      onSave(resp.data, code);
      onCancel();
    } else {
      message.error("Invalid Coupon Code!");
    }
  };

  return (
    <Activity
      heading="Apply Coupon"
      onBack={() => onCancel()}
      content={
        <>
          <HeaderContainer>
            <InputContainer>
              <Input
                placeholder="Enter Coupon Code..."
                onChange={(value) => setCouponCode(value)}
                style={{
                  marginBottom: "-8px",
                  paddingBottom: "10px",
                }}
              />
              <Apply onClick={() => applyCouponAction(couponCode)}>Apply</Apply>
            </InputContainer>
            <Text
              content="More offers"
              fontSize={18}
              fontWeight={500}
              color="#0080ff"
            />
          </HeaderContainer>
          <AvailableCoupons>
            {data.coupons &&
              data.coupons.map((i) => (
                <Container key={i._id}>
                  <TopSection>
                    <Coupon>{i.code}</Coupon>
                    <Apply onClick={() => applyCouponAction(i.code)}>
                      Apply
                    </Apply>
                  </TopSection>
                  {i.description}
                </Container>
              ))}
          </AvailableCoupons>
        </>
      }
    />
  );
};

export default Coupons;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 2px solid #d0caca4a;
  margin: 10px 0px;
`;

const Coupon = styled.div`
  border: 2px dotted green;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0px 10px;
  border-radius: 6px;
  background: #f6ffed;
  color: green;
`;

const Container = styled.div`
  border: 2px solid #d0caca4a;
  box-shadow: 0px 0px 12px -3px rgba(97, 94, 97, 1);
  padding: 5px 10px;
  margin: 20px;
  font-size: 16px;
`;

const Apply = styled.div`
  font-size: 16px;
  color: #0080ff;
  font-weight: 500;
  :hover {
    font-weight: 600;
    color: #1457C6;
  }
`;

const InputContainer = styled.div`
  font-size: 18px;
  letter-spacing: 0.28px;
  line-height: 17px;
  height: 40px;
  border: 1px solid #8080804a;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 15px;
  width: 90%;
  gap: 10px;
`;

const HeaderContainer = styled.div`
  height: 105px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  background: white;
  width: 100%;
`;

const AvailableCoupons = styled.div`
  margin-top: 115px;
`;
