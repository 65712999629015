import React, { useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import { Button } from "antd";
import Text from "../../../components/Text";
import BottomPopup from "../../../components/BottomPopup";
import StackActivity from "../../../components/StackActivity";
import CustomNavBar from "../../../components/MyNavBar";
import { useSelector } from "react-redux";

import {
  WhatsAppOutlined,
  LineChartOutlined,
  GatewayOutlined,
  HighlightOutlined,
  ShopOutlined,
  IdcardOutlined,
  CustomerServiceOutlined,
  DownOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { logRender, truncate } from "../../../../utils";
import { STYLE_CONSTANTS } from "../../../../styles/js/styleConstants";
import { shareOnWhatsapp } from "../../../../utils/whatsapp";
import { BannersCarousel } from "./BannersCarousel";
import { SummaryBlock } from "./SummaryBlock";
import { Container, Header, InnerContainer } from "./styled";
import { viewList } from "./vouchersList";
import { useDrawerActions } from "../../../../hooks/drawer";
import { DRAWERS } from "../../../drawers/registry";
import CustomFAB from "../../../components/CustomFAB";
import {useNavigate} from "react-router-dom";
import {CreateFabBottom} from "../../../drawers/bottom/CreateFabBottom";
import {ELayerTrigger, ELayerType, useLayersFramework} from "../../../v2framework/framework.hooks";
import {EnumLayer} from "../../../v2framework/layers.list";
import DashboardPayInOut from "./DashboardPayInOut";
import {useCustomDispatch} from "../../../../hooks/generic";

const featuresList = [
  {
    label: "Invoice Templates",
    Icon: HighlightOutlined,
    path: "/invoiceTemplates",
  },
  {
    label: "Manage Inventory",
    Icon: LineChartOutlined,
    path: "/items",
  },
  // {
  //   label: "Payment Gateway",
  //   Icon: GatewayOutlined,
  //   path: "/settings/payment",
  // },
  // {
  //   label: "Online Store",
  //   Icon: ShopOutlined,
  //   path: "/ecommerce",
  // },
  // {
  //   label: "Business Cards",
  //   Icon: IdcardOutlined,
  //   path: "/greetings/business",
  // },
  // {
  //   label: "Whatsapp Status",
  //   Icon: WhatsAppOutlined,
  //   path: "/greetings/whatsapp",
  // },
  {
    label: "Help & Support",
    Icon: CustomerServiceOutlined,
    onClick: async () => {
      shareOnWhatsapp({
        message: "Hi, I need help in KhataBuddy mobile app",
        whatsAppNumber: "918700260932",
      });
    },
  },
  {
    label: "Invoice Settings",
    Icon: SettingOutlined,
    path: "/settings/invoice",
  },
];

const CompanyName = React.memo(() => {
  logRender("CompanyName");
  const companyName = useSelector((state) => state?.company?.name);
  return <>{truncate(companyName || "Your Company Name", 20)}</>;
});

export const DashboardTab = React.memo(() => {
  logRender("Dashboard");
  const navigate = useNavigate();
  const [showUpgradePlan, setShowUpgradePlan] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showPopup] = useDrawerActions();
  const {pushInAppLayer} = useLayersFramework();
  const dispatch = useCustomDispatch();
    const state = useSelector((state) => state);

  const showCreateOptions = () => {
      console.log("## FAB CREATE ##");
    return showPopup(DRAWERS.BOTTOM.CREATE_FAB);
  };

  const UpgradePlanContent = (
    <>
      <PopUpHeader>
        <TopLine />
        Upgrade Plan
      </PopUpHeader>
      <UpgradeContainer>
        <Text
          fontSize={18}
          fontWeight={600}
          content="This is part of our premium plans. Upgrade today and start using!"
        />
        <MidContainer>
          <Text
            fontSize={16}
            fontWeight={500}
            color="grey"
            content="Feel free to reach us at +91 87002 60932. Would really love to hear your feedback."
          />
        </MidContainer>
        <ButtonContainer>
          <Button
            type="primary"
            style={{
              width: "40%",
              fontSize: "20px",
              height: "45px",
              background: "#0080ff",
              borderRadius: "5px",
            }}
          >
            Contact Us
          </Button>
          <Button
            type="primary"
            style={{
              width: "45%",
              fontSize: "20px",
              height: "45px",
              background: "green",
              borderRadius: "5px",
            }}
            // onClick={"Todo"}
          >
            Upgrade Plan
          </Button>
        </ButtonContainer>
      </UpgradeContainer>
    </>
  );
  
//onTouchEnd={() => showCreateOptions()} 
  return (
    <div style={{ background: STYLE_CONSTANTS.defaultBackgroundColor }}>
      {/*<div onClick={() => showCreateOptions()}>*/}
      {/*  <CustomFAB title={"Create"} Bottom={true} />*/}
      {/*</div>*/}
      <CustomNavBar>
          <NavbarContent
              style={{ fontWeight: "bold" }}
              onClick={() =>  {
                  pushInAppLayer({
                      type: ELayerType.FRAGMENT,
                      trigger: ELayerTrigger.IN_APP,
                      target: EnumLayer.SWITCH_COMPANY,
                  })
              }}
          >
              <CompanyName />
              <DownOutlined style={{ marginLeft: "10px", fontSize: "18px" }} />
          </NavbarContent>
      </CustomNavBar>
      <OuterContainer>
        <BannersCarousel />
        <Container
            onClick={() => {
                console.log("## Container click ##");
                // goBack();
            }}
          padding={"0px"}
          shadow
          column
          noGap
        >
          <Header
            style={{
              border: "0px solid black",
              padding: 12,
              paddingLeft: 16,
              paddingRight: 16,
              paddingBottom: 4,
            }}
          >
            <InlineContainer>
              <Text
                fontSize={16}
                fontWeight={600}
                color="#333"
                content="Summary (Last 3 Months)"
              />
            </InlineContainer>
          </Header>
          <Container
            noGap
            style={{ border: "0px solid red", paddingBottom: 12 }}
            padding={"0px"}
          >
            <SummaryBlock />
          </Container>
        </Container>

        <Container
          shadow
          start
          column
          noGap
        >
          <Text
            fontSize={18}
            fontWeight={600}
            content="Create New"
            style={{
              textAlign: "center",
              width: "50%",
              marginLeft: "25%",
              borderBottom: "0.5px solid #aaa",
            }}
          />
          {_.chunk(
            viewList.map(({ createLabel, viewLabel, createPath, Icon }) => (
              <InnerContainer
                width="25%"
                className={"primaryLightHoverEffect"}
                alignCenter={true}
                onClick={() => {
                    console.log("## Clicked Create ##", viewLabel);
                    if (createLabel === "Payment---") {
                        dispatch({...state, showPayIn: true});
                    } else if (createLabel === "Expense---") {
                        dispatch({...state, showPayOut: true});
                    } else if (createPath) {
                        navigate(createPath);
                    }
                }}
              >
                <Icon style={{ color: "#0080ff", fontSize: 25 }} />
                <Text fontSize={12} fontWeight={500} content={createLabel} />
              </InnerContainer>
            )),
            4
          ).map((row, rowIndex) => (
            <Container top={rowIndex === 0}>{row}</Container>
          ))}
        </Container>

        <Container shadow start column noGap style={{ background: "white" }}>
          <Text
            fontSize={18}
            fontWeight={600}
            content="Features"
            style={{
              textAlign: "center",
              width: "50%",
              marginLeft: "25%",
              borderBottom: "0.5px solid #aaa",
            }}
          />
          {_.chunk(
            featuresList.map(({ label, onClick, Icon, path }) => (
              <InnerContainer
                width="25%"
                className={"primaryLightHoverEffect"}
                alignCenter={true}
                onClick={() => {
                  if (onClick) {
                    onClick();
                  }
                  if (path) {
                    navigate(path);
                  }
                }}
              >
                <Icon style={{ color: "#0080ff", fontSize: 25 }} />
                <Text fontSize={12} fontWeight={500} content={label} />
              </InnerContainer>
            )),
            4
          ).map((row, rowIndex) => (
            <Container top={rowIndex === 0}>{row}</Container>
          ))}
        </Container>
        <StackActivity
          visible={showUpgradeModal}
          setVisible={setShowUpgradeModal}
          type="upgradePlan"
        />
        <BottomPopup
          content={UpgradePlanContent}
          height="330px"
          visible={showUpgradePlan}
          setVisible={setShowUpgradePlan}
        />
      </OuterContainer>
        {/*<CreateFabBottom/>*/}
    </div>
  );
});

const NavbarContent = styled.div`
  font-size: 20px;
  font-weight: normal;
`;

const InlineContainer = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: ${({ pointer }) => pointer && "pointer"};
`;

const OuterContainer = styled.div`
  padding: 16px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 56px;
`;

const PopUpHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 12px 12px 0;
  font-size: 20px;
  font-weight: 600;
  border-top-right-radius: 10%;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const MidContainer = styled.div`
  padding-top: 10px;
  border-top: 2px solid lightgrey;
`;

const TopLine = styled.div`
  height: 2.5px;
  background: lightgray;
  width: 52px;
  border-radius: 2px;
  margin-bottom: 10px;
`;

const UpgradeContainer = styled.div`
  max-height: 38vh;
  overflow-y: scroll;
  padding: 15px;
  border-top: 2px solid lightgrey;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;
