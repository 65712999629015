import React, {useEffect, useState} from "react";
import {Alert} from "antd";
import {useSelector} from "react-redux";
import {Button, Grid, NavBar, Form, Input} from "antd-mobile";
import {saveUser} from "../../../../services/api";
import ActivityWrapper from "../../../components/Activity";
import {useLayersFramework} from "../../../v2framework/framework.hooks";
import { MobileButtonV2 } from "../../../components/MobileButtonV2";


const PasswordSettings = () => {
    const state = useSelector(state => state);
    const [form] = Form.useForm();
    const [res, setRes] = useState();
    const {goBack} = useLayersFramework();

    useEffect(() => {
        form.setFieldsValue({
            subject: 'Facing an Issue'
        });
    }, [form]);

    return (
        <ActivityWrapper
            heading={"Change Password"}
            footer={
                <Grid columns={2} style={{width: '100%', height: '100%', border: '0px solid orange'}}>
                    <Grid.Item>
                        <Button style={{borderRadius: 0, width: '100%', height: '100%'}} color={"danger"}
                                onClick={() => {
                                    goBack();
                                }}
                                disabled={res?.type === "success"}
                        >
                            Cancel
                        </Button>
                    </Grid.Item>
                    <Grid.Item>
                        <MobileButtonV2 style={{borderRadius: 0, width: '100%', height: '100%'}} color={"primary"}
                                disabled={res?.type === "success"}
                                onClick={async () => {
                                    const values = await form.validateFields();
                                    console.log("## Vals ##", values);
                                    if (values.currentPass !== state?.user.password) {
                                        setRes({
                                            type: "error",
                                            msg: "Incorrect current password"
                                        });
                                        return;
                                    }
                                    if (values.newPass !== values.confirmPass) {
                                        setRes({
                                            type: "error",
                                            msg: "New Password & Confirm New Password don't match"
                                        });
                                        return;
                                    }

                                    form.resetFields();
                                    await saveUser({
                                        user: {
                                            _id: state?.user?._id,
                                            password: values.newPass
                                        }
                                    });

                                    setRes({
                                        type: "success",
                                        msg: "Password Changed Successfully"
                                    });

                                }}>
                            Update
                        </MobileButtonV2>
                    </Grid.Item>
                </Grid>
            }
            content={
                <div>
                    <Form form={form}>
                        <Form.Item name={"currentPass"} label={"Current Password"} rules={[{required: true, message: "can't be blank"}]}>
                            <Input type={"password"} maxLength={20} placeholder={"Current Password"}/>
                        </Form.Item>
                        <Form.Item name={"newPass"} label={"New Password"}
                                   rules={[{required: true, message: "can't be blank"}]}>
                            <Input type={"password"} maxLength={20} placeholder={"New Password"}/>
                        </Form.Item>
                        <Form.Item name={"confirmPass"} label={"Confirm New Password"}
                                   rules={[{required: true, message: "can't be blank"}]}>
                            <Input type={"password"} maxLength={20} placeholder={"Confirm New Password"}/>
                        </Form.Item>
                    </Form>

                    <br/><br/>
                    {
                        res &&
                        <div style={{padding: 8}}>
                            <Alert
                                message={res.msg}
                                type={res.type} showIcon={true}/>
                        </div>
                    }
                </div>
            }
        />
    )

};

export default PasswordSettings;
