import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { DatePicker, Form, InputNumber, Row } from "antd";
import { getLedgers, saveVoucher } from "../../../../services/api";
import { sleep, errorMessage } from "../../../../utils";
import Activity from "../../../components/Activity";
import { Tabs } from "antd-mobile";
import BottomSelect from "../../../components/BottomSelect";
import TextArea from "antd/lib/input/TextArea";
import { RUPEE } from "../../../../models/constants";
import { DateUI } from "../../voucherWrite/DateUI";
import { isMobileApp } from "../../../../utils/webviewUtils";
import { useDispatch, useSelector } from "react-redux";
import { customDispatcher } from "../../../../utils";
import { Loading } from "antd-mobile";

const PayOut = ({ onCancel, vendor, onSave = () => {} }) => {
  const state = useSelector((state) => state);
    const dispatch = customDispatcher(useDispatch());
  const [form] = Form.useForm();


  const [saving, setSaving] = useState(false);
  const [ledgers, setLedgers] = useState([]);
  const [entryType, setEntryType] = useState("Expense");

  useEffect(() => {
    if (state?.company) {
      getLedgers("all").then((resp) => setLedgers(resp.data.data.ledgers));
    }
  }, [state]);

  useEffect(() => {
    form.resetFields();
    if (vendor) {
      form.setFieldsValue({
        // ledgerCr: vendor._id,
        date: moment(),
        paymentMode: vendor.paymentMode || "Cash",
        ledgerDr: entryType === "Expense" ? undefined : vendor._id,
        privateNote: vendor.privateNote,
        amount: vendor.amount,
        entryMode: vendor.entryMode || "Expense",
        distribution: vendor.distribution,
      });
    } else {
      form.setFieldsValue({
        date: moment(),
        paymentMode: "Cash",
      });
    }
  }, [vendor, form, entryType]);

  const NameOptions = ledgers
    .filter(
      (l) =>
        !l.type || l.type === (entryType === "Expense" ? "expense" : "creditor")
    )
    .map((l) => ({
      value: l._id,
      label: l.name,
      subValue: l.balance,
    }));

  const PaymentOptions = [
    "Cash",
    "UPI",
    "Card",
    "EMI",
    "Bank Transfer",
    "Cheque",
  ].map((mode) => ({
    label: mode,
    value: mode,
  }));

  const PayingAccountOptions = ledgers
    .filter((l) => l.type === "cash")
    .map((l) => ({
      label: l.name,
      value: l._id,
    }));

  const TabChange = () => {
    if (entryType === "Expense") {
      setEntryType("Vendor");
    } else {
      setEntryType("Expense");
    }
  };

  return (
    <Activity
      heading="Payment Out"
      onBack={() => {
        dispatch({...state, showPayOut: false});
        onCancel();
      }}
      content={
        <>
          <FormContainer>
            {ledgers.length === 0 ? 
            <Loading
            style={{
              padding: "37%",
              fontSize: "40px",
              color: "#0080ff",
              height: "60vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
            : <Form
              form={form}
              name="basic"
              labelCol={{
                span: 6,
                style: {
                  textAlign: "left",
                },
              }}
              wrapperCol={{ span: 16 }}
              onFinish={async (data) => {
                data.type = "payment";
                data.entryType = entryType;
                data.distribution = [];
                setSaving(true);
                const response = await saveVoucher({
                  vendor: data,
                });
                onSave();
                await sleep(500);
                if (!response.data.success) {
                  errorMessage(response.data.message);
                  setSaving(false);
                  return;
                }
                setSaving(false);
                onCancel();
                form.resetFields();
                form.setFieldsValue({
                  date: moment(),
                  paymentMode: "Cash",
                });
              }}
              onFinishFailed={(err) => {
                console.log("## Finish Failed ##", err);
              }}
              autoComplete="off"
            >
              <Tabs onChange={TabChange}>
                <Tabs.Tab
                  title={
                    <div style={{ fontSize: 16, fontWeight: "bold" }}>
                      Expense
                    </div>
                  }
                  key="Expense"
                  style={{ fontSize: 10 }}
                >
                      <FormItem
                        wrapperCol={{ span: 15 }}
                        name={"ledgerDr"}
                        label={"Paid to"}
                        rules={[
                          { required: true, message: "Please select customer" },
                        ]}
                      >
                        <BottomSelect
                          header={"Select Ledger Name"}
                          data={NameOptions}
                          searchPlaceholder="Select Ledger"
                          placeholder="Select Ledger"
                          subLabel="Balance : "
                        />
                      </FormItem>
                  <Row>
                  <div style={{ display: "flex", gap: 10, width: "100%", justifyContent: "space-between" }}>
                      <FormItem
                        name="date"
                        label="Payment Date"
                        style={{ width: "45%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please select Date",
                          },
                        ]}
                      >{isMobileApp() ? (
                        <DateUI />
                      ) : (
                        <DatePicker
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "20px",
                          }}
                          format={"DD MMM YYYY"}
                          allowClear={false}
                        />)}
                      </FormItem>
                      <FormItem
                        name="paymentMode"
                        label="Payment Mode"
                        rules={[
                          {
                            required: true,
                            message: "Please select payment mode",
                          },
                        ]}
                      >
                        <BottomSelect
                          header="Payment Mode"
                          data={PaymentOptions}
                          searchPlaceholder="Please select Payment Mode"
                        />
                      </FormItem>
                    </div>
                  </Row>

                  <FormItem
                    name="ledgerCr"
                    label="Paid Account"
                    rules={[{ required: true, message: "Please select" }]}
                  >
                    <BottomSelect
                      header="Account"
                      data={PayingAccountOptions}
                      searchPlaceholder="Select Paying Account"
                      placeholder="Select Paying Account"
                    />
                  </FormItem>
                  <FormItem name={"privateNote"} label={"Private Note"}>
                    <TextArea
                      style={{
                        border: "2px solid #d0caca4a",
                        borderRadius: 12,
                        padding: 4,
                      }}
                      placeholder={"Private note (not visible on receipt)"}
                      autoSize={{ minRows: 2, maxRows: 2 }}
                    />
                  </FormItem>

                  <FormItem
                    name="amount"
                    label="Amount Paid (₹)"
                    rules={[
                      {
                        required: true,
                        message: "Invalid Amount",
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      prefix={`${RUPEE}`}
                      min={1}
                      max={100000000}
                      style={{
                        borderRadius: "10px",
                        width: "100%",
                        height: "40px",
                        fontSize: 18,
                      }}
                    />
                  </FormItem>
                </Tabs.Tab>
                <Tabs.Tab
                  title={
                    <div style={{ fontSize: 16, fontWeight: "bold" }}>
                      Vendor
                    </div>
                  }
                  key="Vendor"
                  style={{ fontSize: 10 }}
                >
                      <FormItem
                        wrapperCol={{ span: 15 }}
                        name={"ledgerDr"}
                        label={"Paid to"}
                        rules={[
                          { required: true, message: "Please select customer" },
                        ]}
                      >
                        <BottomSelect
                          header={"Select Vendor Name"}
                          data={NameOptions}
                          searchPlaceholder="Select Vendor"
                          placeholder="Select Vendor"
                          subLabel="Balance : "
                        />
                      </FormItem>
                  <Row>
                  <div style={{ display: "flex", gap: 10, width: "100%", justifyContent: "space-between" }}>
                      <FormItem
                        name="date"
                        label="Payment Date"
                        style={{ width: "45%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please select Date",
                          },
                        ]}
                      >{isMobileApp() ? (
                        <DateUI />
                      ) : (
                        <DatePicker
                          style={{
                            borderRadius: "10px",
                            height: "36px",
                            fontSize: "20px",
                          }}
                          format={"DD MMM YYYY"}
                          allowClear={false}
                        />)}
                      </FormItem>
                      <FormItem
                        name="paymentMode"
                        label="Payment Mode"
                        rules={[
                          {
                            required: true,
                            message: "Please select payment mode",
                          },
                        ]}
                      >
                        <BottomSelect
                          header="Payment Mode"
                          data={PaymentOptions}
                          searchPlaceholder="Please select Payment Mode"
                        />
                      </FormItem>
                    </div>
                  </Row>

                  <FormItem
                    name="ledgerCr"
                    label="Paid Account"
                    rules={[{ required: true, message: "Please select" }]}
                  >
                    <BottomSelect
                      header="Account"
                      data={PayingAccountOptions}
                      searchPlaceholder="Select Paying Account"
                      placeholder="Select Paying Account"
                    />
                  </FormItem>
                  <FormItem name={"privateNote"} label={"Private Note"}>
                    <TextArea
                      style={{
                        border: "2px solid #d0caca4a",
                        borderRadius: 12,
                        padding: 4,
                      }}
                      placeholder={"Private note (not visible on receipt)"}
                      autoSize={{ minRows: 2, maxRows: 2 }}
                    />
                  </FormItem>

                  <FormItem
                    name="amount"
                    label="Amount Paid (₹)"
                    rules={[
                      {
                        required: true,
                        message: "Invalid Amount",
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      prefix={`${RUPEE}`}
                      min={1}
                      max={100000000}
                      style={{
                        borderRadius: "10px",
                        width: "100%",
                        height: "36px",
                      }}
                    />
                  </FormItem>
                </Tabs.Tab>
              </Tabs>
              <ButtonContainer>
                <button
                  className={
                    "fab primaryEffect generalRoundedButton buttonProperties"
                  }
                  htmlType="submit"
                  loading={saving}
                >
                  Save Payment Record
                </button>
              </ButtonContainer>
            </Form>}
          </FormContainer>
        </>
      }
    />
  );
};

export default PayOut;

const FormItem = styled(Form.Item)`
  margin-bottom: 8px;
`;

const FormContainer = styled.div`
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 70px;
  bottom: -2px;
  position: absolute;
  margin-left: 10px;
  padding-top: 20px;
`;
