import {Carousel} from "antd";
import {baseUrl} from "../../../../services/api";
import {shareOnWhatsapp} from "../../../../utils/whatsapp";
import React from "react";
import {logRender} from "../../../../utils";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

export const BannersCarousel = React.memo(() => {
    logRender("BannersCarousel");
    const appDashboardBanners = useSelector(state => state.appDashboardBanners);
    const navigate = useNavigate();

    if (!appDashboardBanners || !appDashboardBanners.length) {
        return <div></div>;
    }

    return <Carousel autoplay dots={false}>
        {
            appDashboardBanners.map((i) => (
            <CarouselItem>
                <CarouselImage
                    src={`${baseUrl}/internal/file/${i.img}`}
                    height="138px"
                    onClick={() => {
                        if (i.cta?.type === "navigate") {
                            navigate(i.cta.value)  // ToDo infinity
                        } else if (i.cta?.type === "whatsapp") {
                            shareOnWhatsapp({
                                message: i.cta?.value || "Hello",
                                whatsAppNumber: "918700260932",
                            });
                        }
                    }
                    }
                />
            </CarouselItem>
        ))}
    </Carousel>;
});


const CarouselItem = styled.div`
  height: 142px;
  border-radius: 12px;
  border: 2px solid lightgrey;
`;

const CarouselImage = styled.img`
  src: ${({ src }) => src };
  height: ${({ height }) => height};
  width: 100%;
  border-radius: 12px;
  background-position: center;
  background-repeat: no-repeat;
`;


