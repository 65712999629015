import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { DatePicker, Form, Switch, InputNumber } from "antd";
import { getLedgers, getVouchers, saveVoucher } from "../../../../services/api";
import { sleep, errorMessage } from "../../../../utils";
import ReceiptDistribution from "./ReceiptDistribution";
import Activity from "../../../components/Activity";
import BottomSelect from "../../../components/BottomSelect";
import { Tabs } from "antd-mobile";
import TextArea from "antd/lib/input/TextArea";
import { RUPEE } from "../../../../models/constants";
import { isMobileApp } from "../../../../utils/webviewUtils";
import { DateUI } from "../../voucherWrite/DateUI";
import { useDispatch, useSelector } from "react-redux";
import { customDispatcher } from "../../../../utils";
import { Loading } from "antd-mobile";
import {Z_INDEXES} from "../../../../styles/js/z_indexes";
import ActivityWrapper from "../../../components/Activity";
import {useLayersFramework} from "../../../v2framework/framework.hooks";

const PayIn = ({ customer, onSave = () => {} }) => {
  const state = useSelector((state) => state);
    const {goBack} = useLayersFramework();
  const [form] = Form.useForm();

  const [saving, setSaving] = useState(false);
  const [ledgers, setLedgers] = useState([]);
  const [vouchers, setVouchers] = useState([]);
  const [selectedLedgerId, setSelectedLedgerId] = useState();
  // const [entryType, setEntryType] = useState("Standard");
    const entryType = "Standard";

  // const TabChange = () => {
  //   if (entryType === "Standard") {
  //     setEntryType("Invoice-Wise");
  //   } else {
  //     setEntryType("Standard");
  //   }
  // };

  useEffect(() => {
    if (state?.company) {
      getLedgers("all").then((resp) => setLedgers(resp.data.data.ledgers));
      getVouchers("invoice").then((resp) =>
        setVouchers(resp.data.data.vouchers)
      );
    }
  }, [state]);

  useEffect(() => {
    form.resetFields();
    if (customer) {
      form.setFieldsValue({
        ledgerCr: customer._id,
        date: moment(),
        paymentMode: customer.paymentMode || "Cash",
        ledgerDr: customer.ledgerDr,
        privateNote: customer.privateNote,
        amount: customer.amount,
        entryMode: customer.entryMode || "Standard",
        distribution: customer.distribution,
      });
      setSelectedLedgerId(customer.ledgerCr);
    } else {
      form.setFieldsValue({
        date: moment(),
        paymentMode: "Cash",
      });
      setSelectedLedgerId(undefined);
    }
  }, [customer, form]);

  const NameOptions = ledgers
    .filter((l) => !l.type || l.type === "debtor")
    .map((l) => ({
      label: l.name,
      value: l._id,
      subValue: l.balance,
    }));

  const PaymentOptions = [
    "Cash",
    "UPI",
    "Card",
    "EMI",
    "Bank Transfer",
    "Cheque",
  ].map((mode) => ({
    label: mode,
    value: mode,
  }));

  const ReceivingAccountOptions = ledgers
    .filter((l) => l.type === "cash")
    .map((l) => ({
      label: l.name,
      value: l._id,
    }));

  const FormContent = () => {
    return (
      <div style={{ padding: "5% 5% 15%" }}>
            <FormItem
              wrapperCol={{ span: 15 }}
              name={"ledgerCr"}
              label={"Select Customer"}
              rules={[{ required: true, message: "Please select customer" }]}
            >
              <BottomSelect
                header="Select Customer Name"
                data={NameOptions}
                searchPlaceholder="Select Customer"
                placeholder="Select Customer"
                subLabel="Balance : "
              />
            </FormItem>
          <div style={{ display: "flex", gap: 10, width: "100%", justifyContent: "space-between" }}>
            <FormItem
              labelCol={{
                span: 12,
                style: {
                  textAlign: "left",
                },
              }}
              name="date"
              label="Payment Date"
              style={{ width: "45%" }}
              rules={[
                {
                  required: true,
                  message: "Please select Date",
                },
              ]}
            >
              {isMobileApp() ? (
                <DateUI />
              ) : (
                <DatePicker
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    fontSize: "20px",
                  }}
                  format={"DD MMM YYYY"}
                  allowClear={false}
                />
              )}
            </FormItem>
            <FormItem
              labelCol={{
                span: 12,
                style: {
                  textAlign: "left",
                },
              }}
              name="paymentMode"
              label="Payment Mode"
              rules={[
                {
                  required: true,
                  message: "Please select payment mode",
                },
              ]}
            >
              <BottomSelect
                header="Payment Mode"
                data={PaymentOptions}
                searchPlaceholder="Please select Payment Mode"
              />
            </FormItem>
          </div>
        <FormItem
          name="ledgerDr"
          label="Receiving Account"
          rules={[{ required: true, message: "Please select" }]}
        >
          <BottomSelect
            header="Account"
            data={ReceivingAccountOptions}
            searchPlaceholder="Select Receiving Account"
            placeholder="Select Receiving Account"
          />
        </FormItem>
        <FormItem name={"privateNote"} label={"Private Note"}>
          <TextArea
            style={{
              border: "2px solid #d0caca4a",
              borderRadius: 12,
              padding: 4,
            }}
            placeholder={"Private note (not visible on receipt)"}
            autoSize={{ minRows: 2, maxRows: 2 }}
          />
        </FormItem>

        {!customer && (
          <FormItem name="sendSms" label="SMS to customer ?">
            <Switch
              checkedChildren={"Send SMS"}
              unCheckedChildren={"Don't Send"}
            />
          </FormItem>
        )}

        {entryType === "Standard" ? (
          <FormItem
            name="amount"
            label="Amount Received (₹)"
            rules={[
              {
                required: true,
                message: "Invalid Amount",
              },
            ]}
          >
            <InputNumber
              min={1}
              prefix={`${RUPEE}`}
              max={100000000}
              style={{
                borderRadius: "10px",
                width: "100%",
                height: "36px",
              }}
            />
          </FormItem>
        ) : (
          <FormItem
            name={"distribution"}
            label={"Amount Distribution"}
            rules={[
              {
                required: true,
                message:
                  "Please add at least 1 entry or use Standard Entry Mode",
              },
            ]}
          >
            <ReceiptDistribution
              invoices={vouchers}
              selectedLedgerId={selectedLedgerId}
            />
          </FormItem>
        )}
      </div>
    );
  };

  return (
    <ActivityWrapper
      heading="Payment In"
      content={
        <>
          <FormContainer>
            {ledgers.length === 0 ? <Loading
                style={{
                  padding: "37%",
                  fontSize: "40px",
                  color: "#0080ff",
                  height: "60vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            : 
            <>

              {/*  <Tabs onChange={TabChange} style={{ background: "white", position: "absolute", width: "100%", top: "55px", zIndex: Z_INDEXES.DEFAULT, borderTop: '1px solid lightgray' }}>*/}
              {/*  <Tabs.Tab*/}
              {/*    title={<div style={{ fontSize: 16, fontWeight: "bold" }}>*/}
              {/*      Standard*/}
              {/*    </div>}*/}
              {/*    key="Standard"*/}
              {/*    style={{ fontSize: 10 }} />*/}
              {/*  <Tabs.Tab*/}
              {/*    title={<div style={{ fontSize: 16, fontWeight: "bold" }}>*/}
              {/*      Invoice-Wise*/}
              {/*    </div>}*/}
              {/*    key="Invoice-Wise" />*/}
              {/*</Tabs>*/}

                <Form
                form={form}
                name="basic"
                labelCol={{
                  span: 6,
                  style: {
                    textAlign: "left",
                  },
                }}
                wrapperCol={{ span: 16 }}
                onFinish={async (data) => {
                  data.type = "receipt";
                  data.entryType = entryType;
                  if (entryType === "Invoice-Wise") {
                    let totalAmount = 0;
                    data.distribution.forEach((row) => {
                      totalAmount += row.amount;
                    });
                    data.amount = totalAmount;
                  } else {
                    data.distribution = [];
                  }
                  if (!data.privateNote) {
                    data.privateNote = "";
                  }
                  console.log("## Finished ##", data);
                  setSaving(true);
                  // return;
                  const response = await saveVoucher({
                    voucher: data,
                  });
                  onSave();
                  await sleep(500);
                    if (!response.data.success) {
                    errorMessage(response.data.message);
                    setSaving(false);
                    console.log("## ##", data);
                    return;
                  }
                  setSaving(false);
                  form.resetFields();
                  form.setFieldsValue({
                    date: moment(),
                    paymentMode: "Cash",
                  });
                  goBack();
                } }
                onFinishFailed={(err) => {
                  console.log("## Finish Failed ##", err);
                } }
                autoComplete="off"
              >
                  {FormContent()}

                  <ButtonContainer>
                    <button
                      className={"fab primaryEffect generalRoundedButton buttonProperties"}
                      htmlType="submit"
                      loading={saving}
                    >
                      Save Payment Record
                    </button>
                  </ButtonContainer>
                </Form></>}
          </FormContainer>
        </>
      }
    />
  );
};

export default PayIn;

const FormItem = styled(Form.Item)`
  margin-bottom: 8px;
`;

const FormContainer = styled.div``;

const ButtonContainer = styled.div`
  width: 100%;
  height: 70px;
  bottom: -2px;
  position: absolute;
  margin-left: 10px;
  padding-top: 20px;
`;
