import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import _ from "lodash";
import moment from "moment";
import { Swiper, Tabs } from "antd-mobile";
import { Input } from "antd";

import Text from "../../../../components/Text";
import Activity from "../../../../components/Activity";
import StackActivity from "../../../../components/StackActivity";
import FlexContainer from "../../../../components/FlexContainer";

import { getTransactions, getPayouts } from "../../../../../services/api";
import { beautifyAmount } from "../../../../../utils";
import { RUPEE } from "../../../../../models/constants";

const tabItems = [
  { key: "transactions", title: "Transactions" },
  { key: "payouts", title: "Payouts" },
];

const Card = ({
  data,
  tab,
  loading,
  setSelectedRecord,
  OnItemSelect = () => {},
}) => {
  return (
    data &&
    !loading && (
      <CardContainer
        onClick={() => {
          setSelectedRecord(data);
          tab === "payouts" && OnItemSelect(data);
        }}
      >
        <InnerContainer flexEnd>
          <Text
            fontSize={16}
            color="grey"
            content={(data && data.creationDate
              ? moment(data.creationDate)
              : moment()
            ).format("DD MMM YYYY")}
          />
        </InnerContainer>
        <Text
          fontSize={20}
          fontWeight={600}
          content={
            tab === "transactions"
              ? data.customer && data.customer.name
              : data.displayPayoutId
          }
          top="-10px"
        />
        {tab === "transactions" && (
          <Text
            fontSize={16}
            color="grey"
            content={data.customer && data.customer.phone}
          />
        )}
        <InnerContainer style={{ marginTop: "10px" }}>
          <Table>
            <Container border>
              <TableHeading
                width={tab === "transactions" ? "40%" : "50%"}
                border
              >
                <Text
                  fontWeight={600}
                  content={tab === "transactions" ? "Amount" : "Total Amount"}
                />
              </TableHeading>
              <TableHeading
                width={tab === "transactions" ? "30%" : "50%"}
                border={tab === "transactions"}
              >
                <Text
                  fontWeight={600}
                  content={
                    tab === "transactions" ? "Bill #" : "Transaction Fees"
                  }
                />
              </TableHeading>
              {tab === "transactions" && (
                <TableHeading width="30%">
                  <Text fontWeight={600} content={"Status"} />
                </TableHeading>
              )}
            </Container>
            <Container>
              <TableHeading
                width={tab === "transactions" ? "40%" : "50%"}
                border
              >
                <Text
                  color="green"
                  fontWeight={600}
                  content={
                    RUPEE +
                    beautifyAmount(
                      tab === "transactions"
                        ? data.amount || 0
                        : data.totalAmount || 0
                    )
                  }
                />
              </TableHeading>
              <TableHeading
                width={tab === "transactions" ? "30%" : "50%"}
                color={tab === "payouts" && "red"}
                border={tab === "transactions"}
              >
                <Text
                  fontWeight={600}
                  content={
                    tab === "transactions"
                      ? data.invoiceNumber || "--"
                      : RUPEE + beautifyAmount(data.transactionFee || 0)
                  }
                />
              </TableHeading>
              {tab === "transactions" && (
                <TableHeading width="30%">
                  <Text
                    fontWeight={600}
                    color={data.displayPayoutId ? "green" : "red"}
                    content={data.displayPayoutId ? "Paid Out" : "Pending"}
                  />
                </TableHeading>
              )}
            </Container>
          </Table>
        </InnerContainer>
        <InnerContainer></InnerContainer>
        <Remarks>
          <Text
            fontSize={16}
            content={
              tab === "transactions"
                ? data.orderDetails && data.orderDetails.remarks
                  ? data.orderDetails.remarks
                  : ""
                : "Payout Amount : "
            }
          />
          {tab === "payouts" && (
            <Text
              fontSize={16}
              color="green"
              fontWeight={600}
              content={RUPEE + beautifyAmount(data.paidAmount || 0)}
            />
          )}
        </Remarks>
      </CardContainer>
    )
  );
};

const PaymentGateway = () => {
  const swiperRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [transactionRecords, setTransactionRecords] = useState([]);
  const [payoutRecords, setPayoutRecords] = useState([]);
  const [tab, setTab] = useState("transactions");
  const [showPayoutItems, setShowPayoutItems] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchRecords = async () => {
      setLoading(true);
      const response = await getTransactions();
      const payoutResponse = await getPayouts();
      const resTransaction = _.get(response, "data.data.transactions", []);
      const resPayout = _.get(payoutResponse, "data.data.payouts", []);
      setTransactionRecords(resTransaction);
      setPayoutRecords(resPayout);
      setLoading(false);
    };

    fetchRecords();
  }, []);

  useEffect(() => {
    if (activeIndex === 1) {
      setTab("payouts");
    } else {
      setTab("transactions");
    }
  }, [activeIndex]);

  const OnItemSelect = useCallback(() => {
    setShowPayoutItems(true);
  }, []);

  return (
    <>
      <Activity
        heading="Payment Gateway"
        onBack={ null
          // () => navigate("/dashboard") --
        }
        content={
          <FlexContainer
            header={
              <>
                <Tabs
                  activeKey={tabItems[activeIndex].key}
                  style={{ background: "white", "--title-font-size": "18px" }}
                  activeLineMode={"full"}
                  onChange={(key) => {
                    const index = tabItems.findIndex(
                      (item) => item.key === key
                    );
                    setActiveIndex(index);
                    swiperRef.current?.swipeTo(index);
                  }}
                >
                  <Tabs.Tab
                    activeLineMode={"full"}
                    className={
                      activeIndex === 0
                        ? "selectedPartyTab"
                        : "unselectedPartyTab"
                    }
                    title={<div>Transactions</div>}
                    key="transactions"
                  ></Tabs.Tab>
                  <Tabs.Tab
                    activeLineMode={"full"}
                    className={
                      activeIndex === 1
                        ? "selectedPartyTab"
                        : "unselectedPartyTab"
                    }
                    title={<div>Payouts</div>}
                    key="payouts"
                  ></Tabs.Tab>
                </Tabs>
                {tab === "transactions" && (
                  <Input.Search
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder={"Search with Customer Name"}
                    style={{
                      width: "100%",
                      minWidth: 300,
                      fontSize: 18,
                      padding: "5px 10px",
                    }}
                    size={"large"}
                    enterButton={true}
                    allowClear
                  />
                )}
              </>
            }
            content={
              <div style={{ padding: 8, paddingTop: 8, background: "#fff" }}>
                <Swiper
                  direction="horizontal"
                  indicator={() => null}
                  ref={swiperRef}
                  defaultIndex={activeIndex}
                  onIndexChange={(index) => {
                    setActiveIndex(index);
                  }}
                  style={{
                    height: "100%",
                    border: "0px solid red",
                  }}
                >
                  <Swiper.Item
                    style={{ height: "100%", border: "0px solid blue" }}
                  >
                    {transactionRecords
                      .filter((r) =>
                        ((r.customer && r.customer.name) || "")
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                      )
                      .map(
                        (data) =>
                          data._id && (
                            <Card data={data} tab={tab} loading={loading} />
                          )
                      )}
                  </Swiper.Item>
                  <Swiper.Item
                    style={{ height: "100%", border: "0px solid yellow" }}
                  >
                    {payoutRecords.map((data) => (
                      <Card
                        data={data}
                        tab={tab}
                        loading={loading}
                        setSelectedRecord={setSelectedRecord}
                        OnItemSelect={OnItemSelect}
                      />
                    ))}
                  </Swiper.Item>
                </Swiper>
              </div>
            }
          />
        }
      />
      <StackActivity
        visible={showPayoutItems}
        setVisible={setShowPayoutItems}
        type="paymentPayout"
        data={selectedRecord}
      />
    </>
  );
};

export default PaymentGateway;

const CardContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 20px;
  border: 2px solid gray;
  margin: 0px 0px 21px;
  padding: 5px 15px;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  align-items: ${({ flexEnd }) => flexEnd && "flex-end"};
  justify-content: space-between;
`;

const Table = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  width: 100%;
  border: 2px solid lightgray;
`;

const Container = styled.div`
  width: 100%;
  border-bottom: ${({ border }) => border && "2px solid lightgray"};
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const TableHeading = styled.div`
  width: ${({ width }) => width};
  width: 30%;
  border-right: ${({ border }) => border && "2px solid lightgray"};
  align-items: center;
  text-align: center;
`;

const Remarks = styled.div`
  padding: 10px 0px;
  display: flex;
`;
